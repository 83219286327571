import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, ArrowForward, } from '@mui/icons-material';
import useSteppers from '../../../../hooks/useSteppers';
import { CloseButton, PurpleButton } from '../../../../components/mui/button/Button'

const BottomTile = ({ id, clearForm, steps }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { handleBackward, handleForward, isBackwardDisabled, isForwardDisabled } = useSteppers(steps);
    const type = location?.pathname?.includes('addRecruit') ? 'add' : 'edit';

    return (
        <div className='flex sm:flex-row flex-col justify-between gap-5 p-8 sm:p-14 bg-white border-b-2 border-x-2'>
            {id && <div className='flex justify-between sm:justify-start w-full sm:w-fit gap-5'>
                <PurpleButton disabled={isBackwardDisabled} onClick={handleBackward} text={<ArrowBack sx={{ color: 'white' }} />} />

                <PurpleButton disabled={isForwardDisabled} onClick={handleForward} text={<ArrowForward sx={{ color: 'white' }} />} />
            </div>}

            <div className='flex flex-col sm:flex-row gap-5'>
                {id && <CloseButton variant='contained' size='large' text="Cancel" elevation={false} onClick={() => navigate(type === 'add' ? '/admin/employee/recruits' : `/admin/employee/recruits/onlineVerify/${id}`)} />}

                {!id && <CloseButton variant='contained' size='large' elevation={false} onClick={clearForm} />}
                
                {(!id || type === 'add') && <PurpleButton size='large' type="submit" text="Save & Next" />}
            </div>
        </div>
    )
}

export default BottomTile;