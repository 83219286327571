import { Avatar, Dialog } from '@mui/material';
import { Delete, Edit, KeyboardReturn, Upload } from '@mui/icons-material';
import { SquareIconButton } from '../../../mui/button/SquareIconButton';
import { CrossButton } from '../../../mui/button/Button';

const PhotoChangePopup = ({ onConfirm, onDelete, onClose, data }) => {
  const { fullName, photoURL } = data;

  return (
    <Dialog open={true} onClose={onClose} className='w-full h-full'>
      <div className='flex flex-col items-center text-center p-8 gap-6 w-full h-full rounded-md bg-white'>
      
        <div className='flex flex-col gap-5'>
          <div className='relative flex justify-between w-full'>
            <h2 className='h3-p'>Profile Photo</h2>
            <CrossButton className='absolute -right-4 -top-4' onClose={onClose} />
          </div>
          <Avatar alt={fullName} src={photoURL} style={{ borderRadius: 5, height: 240, width: 240, fontSize: 80 }} />
        </div>

        <div className='flex justify-between gap-4 w-full'>
          <SquareIconButton title={photoURL === '' ? 'Upload' : 'Update'} sx={{ backgroundColor: '#E7D8F8', '&:hover': { backgroundColor: '#D8B7F3' } }} icon={photoURL === '' ? <Upload sx={{ color: '#512C9D', fontSize: 24 }} /> : <Edit sx={{ color: '#512C9D', fontSize: 24 }} />} onClick={onConfirm} className='w-full' />

          {<SquareIconButton title='Delete' sx={{ backgroundColor: '#E7D8F8', '&:hover': { backgroundColor: '#D8B7F3' } }} icon={<Delete sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={onDelete} disabled={photoURL === ''} className='w-full' />}
        </div>

      </div >
    </Dialog>
  )
};

export default PhotoChangePopup