import { CustomTextField, CustomAsyncSelect } from '../../../../../components/mui/Input'
import { bloodGroups, disabilities, genders, motherTongues } from "../../../../../constants/personal"
import renderFormField from '../../../../../utils/renderFormField';
import ProfilePhoto from './ProfilePhoto';

const PersonalInfo = ({ formData, setValue, handleNestedChange, isLoading }) => {

  const genderOptions = genders.map(gender => ({ value: gender, label: gender }));
  const bloodOptions = bloodGroups.map(blood => ({ value: blood, label: blood }));
  const disabilityOptions = disabilities.map(option => ({ value: option, label: option }))
  const motherTongueOptions = motherTongues.map(motherTongue => ({ value: motherTongue, label: motherTongue }));

  const personalDetailsFields = [
    { type: 'input', label: "Middle Name", value: formData?.middleName, name: 'formData.middleName', required: false },
    { type: 'input', label: "Last Name", value: formData?.lastName, name: 'formData.lastName' },
    { type: 'select', label: "Gender", value: formData?.personalInfo?.gender, name: "formData.personalInfo.gender", options: genderOptions },
    { type: 'date', label: "Date of Birth", value: formData?.personalInfo?.dob, name: "formData.personalInfo.dob" },
    { type: 'select', label: "Blood Group", value: formData?.personalInfo?.bloodGroup, name: "formData.personalInfo.bloodGroup", options: bloodOptions },
    { type: 'select', label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue, name: "formData.additionalInfo.motherTongue", options: motherTongueOptions },
    { type: 'select', label: "Disability", value: formData?.additionalInfo?.disability, name: "formData.additionalInfo.disability", options: disabilityOptions }
  ];

  return (
    <div class="flex flex-col w-full gap-8">
      <ProfilePhoto formData={formData} setValue={setValue} isLoading={isLoading} />

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
        <h5 className="col-span-full h4-p">Personal Details</h5>
        {!isLoading ? <div className="flex w-full col-span-full md:col-span-1">
          <CustomAsyncSelect
            className='bg-white w-24' label="Title"
            options={[
              { value: 'Dr', label: 'Dr' },
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
            ]} onChange={handleNestedChange}
            name='formData.title' value={formData?.title}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />
          <CustomTextField
            label="First Name" name='formData.firstName' value={formData?.firstName} onChange={handleNestedChange}
            fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
          />
        </div> : <div className="animate-pulse h-14 w-full rounded-md bg-muiLight" />}
        {personalDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
      </div>
    </div>
  )
}

export default PersonalInfo;