import { useNavigate, useSearchParams } from "react-router-dom";
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import usePostData from "../../../hooks/usePostData";
import useSnackbar from "../../../hooks/useSnackbar";

import google from "../../../assets/logo/google.svg"

const LoginGoogle = ({emailSubmitted}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('type');

    const { postData } = usePostData();
    const { handleSnackbarOpen } = useSnackbar();


    const SignGoogle = async (data) => {
        const { success, message, data: status } = await postData('/api/admission/login/loginGoogleSuccess', { ...data, type: activeTab })
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some error occurred from our end, pls try again!", 'error');

        if (status?.submitted) return navigate(`/admissions/${activeTab.toLowerCase()}/profile?type=Personal`);
        navigate(`/admissions/${activeTab.toLowerCase()}/form?step=Personal+Details`);
    };

    const showErrorPopup = () => handleSnackbarOpen("Opps.. Some error occurred!", 'error');

    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: SignGoogle,
        onNonOAuthError: (error) => { if (error.type !== 'popup_closed') showErrorPopup(); },
        onError: showErrorPopup,
        ux_mode: 'popup',
    });

    useGoogleOneTapLogin({
        onError: () => handleSnackbarOpen("Opps.. Some error occurred!", 'error'),
        onSuccess: SignGoogle,
        googleAccountConfigs: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
        }
    });



    return (
        <button className='flex py-2 px-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-[4px] w-full select-none'
            onClick={handleGoogleLogin} disabled={emailSubmitted}
        >
            <div className='h-fit w-fit flex p-1 bg-white rounded-full'>
                <img draggable={false} src={google} className='h-5 w-5' alt="googleImage" />
            </div>
            <h5 className='text-p font-semibold'>Sign in with Google</h5>
        </button>
    )
}

export default LoginGoogle;