import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const DiplomaInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const diplomaDetailFields = formData?.education?.diplomaDetails?.map((diploma, index) => [
        { label: "Degree", value: formData?.education?.diplomaDetails?.[index]?.degree?.status, data: diploma?.degree?.data, name: `formData.education.diplomaDetails.${index}.degree.status` },
        { label: "Major", value: formData?.education?.diplomaDetails?.[index]?.major?.status, data: diploma?.major?.data, name: `formData.education.diplomaDetails.${index}.major.status` },
        { label: "Completion Year", value: formData?.education?.diplomaDetails?.[index]?.passingYear?.status, data: diploma?.passingYear?.data, name: `formData.education.diplomaDetails.${index}.passingYear.status` },
        { label: "Degree Type", value: formData?.education?.diplomaDetails?.[index]?.degreeType?.status, data: diploma?.degreeType?.data, name: `formData.education.diplomaDetails.${index}.degreeType.status` },
        { label: "College Name", value: formData?.education?.diplomaDetails?.[index]?.collegeName?.status, data: diploma?.collegeName?.data, name: `formData.education.diplomaDetails.${index}.collegeName.status` },
        { label: "College City", value: formData?.education?.diplomaDetails?.[index]?.city?.status, data: diploma?.city?.data, name: `formData.education.diplomaDetails.${index}.city.status` },
        { label: "College State", value: formData?.education?.diplomaDetails?.[index]?.state?.status, data: diploma?.state?.data, name: `formData.education.diplomaDetails.${index}.state.status` },
        { label: "College Country", value: formData?.education?.diplomaDetails?.[index]?.country?.status, data: diploma?.country?.data, name: `formData.education.diplomaDetails.${index}.country.status` },
        { label: "Percentage", value: formData?.education?.diplomaDetails?.[index]?.percentage?.status, data: diploma?.percentage?.data, name: `formData.education.diplomaDetails.${index}.percentage.status` },
        { label: "Grade", value: formData?.education?.diplomaDetails?.[index]?.grade?.status, data: diploma?.grade?.data, name: `formData.education.diplomaDetails.${index}.grade.status` },
        { label: "CGPA", value: formData?.education?.diplomaDetails?.[index]?.cgpa?.status, data: diploma?.cgpa?.data, name: `formData.education.diplomaDetails.${index}.cgpa.status` },
    ]);

    return (
        diplomaDetailFields?.map((fields, i) =>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Diploma ${diplomaDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Diploma {diplomaDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                </CustomCheckBox>

                {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                    <div key={`${i}-${index}`}>
                        <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field.label} value={field.data} loading={isLoading} />
                        </CustomCheckBox>
                    </div>
                )}
            </div>
        )
    )
}

export default DiplomaInfo;