import { useEffect, useState } from "react";

const useTable = (rows, sortParameters = [], pagination = true) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortedRows, setSortedRows] = useState(rows);
    const currentRows = pagination ? sortedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('none');

    const [orderState, setOrderState] = useState({
        order: 'asc',
        orderBy: sortParameters[0]
    });

    const handleRequestSort = (colID) => {
        const isAsc = orderState.order === 'asc' && orderState.orderBy === colID;
        const newOrder = isAsc ? 'desc' : 'asc';
        const sortedData = (rows && [...rows])?.sort((a, b) => {
            const order = isAsc ? 1 : -1;
            return a[colID] < b[colID] ? -1 * order
                : a[colID] > b[colID]
                    ? order : 0;
        });
        setOrderState({ order: newOrder, orderBy: colID });
        setSortedRows(sortedData);
    };

    useEffect(() => {
        // Ordering logic
        const comparator = (a, b) => {
            const column = sortParameters.find(param => param.id === orderState.orderBy);
            if (column && column.customSort) {
              return orderState.order === 'desc' 
                ? column.customSort(b, a)
                : column.customSort(a, b);
            }
            
            const order = orderState.order === 'desc' ? -1 : 1;
            return a[orderState.orderBy] < b[orderState.orderBy] ? -1 * order
              : a[orderState.orderBy] > b[orderState.orderBy]
                ? order : 0;
          };
      
          const sortedData = (rows && [...rows])?.sort(comparator);

        // Searching logic
        const filteredRows = sortedData?.filter((row) => {
            const query = searchQuery.toLowerCase();
        
            const typeHandlers = {
                'string': (value) => value.toLowerCase().includes(query),
                'number': (value) => value.toString().includes(query),
                'boolean': (value, key) => value === true && key.toLowerCase().includes(query),
                'array': (value) => value.some(item => typeHandlers[typeof item]?.(item)),
                'date': (value) => value.toLocaleDateString().includes(query)
            };
        
            return Object.entries(row).some(([key, value]) => {
                // Determine the type and apply the appropriate handler
                if (Array.isArray(value)) {
                    return typeHandlers['array'](value);
                }
                if (value instanceof Date) {
                    return typeHandlers['date'](value);
                }
                const type = typeof value;
                return typeHandlers[type] ? typeHandlers[type](value, key) : false;
            });
        }) || [];        


        setSortedRows(filteredRows);
    }, [orderState, rows, searchQuery]);

    const handleSearch = (query) => setSearchQuery(query);
    const handleSortChange = (option) => setSortOption(option);

    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return {
        sortedRows, currentRows,

        // pagination Parameters
        page, rowsPerPage,
        handleChangePage, handleChangeRowsPerPage,

        // Ordering Parameters
        handleRequestSort, orderState,

        // Searching Parameters
        handleSearch,
        // Sorting Parameters (Not done yet)
        handleSortChange
    };
};

export default useTable;
