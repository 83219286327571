import React from "react";
import TextBox from '../../../components/mui/text/TextBox';
import { dateFormat } from '../../../utils/dateFormat'
const PersonalDetails = ({ formData, loading }) => {

    const fields = [
        { label: 'Alternate Phone No', value: formData?.alternatePhNo },
        { label: 'Personal Email ID', value: formData?.emailId },
        { label: 'Alternate Email ID', value: formData?.alternateEmailId },
        { label: 'Date of Birth', value: dateFormat(formData?.personalInfo?.dob) },
        { label: 'Nationality', value: formData?.identityInfo?.nationality },
        { label: 'Religion', value: formData?.identityInfo?.religion },
        { label: 'Caste', value: formData?.identityInfo?.caste },
        { label: 'Sub-Caste', value: formData?.identityInfo?.subCaste },
        { label: 'Category', value: formData?.identityInfo?.category },
        { label: 'Mother Tongue', value: formData?.additionalInfo?.motherTongue },
        { label: 'Disability', value: formData?.additionalInfo?.disability || 'N/A' },
        { label: 'Marital Status', value: formData?.additionalInfo?.maritalStatus },
    ]

    return (
        <div className='flex flex-col w-full gap-5'>
            <div className='flex items-center gap-4'>
                <h6 className='lb-s whitespace-nowrap'>Personal Details</h6>
                <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5 ml-1'>
                {fields?.map((field, index) => (
                    <TextBox key={index} label={field?.label} value={field?.value} loading={loading} />
                ))}
            </div>
        </div>
    )
}

export default PersonalDetails;