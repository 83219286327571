import PortalPopup from '../PortalPopup'
import { CrossButton } from "../../mui/button/Button";

const ViewOnlyPopup = ({ message, heading, onClose, icon }) => {
    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" >
            <div className="relative flex flex-col justify-between lg:w-[700px] w-full h-full max-h-[700px] bg-white p-5 rounded-lg gap-5">
            
                <div className="flex flex-col xs:flex-row w-full gap-5">
                    <img className="col-span-2 w-8 h-8 xs:w-12 xs:h-12" alt="ICON" loading="eager" src={icon} />
                    <div className='flex flex-col gap-1'>
                        <h5 className="h5-p">{heading}</h5>
                        <p className="text-s">{message}</p>
                    </div>
                </div>

                <CrossButton className='absolute top-2.5 right-2.5' onClose={onClose} />
            </div>
        </PortalPopup>
    )
}

export default ViewOnlyPopup;