import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const IdentityInfo = ({ data }) => {

  const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

  const identityInfoFields = [
    { label: "Nationality", value: formData?.identityInfo?.nationality?.status, data: formData?.identityInfo?.nationality?.data, name: 'formData.identityInfo.nationality.status' },
    { label: "Category", value: formData?.identityInfo?.category?.status, data: formData?.identityInfo?.category?.data, name: 'formData.identityInfo.category.status' },
    { label: "Religion", value: formData?.identityInfo?.religion?.status, data: formData?.identityInfo?.religion?.data, name: 'formData.identityInfo.religion.status' },
    { label: "Caste", value: formData?.identityInfo?.caste?.status, data: formData?.identityInfo?.caste?.data, name: 'formData.identityInfo.caste.status' },
    { label: "Sub Caste", value: formData?.identityInfo?.subCaste?.status, data: formData?.identityInfo?.subCaste?.data, name: 'formData.identityInfo.subCaste.status' },
  ];

  return (
    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
      <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Identity Details')} value={masterCheckBoxValue(identityInfoFields, checkBoxOptions)} name={identityInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
        <h4 className="col-span-full h4-p text-grey">Identity Details</h4>
      </CustomCheckBox>
      
      {identityInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
      </CustomCheckBox>)}
    </div>
  )
}

export default IdentityInfo;