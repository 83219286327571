import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const RequiredExamsInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const requiredExamsFields = Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.map((round, index) => formData?.exams?.requiredExamsDetail?.[round]?.map((exam, i) => [
        { label: 'Exam Year', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.examYear?.status, data: exam?.examYear?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.examYear.status` },
        { label: 'Roll No', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.rollNo?.status, data: exam?.rollNo?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.rollNo.status` },
        { label: 'Obtained Marks', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.obtainedMarks?.status, data: exam?.obtainedMarks?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.obtainedMarks.status` },
        { label: 'Total Marks', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.totalMarks?.status, data: exam?.totalMarks?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.totalMarks.status` },
        { label: 'Percentage', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.percentage?.status, data: exam?.percentage?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.percentage.status` },
        { label: 'Percentile', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.percentile?.status, data: exam?.percentile?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.percentile.status` },
        { label: 'National Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.nationalRank?.status, data: exam?.nationalRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.nationalRank.status` },
        { label: 'State Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.stateRank?.status, data: exam?.stateRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.stateRank.status` },
        { label: 'Caste Rank', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.casteRank?.status, data: exam?.casteRank?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.casteRank.status` },
        { label: 'Grade', value: formData?.exams?.requiredExamsDetail?.[round]?.[i]?.grade?.status, data: exam?.grade?.data, name: `formData.exams.requiredExamsDetail.${round}.${i}.grade.status` }
    ]));

    return (
        requiredExamsFields?.map((round, index) => (
            round?.map((exam, examIndex) =>
                <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                    <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `${Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.[index]} Details`)} value={masterCheckBoxValue(exam, checkBoxOptions)} name={exam} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <h4 className="col-span-full h4-p text-grey">{Object?.keys(formData?.exams?.requiredExamsDetail ?? {})?.[index]} Exam {round?.length > 0 ? examIndex + 1 : ''} Details</h4>
                    </CustomCheckBox>
                    {exam?.map((field, i) => (isLoading || field?.data !== undefined) &&
                        <div key={`${index}-${i}`}>
                            <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                <TextBox label={field.label} value={field.data} loading={isLoading} />
                            </CustomCheckBox>
                        </div>
                    )}
                </div>
            )))
    )
}

export default RequiredExamsInfo;