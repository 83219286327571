import React from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import { ArrowBack, EditOutlined, PersonOff } from "@mui/icons-material";

import usePopup from "../../../../../hooks/usePopup";
import usePutData from "../../../../../hooks/usePutData";
import { loadingState } from "../../../../../recoil/state";
import useSnackbar from "../../../../../hooks/useSnackbar";

import { CustomTextField } from "../../../../../components/mui/Input";
import { CloseButton, ConfirmButton, PurpleButton } from "../../../../../components/mui/button/Button";
import BlackToolTip from "../../../../../components/mui/BlackToolTip";

import GeneralInfo from "./information/GeneralInfo";
import PersonalInfo from "./information/PersonalInfo";
import AddressInfo from "./information/AddressInfo";
import FinancialInfo from "./information/FinanceInfo";
import IdentityInfo from "./information/IdentityInfo";
import AdditionalInfo from "./information/AdditionalInfo";
import FamilyInfo from "./information/FamilyInfo";
import ElementaryInfo from "./information/education/ElementaryInfo";
import IntermediateInfo from "./information/education/IntermediateInfo";
import DiplomaInfo from "./information/education/DiplomaInfo";
import UndergraduateInfo from "./information/education/UndergraduateInfo";
import PostgraduateInfo from "./information/education/PostgraduateInfo";
import DoctorateInfo from "./information/education/DoctorateInfo";
import CertificationInfo from "./information/education/CertificationInfo";

const VerifyData = ({ id, documentOpen, formData, isLoading, formOperations, onlineStatus }) => {
    const { masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit } = formOperations;
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const handleBackward = () => navigate('/admin/employee/recruits');

    const handleEditDetails = () => navigate(`/admin/employee/recruits/editDetails/${id}?step=Personal+Details`)

    const onConfirmDelete = async () => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/recruits/deleteRecruit/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen(`Recruit deleted successfully!`, 'success');
        navigate('/admin/employee/recruits');
    }

    const handleDelete = () => openPopup('Block Recruit', "Are you sure you want to block the recruit? This action can't be reverted back!", 'warning', onConfirmDelete);

    const checkBoxOptions = {
        'Verified': true,
        'Rejected': false,
        'Pending': undefined,
        'true': 'Verified',
        'false': 'Rejected',
        'undefined': 'Pending'
    };

    const data = { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading };

    return (
        <div className={`flex flex-col h-[calc(100svh-13rem)] tab:h-[calc(100svh-10rem)] lg:h-[calc(100svh-5rem)] transition-transform delay-500 duration-500 col-span-full ${documentOpen ? "lg:col-span-8" : "lg:col-span-15"}`}>
            <div className="sticky top-0 flex justify-between gap-5 w-full p-3 sm:px-5 md:px-8 drop-shadow-sm border-b h-[68px]">
                <div className="flex items-center gap-2 xs:gap-5">
                    <IconButton size="small" className="w-fit" aria-label="back" onClick={handleBackward}>
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    <h4 className="h3-p">Review Changes</h4>
                </div>

                <div className="flex gap-3">
                    <BlackToolTip title={onlineStatus !== 'Pending' ? `${onlineStatus} Application can't be edited!` : 'Edit Recruit Application'}>
                        <p>
                            <IconButton aria-label="Edit Details" disableElevation
                                className='rounded-sm' onClick={handleEditDetails} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }} disabled={onlineStatus !== 'Pending'}>
                                <EditOutlined className="text-amber-500" fontSize="small" />
                            </IconButton>
                        </p>
                    </BlackToolTip>

                    <BlackToolTip title='Block Recruit Permanently'>
                        <p>
                            <IconButton aria-label="Block Application" disableElevation
                                className='rounded-sm' onClick={handleDelete} sx={{ backgroundColor: '#f8fafc', border: '1px solid', borderColor: '#e5e5e5' }}>
                                <PersonOff className="text-signal" fontSize="small" />
                            </IconButton>
                        </p>
                    </BlackToolTip>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex flex-col gap-8 xs:gap-12 p-5 xs:p-8 overflow-y-scroll no-scrollbar">

                <GeneralInfo data={data} />
                <PersonalInfo data={data} />
                <AddressInfo data={data} />
                <IdentityInfo data={data} />
                <FinancialInfo data={data} />
                <AdditionalInfo data={data} />
                <FamilyInfo data={data} />

                <ElementaryInfo data={data} />
                {formData?.education?.intermediateDetail && <IntermediateInfo data={data} />}
                {formData?.education?.diplomaDetails && <DiplomaInfo data={data} />}
                {formData?.education?.ugDetails && <UndergraduateInfo data={data} />}
                {formData?.education?.pgDetails && <PostgraduateInfo data={data} />}
                {formData?.education?.doctorateDetails && <DoctorateInfo data={data} />}
                {formData?.education?.certificationDetails && <CertificationInfo data={data} />}

                <div className="flex flex-col xs:mt-4 gap-3 xs:gap-5">
                    <CustomTextField multiline rows={4} label='Add Remark' placeholder='Your remark will be forwarded to the respective recruit for further clarification' name='remark' required={false} disabled={onlineStatus === 'Verified'} />

                    <div className="flex flex-col xs:flex-row gap-3 xs:gap-5 w-full">
                        <CloseButton fullWidth type='button' text='Review Later' onClick={handleBackward} />
                        {onlineStatus === 'Pending' ?
                            <ConfirmButton fullWidth type='submit' text={"Verify Online Verification"} /> :
                            onlineStatus === 'Rejected' ?
                                <ConfirmButton fullWidth type='submit' text="ReVerify Online Verification" /> :
                                onlineStatus === 'Verified' ?
                                    <PurpleButton fullWidth type='submit' text="Revert Online Verification" /> : <PurpleButton fullWidth disabled={true} text={isLoading ? 'Loading' : 'Status Not Found'} />}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default VerifyData;