import React from "react";
import { CloseButton, ConfirmButton } from "../../../../components/mui/button/Button";
import PortalPopup from "../../../../components/popups/PortalPopup";
import { Dialog } from "@mui/material";

const RejectConfirmationPopup = ({ icon, heading, text, message, onClose, onConfirm }) => {
    const iconPaths = {
        success: '/icons/tabs/Success.svg',
        download: '/icons/tabs/Download.svg',
        view: '/icons/tabs/SuccessPurple.svg',
        delete: '/icons/tabs/Delete.svg',
        edit: '/icons/tabs/Edit.svg',
    };

    return (
        <Dialog open={true} onClose={onClose}>
            <div className="flex flex-col p-5 gap-5 xs:gap-10 w-full max-w-[32rem] bg-white rounded-lg">

                <div className="flex gap-5">
                    <img className="col-span-2 w-12 h-12" alt="" loading="eager" src={iconPaths[icon]} />
                    <div className="flex flex-col" >
                        <h2 className="h4-p">{heading}</h2>
                        <p className="text-s">{text}</p>
                    </div>
                </div>

                <div className="flex text-neutral">
                    {message}
                </div>

                <div className="flex sm:flex-row justify-between gap-4">
                    <CloseButton onClick={onClose} text='Cancel' fullWidth />
                    <ConfirmButton onClick={() => onConfirm()} text="Confirm" fullWidth />
                </div>
            </div>
        </Dialog>
    )
}

export default RejectConfirmationPopup;