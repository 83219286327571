import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const AdditionalInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const additionalInfoFields = [
        { label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue?.status, data: formData?.additionalInfo?.motherTongue?.data, name: 'formData.additionalInfo.motherTongue.status' },
        { label: "Disability", value: formData?.additionalInfo?.disability?.status, data: formData?.additionalInfo?.disability?.data, name: 'formData.additionalInfo.disability.status' },
        { label: "Marital Status", value: formData?.additionalInfo?.maritalStatus?.status, data: formData?.additionalInfo?.maritalStatus?.data, name: 'formData.additionalInfo.maritalStatus.status' },
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Additional Details')} value={masterCheckBoxValue(additionalInfoFields, checkBoxOptions)} name={additionalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Additional Details</h4>
            </CustomCheckBox>
            
            {additionalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
            </CustomCheckBox>)}
        </div>
    )
}

export default AdditionalInfo;