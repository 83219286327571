import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, useMediaQuery } from '@mui/material'

import useGetData from '../../../../hooks/useGetData'

import SkeletonText from '../../../../components/mui/text/SkeltonText'
import TextBox from '../../../../components/mui/text/TextBox'

import DivisionIcon from '../../../../assets/icons/organization/divisionIcon.svg'
import DepartmentIcon from '../../../../assets/icons/organization/departmentIcon.svg'
import ProgramIcon from '../../../../assets/icons/organization/programIcon.svg'
import FacebookIcon from '../../../../assets/logo/facebook.svg'
import TwitterIcon from '../../../../assets/logo/twitter.svg'
import LinkedInIcon from '../../../../assets/logo/linkedin.svg'
import InstagramIcon from '../../../../assets/logo/instagram.svg'
import CampusShortLogo from '../../../../assets/logo/products/campusShortLogo.svg'
import AtlasShortLogo from '../../../../assets/logo/products/atlasShortLogo.svg'
import ReachShortLogo from '../../../../assets/logo/products/reachShortLogo.svg'
import TracerShortLogo from '../../../../assets/logo/products/tracerShortLogo.svg'
import FilterComponent from './FilterComponent'

const iconMap = {
    campus: CampusShortLogo,
    atlas: AtlasShortLogo,
    reach: ReachShortLogo,
    tracer: TracerShortLogo,
}

const OrganizationDashboard = () => {
    const { data: organizationData, isLoading: organizationDataLoading } = useGetData('/api/organization/getOrganizationInfo')
    const { data: organizationStats, isLoading: organizationStatsLoading } = useGetData('/api/organization/getOrganizationStats')
    const xs = useMediaQuery('(max-width:500px)');

    const statsData = [
        { heading: 'Total Divisions', count: organizationStats?.divisionsCount, icon: DivisionIcon },
        { heading: 'Total Departments', count: organizationStats?.departmentsCount, icon: DepartmentIcon },
        { heading: 'Total Programs', count: organizationStats?.programsCount, icon: ProgramIcon }
    ]

    const organizationInfo = [
        { label: 'Affiliated University', value: organizationData?.affiliatedUniversity },
        { label: 'Established Year', value: organizationData?.establishedYear },
        { label: 'Address', value: organizationData?.address, className: 'col-span-full' }
    ]

    const mailData = {
        support: 'Support Mail ID',
        reach: 'Reach Mail ID',
        campus: 'Campus Mail ID',
        atlas: 'Atlas Mail ID',
        uplift: 'Uplift Mail ID'
    }

    const socialLinks = [
        { icon: FacebookIcon, to: organizationData?.socialMediaLinks?.facebook },
        { icon: TwitterIcon, to: organizationData?.socialMediaLinks?.twitter },
        { icon: LinkedInIcon, to: organizationData?.socialMediaLinks?.linkedIn },
        { icon: InstagramIcon, to: organizationData?.socialMediaLinks?.instagram },
    ]

    return (
        <div className="flex flex-col gap-5 bg-white p-3 xs:p-5 min-h-[calc(100svh-5rem)]">
            <div className='grid grid-cols-1 xs:grid-cols-20 border drop-shadow bg-white rounded-[4px]'>
                <div className='col-span-full flex flex-col xs:flex-row items-center p-5 gap-5 border-b-2'>
                    <Link to={organizationData?.officialWebsite} target="_blank" rel="noopener noreferrer" className={`flex items-center justify-center h-20 p-0 rounded-md w-60 xs:w-80 ${organizationDataLoading ? 'animate-pulse bg-muiLight' : 'xs:p-3 border-[#5d45db] xs:border-[1px]'}`}>
                        <img className='h-full' draggable={false} src={xs ? organizationData?.logoThumbnailURL : organizationData?.logoDefaultURL} alt={organizationData?.name} />
                    </Link>

                    <div className='flex flex-col text-center items-center xs:items-start gap-1 w-full'>
                        <SkeletonText loading={organizationDataLoading} width={xs ? '100%' : '40%'} className='h3-p sm:h2-p' skeletonClassName='w-[60%]'>{organizationData?.name}</SkeletonText>

                        <SkeletonText width={xs ? '100%' : '60%'} className='lb-s text-purple' loading={organizationDataLoading} skeletonClassName='w-[80%]'>{organizationData?.tagline}</SkeletonText>
                    </div>
                </div>

                <div className='col-span-full lg:col-span-15 grid grid-cols-1 xs:grid-cols-2 gap-4 p-5 border-b-2'>
                    {
                        organizationInfo?.map((field, index) => (field?.value || organizationDataLoading) &&
                            <TextBox className={field?.className} key={index} label={field?.label} value={field?.value} loading={organizationDataLoading} />
                        )
                    }
                </div>

                <div className='hidden lg:flex col-span-5 flex-col gap-4 p-5 border-b-2 xs:border-l-2'>
                    <h4 className='col-span-full h4-p h-fit'>Social Links</h4>
                    <div className='flex gap-2'>
                        {
                            socialLinks?.map((link, index) =>
                                <IconButton key={index} onClick={() => !organizationDataLoading ? window.open(link?.to, '_blank') : null} className='w-fit'>
                                    <img className='h-7 w-fit' src={link?.icon} alt='Social Links' />
                                </IconButton>
                            )
                        }
                    </div>
                </div>

                <div className='col-span-full lg:col-span-15 grid grid-cols-1 xs:grid-cols-2 gap-4 p-5 xs:border-b-2 lg:border-b-0'>
                    {
                        Object?.keys(organizationData?.mails ?? mailData)?.map((field, index) => (organizationData?.mails?.[field] || organizationDataLoading) &&
                            <TextBox key={index} label={mailData?.[field]} value={organizationData?.mails?.[field]} loading={organizationDataLoading} />
                        )
                    }
                </div>

                <div className='xs:col-span-10 lg:col-span-5 flex flex-col gap-4 p-5 border-t-2 lg:border-l-2 xs:border-t-0'>
                    <h4 className='col-span-full h4-p h-fit'>Associated Products</h4>
                    <div className='flex gap-4'>
                        {
                            Object?.keys(organizationData?.accessType ?? iconMap)?.map((product, index) =>
                                organizationDataLoading ? <div key={index} className='h-11 w-14 rounded-[4px] animate-pulse bg-muiLight' /> :
                                    <Link key={index} to={organizationData?.links?.[product]} target="_blank" rel="noopener noreferrer" className='p-3 bg-secondary rounded-[4px]'>
                                        <img className='h-5 w-fit' src={iconMap?.[product]} alt='Social Links' />
                                    </Link>
                            )
                        }
                    </div>
                </div>

                <div className='flex lg:hidden col-span-full xs:col-span-10 flex-col gap-4 p-5 border-t-2 xs:border-l-2 xs:border-t-0'>
                    <h4 className='col-span-full h4-p h-fit'>Social Links</h4>
                    <div className='flex gap-2'>
                        {
                            socialLinks?.map((link, index) =>
                                <IconButton key={index} onClick={() => window.open(link?.to, '_blank')} className='w-fit'>
                                    <img className='h-7 w-fit' src={link?.icon} alt='Social Links' />
                                </IconButton>
                            )
                        }
                    </div>
                </div>
            </div>

            <FilterComponent organizationStats={statsData} isLoading={organizationStatsLoading} />
        </div>
    )
}

export default OrganizationDashboard