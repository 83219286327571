import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EmployeeProfile from '../pages/admin/profile/Profile'
import EmployeeDashboard from '../pages/admin/dashboard/Dashboard'

import Setting from '../pages/admin/settings/Setting'
import ChangePassword from '../pages/admin/settings/ChangePassword'
import UpdateInfo from '../pages/admin/settings/updateInfo/UpdateInfo'
import UpdateContactInfo from '../pages/admin/settings/UpdateContactInfo'

import StudentForm from '../pages/admissions/student/forms/Form'
import StudentAdmissionProfile from '../pages/admissions/student/home/Profile'

import StudentProfile from '../pages/admin/student/profile/Profile'
import ApplicantVerification from '../pages/admin/student/applicants/StudentApplicants'
import ApplicantOnlineVerification from '../pages/admin/student/applicants/onlineVerification/OnlineVerification'
import ApplicantOfflineVerification from '../pages/admin/student/applicants/offlineVerification/OfflineVerification'
import BlockedApplicants from '../pages/admin/student/applicants/BlockedApplicants'
import InProgressApplicants from '../pages/admin/student/applicants/InProgressApplicants'
import EnrolledStudent from '../pages/admin/student/enrolleds/EnrolledStudents'
import AlumniStudent from '../pages/admin/student/alumnis/AlumniStudents'

import EmployeeForm from '../pages/admissions/employee/forms/Form'
import EmployeeAdmissionProfile from '../pages/admissions/employee/home/Profile'

import RecruitsVerification from '../pages/admin/employee/recruits/RecruitsVerification'
import RecruitOnlineVerification from '../pages/admin/employee/recruits/onlineVerification/OnlineVerification'
import RecruitOfflineVerification from '../pages/admin/employee/recruits/offlineVerification/OfflineVerification'
import BlockedRecruits from '../pages/admin/employee/recruits/BlockedRecruits'
import InProgressRecruits from '../pages/admin/employee/recruits/InProgressRecruits'
import CurrentEmployee from '../pages/admin/employee/currents/CurrentFaculty'
import FormerEmployee from '../pages/admin/employee/formers/FormerFaculty'

import InProgress from './InProgress'
import OrganizationDashboard from '../pages/admin/organization/dashboard/OrganizationDashboard'
import OrganizationRegistration from '../pages/admin/organization/registration/OrganizationRegistration'
import DropoutStudents from '../pages/admin/student/enrolleds/DropoutStudents'

const EmployeeRoutes = () => {
    return (
        <Routes>
            <Route exact path="/profile" element={<EmployeeProfile />} />
            <Route exact path="/dashboard" element={<EmployeeDashboard />} />

            <Route exact path='/notifications' element={<InProgress />} />

            <Route exact path='/settings' element={<Setting />} />
            <Route exact path='/settings/updateInfo' element={<UpdateInfo />} />
            <Route exact path='/settings/changePassword' element={<ChangePassword />} />
            <Route exact path='/settings/communication' element={<UpdateContactInfo />} />

            <Route exact path="/student/applicants" element={<ApplicantVerification />} />
            <Route exact path="/student/applicants/editDetails/:id" element={<StudentForm />} />
            <Route exact path="/student/applicants/onlineVerify/:id" element={<ApplicantOnlineVerification />} />
            <Route exact path="/student/applicants/offlineVerify/:id" element={<ApplicantOfflineVerification />} />
            <Route exact path="/student/applicants/profile/:id" element={<StudentAdmissionProfile />} />
            <Route exact path="/student/applicants/blocked" element={<BlockedApplicants />} />
            <Route exact path="/student/applicants/blocked/profile/:id" element={<StudentAdmissionProfile />} />
            <Route exact path="/student/applicants/inProgress" element={<InProgressApplicants />} />
            <Route exact path="/student/applicants/addApplicant/:id" element={<StudentForm />} />

            <Route exact path='/student/enrolleds' element={<EnrolledStudent />} />
            <Route exact path='/student/enrolleds/profile/:id' element={<StudentProfile />} />
            <Route exact path="/student/enrolleds/dropoutStudents" element={<DropoutStudents />} />

            <Route exact path='/student/alumnis' element={<AlumniStudent />} />
            <Route exact path='/student/alumnis/profile/:id' element={<StudentProfile />} />


            <Route exact path='/employee/recruits' element={<RecruitsVerification />} />
            <Route exact path="/employee/recruits/editDetails/:id" element={<EmployeeForm />} />
            <Route exact path='/employee/recruits/onlineVerify/:id' element={<RecruitOnlineVerification />} />
            <Route exact path='/employee/recruits/offlineVerify/:id' element={<RecruitOfflineVerification />} />
            <Route exact path="/employee/recruits/profile/:id" element={<EmployeeAdmissionProfile />} />
            <Route exact path="/employee/recruits/blocked" element={<BlockedRecruits />} />
            <Route exact path="/employee/recruits/blocked/profile/:id" element={<EmployeeAdmissionProfile />} />
            <Route exact path="/employee/recruits/inProgress" element={<InProgressRecruits />} />
            <Route exact path="/employee/recruits/addRecruit/:id" element={<EmployeeForm />} />

            <Route exact path='/employee/currents' element={<CurrentEmployee />} />
            <Route exact path='/employee/currents/profile/:id' element={<EmployeeProfile />} />

            <Route exact path='/employee/formers' element={<FormerEmployee />} />
            <Route exact path='/employee/formers/profile/:id' element={<EmployeeProfile />} />

            <Route exact path='/organization/dashboard' element={<OrganizationDashboard />} />
            <Route exact path='/organization/registration' element={<OrganizationRegistration />} />
        </Routes>
    )
}

export default EmployeeRoutes;