import { useState } from 'react';
import { Upload, Delete, Preview } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import pendingIcon from '../../assets/icons/Pending.svg';
import successIcon from '../../assets/icons/Success.svg';
import submittedIcon from '../../assets/icons/Submitted.svg';
import rejectedIcon from '../../assets/icons/Rejected.svg';
import UploadPopup from './UploadPopup';
import useFetchData from '../../hooks/useFetchData';
import useSnackbar from '../../hooks/useSnackbar';
import SkeletonText from '../mui/text/SkeltonText';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/state';
import BlackToolTip from '../mui/BlackToolTip';
import useDeleteData from '../../hooks/useDeleteData';

const UploadDocumentTile = ({ doc = {}, uploadURL, viewURL, deleteURL, loading, updateStatus }) => {
    const { onlineStatus: status, title, description, key } = doc;
    const iconSrc = status === 'Verified' ? successIcon : status === 'Rejected' ? rejectedIcon : status === 'Submitted' ? submittedIcon : pendingIcon;

    const { fetchData, error } = useFetchData();
    const { handleSnackbarOpen } = useSnackbar();

    const [, setGlobalLoading] = useRecoilState(loadingState);
    const { deleteData } = useDeleteData();
    const [popup, setPopup] = useState(false);
    const [signedURL, setSignedURL] = useState('');
    const [file, setFile] = useState();

    const setterFile = (file) => setFile(file);
    const closePopup = () => setPopup(false);

    const getUploadURL = async () => {
        const signedURL = await fetchData(uploadURL);
        if (error) handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        setSignedURL(signedURL);
        setPopup(true)
    };

    const getViewURL = async () => {
        const signedURL = await fetchData(viewURL);
        if (error) handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        window.open(signedURL, '_blank');
    };

    const deleteUploaded = async () => {
        const { message, success } = await deleteData(deleteURL);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        handleSnackbarOpen('Your Document is successfully Deleted', 'success');
        updateStatus(key, 'Pending');
    };

    const onSubmit = async () => {
        setGlobalLoading(true);
        const uploadResponse = await fetch(signedURL, { method: 'PUT', body: file });
        setGlobalLoading(false);

        if (!uploadResponse.ok)
            return handleSnackbarOpen(`Failed to upload file ${file.name}`, "error")

        updateStatus(key, 'Submitted')
        handleSnackbarOpen("Documents deposited! Your files safely locked away in the vault.", "success")
        closePopup();
    }

    const fileExtension = key?.endsWith('.pdf') ? 'pdf' : 'image';
    const allowedTypes = { image: ['png', 'jpeg', 'jpg'], pdf: ['pdf'] };

    return (
        <div className="flex flex-col xs:flex-row justify-between items-center p-4 gap-5 border-slate-300 border-2 rounded-md bg-white">

            <div className="flex gap-3 items-center w-full">
                <BlackToolTip title={status} >
                    <img className='flex flex-shrink-0 h-14 w-14 cursor-pointer' src={iconSrc} alt={status} />
                </BlackToolTip>

                <div className="flex flex-col w-full">
                    <SkeletonText loading={loading} width='20%' className="h5-p font-semibold">{title}</SkeletonText>
                    <SkeletonText loading={loading} width='50%' className="text-s font-normal">{description}</SkeletonText>
                </div>
            </div>

            <div className='flex items-center text-center gap-4 max-w-fit w-full'>
                <SkeletonText width={56} loading={loading} className='ml-2 bg-orange px-2 py-1.5 text-p text-white rounded-[4px] min-w-14'>{fileExtension.toUpperCase()}</SkeletonText>

                <div className="grid grid-cols-3 gap-1">
                    {(status === 'Submitted' || status === 'Verified') ?
                        <BlackToolTip arrow title='View Uploaded Document'>
                            <IconButton disabled={loading} onClick={getViewURL}>
                                <Preview className='text-green' />
                            </IconButton>
                        </BlackToolTip>
                        :
                        <BlackToolTip arrow title='Upload Document'>
                            <IconButton disabled={loading} onClick={getUploadURL}>
                                <Upload className='text-purple' />
                            </IconButton>
                        </BlackToolTip>}

                    {status === 'Submitted' &&
                        <BlackToolTip arrow title='Delete Uploaded Document'>
                            <IconButton disabled={loading} onClick={deleteUploaded}>
                                <Delete className='text-signal' />
                            </IconButton>
                        </BlackToolTip>}
                </div>
            </div>

            {popup &&
                <UploadPopup file={file} setterFile={setterFile} allowedTypes={allowedTypes[fileExtension]}
                    onClose={closePopup} onSubmit={onSubmit} />}
        </div>
    );
};

export default UploadDocumentTile;
