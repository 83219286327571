import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil';
import { Avatar } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

import { loadingState } from '../../../../../recoil/state';
import useFetchData from '../../../../../hooks/useFetchData';
import useSnackbar from '../../../../../hooks/useSnackbar';
import useDeleteData from '../../../../../hooks/useDeleteData';

import UploadPopup from '../../../../../components/upload/UploadPopup';
import PhotoChangePopup from '../../../../../components/popups/localPopup/settings/PhotoChangePopup';

const ProfilePhoto = ({ formData, setValue, isLoading }) => {
    const { fetchData: fetchUploadUrl, error: urlError } = useFetchData();
    const { deleteData } = useDeleteData();
    const { handleSnackbarOpen } = useSnackbar();

    const setGlobalLoading = useSetRecoilState(loadingState);

    const [uploadPopup, setUploadPopup] = useState(false);
    const [photoChangePopup, setPhotoChangePopup] = useState(false);
    const [signedURL, setSignedURL] = useState('');
    const [file, setFile] = useState();

    const setterFile = (file) => setFile(file);
    const fileExtension = formData?.photoKey?.endsWith('.pdf') ? 'pdf' : 'image';
    const allowedTypes = { image: ['png', 'jpeg', 'jpg'], pdf: ['pdf'] };

    const fullName = `${formData?.firstName} ${formData?.middleName} ${formData?.lastName}`;

    const handlePhotoChange = async () => {
        setPhotoChangePopup(false);
        const uploadURL = await fetchUploadUrl(`/api/employee/personal/getUploadURL?key=${formData?.photoKey}`);
        if (urlError) return handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        setSignedURL(uploadURL);
        setUploadPopup(true)
    }

    const onPhotoChange = async () => {
        setUploadPopup(false);
        setGlobalLoading(true);
        const uploadResponse = await fetch(signedURL, { method: 'PUT', body: file });
        const viewURL = await fetchUploadUrl(`/api/employee/personal/getViewURL?key=${formData?.photoKey}`);
        setGlobalLoading(false);

        if (!uploadResponse.ok) return handleSnackbarOpen(`Failed to upload file ${file?.name}`, "error");
        if (urlError) return handleSnackbarOpen('Opps.. Some Error occurred', 'warning');
        setValue('photoURL', viewURL);
        handleSnackbarOpen("Your Profile Image has been successfully updated", "success");
    }

    const deletePhoto = async () => {
        setPhotoChangePopup(false);
        setGlobalLoading(true);
        const { message, success } = await deleteData(`/api/employee/personal/deleteUploadedObject?key=${formData?.photoKey}`);
        setGlobalLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. Some Error occurred', 'error');
        setValue('photoURL', '');
        handleSnackbarOpen('Your Profile Image is successfully Deleted', 'success');
    };

    return (
        <div class="flex flex-col gap-3">
            <h5 className="col-span-full h4-p">Photo</h5>
            <div className='relative w-fit cursor-pointer group' onClick={() => setPhotoChangePopup(true)}>
                {!isLoading ?
                    <>
                        <Avatar className='group-hover:opacity-85' alt={fullName} src={formData?.photoURL} style={{ borderRadius: 5, height: 192, width: 192, fontSize: 80 }} />
                        <PhotoCamera className='absolute hidden top-[40%] left-[40%]' sx={{ color: "#512C9D", fontSize: 44, display: 'none', backgroundColor: '#F8F4FD', borderRadius: 100, padding: 1, '.group:hover &': { display: 'block' } }} />
                    </> :
                    <div className='h-48 w-48 bg-muiLight animate-pulse rounded-md'></div>}
            </div>

            {uploadPopup &&
                <UploadPopup file={file} setterFile={setterFile} allowedTypes={allowedTypes[fileExtension]}
                    onClose={() => setUploadPopup(false)} onSubmit={onPhotoChange} />}
            {photoChangePopup &&
                <PhotoChangePopup onConfirm={handlePhotoChange} onDelete={deletePhoto} onClose={() => setPhotoChangePopup(false)} data={{ fullName: fullName, photoURL: formData?.photoURL }} />}
        </div>
    )
}

export default ProfilePhoto;