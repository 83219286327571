import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { PersonRemoveAlt1 } from "@mui/icons-material";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DemoteIcon from '../../../../assets/icons/DemoteIcon'
import TransferIcon from '../../../../assets/icons/TransferIcon'
import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import { futureYearsArray } from "../../../../utils/dateFormat";
import RenderStats from "../../../../utils/RenderStats";
import { convertArrayCamelCaseToFirstWords } from "../../../../utils/functions";
import CustomTable from "../../../../components/mui/Table";
import BlinkingBadge from "../../../../components/mui/BlinkingBadge";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";

const EnrolledStudents = () => {
    const { data: enrollData, isLoading: enrollDataLoading, updateData } = useGetData('/api/employee/admission/enrolleds/getEnrolleds')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/enrolleds/stats/getStudentsProgramsCount');
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [userId, setUserId] = useState('');
    const user = enrollData?.find(user => user._id === userId) || {};

    const customOperations = () => <SquareIconButton title='Dropout Students'
        sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }}
        icon={<PersonRemoveAlt1 sx={{ color: "orange", fontSize: 24 }} />}
        onClick={() => navigate('/admin/student/enrolleds/dropoutStudents')} />

    const handleRow = (_event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view',
            () => navigate("/admin/student/enrolleds/profile/" + value + '?type=Personal'));

    const handleDemote = (_event, value) => {
        setUserId(value);
        openPopup('Demote to Applicant', "Are you sure you want to demote this Student to Applicant? If the college is using the CAMPUS ERP system, the student's access will also be terminated from it.",
            'edit', onDemote, undefined, 'input', demoteFields);
    }

    const onDemote = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledDemotion/${userId}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== userId);
        });
        handleSnackbarOpen(`Student successfully Demoted!`, 'success');
    }

    const displayStatus = (_event, id) => {
        const user = enrollData?.find(user => user._id === id) || {};
        if (!user?.pendingVerification) return handleRow(null, user?._id);
        const verificationStatus = convertArrayCamelCaseToFirstWords(user?.pendingVerification);
        return handleSnackbarOpen(`${verificationStatus?.join(', ')} Verification is pending for Student.`, 'warning');
    };

    const handleCompletion = (_event, value) => {
        setUserId(value);
        openPopup('Transfer to Alumni', 'Are you sure you want to transfer this student to Alumni?',
            'edit', onCompletion, undefined, 'input', transferFields);
    }

    const onCompletion = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledTransfer/${userId}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== userId);
        });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const handleDropout = async (_event, value) => {
        setUserId(value);
        openPopup('Dropout Student', 'Are you sure you want to mark this student as Dropout?',
            'edit', onDropout, undefined, 'input', dropoutFields);
    }

    const onDropout = async (formData) => {
        console.log(userId);
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateDropout/${userId}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => { return prevState.filter(user => user._id !== userId) });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        {
            id: 'name', label: 'Student Name', minWidth: 200, type: 'iconButton',
            onClick: displayStatus,
            render: (row) => (
                row?.pendingVerification ?
                    <BlinkingBadge sx={{
                        '& .MuiBadge-anchorOriginTopLeftCircular': {
                            top: '0px',
                            left: '-8px',
                        },
                    }}
                        position={{ vertical: 'top', horizontal: 'left' }}
                        active={row?.pendingVerification} badgeColor='#F97316'>
                        {row?.name}
                    </BlinkingBadge> : <p> {row?.name} </p>
            )
        },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        {
            id: 'admissionYear', label: 'Admission Year', minWidth: 140, type: 'text',
            render: (row) => (
                <div>
                    {row?.admissionYear}-{(row?.admissionYear + 1).toString().slice(-2)}
                </div>
            )
        },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        {
            id: 'demote', label: 'Transfer to Applicant', type: 'iconButton',
            align: 'center', onClick: handleDemote,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <DemoteIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'completion', label: 'Transfer to Alumni', type: 'iconButton',
            align: 'center', onClick: handleCompletion,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-8 w-8 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'dropout', label: 'Dropout Student', type: 'iconButton',
            align: 'center', onClick: handleDropout,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-8 w-8 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    const transferFields = [
        {
            type: 'select',
            name: 'graduationYear',
            label: 'Graduation Year',
            options: futureYearsArray(user?.admissionYear + user?.duration)
        }
    ]

    const dropoutFields = [
        {
            type: 'date',
            name: 'dropoutTimeStamp',
            label: 'Dropout Date',
        }
    ]

    const demoteFields = [
        {
            rows: 4,
            type: 'input',
            name: 'statusMessage',
            label: 'Remark',
            multiline: true,
            placeholder: 'Add a remark'
        }
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={StudentIcon} data={statsData} statsLoading={statsLoading} />

            <CustomTable
                heading="All Enrolled Students"
                text="Enrolled Students data"
                isLoading={enrollDataLoading}
                columns={columns} rows={enrollData}
                sortParameters={['name', 'admissionYear', 'programKey']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
                extraOperations={customOperations}
            />
        </div>
    )
}

export default EnrolledStudents;