import React from "react";
import { Dialog } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";

import { CloseButton, ConfirmButton } from "../../../mui/button/Button";
import TextBox from "../../../mui/text/TextBox";

import { certificationFields, childrenFields, diplomaFields, doctorateFields, elementaryFields, emergencyPersonFields, employmentFields, fatherFields, financialFields, identityFields, intermediateFields, motherFields, partnerFields, permanentAddressFields, personalFields, postgraduateFields, residentialAddressFields, undergraduateFields } from "../../../../constants/labelConstants";

const CompareTile = ({ field }) =>
  <React.Fragment>
    <TextBox label={field.label} value={field.value} className='col-span-9' />
    <ArrowRightAlt className="col-span-2" />
    <TextBox label={field.label} value={field.new} className='col-span-9' />
  </React.Fragment>

const Section = ({ title, fields }) => (
  <>
    <h6 className='col-span-full lb-s'>{title}</h6>
    {fields.map((field, index) => (
      <CompareTile key={index} field={field} />
    ))}
  </>
);

const ArraySection = ({ title, fieldsArray, getLabel }) => (
  <>
    {fieldsArray?.map((fields, i) => (
      <React.Fragment key={i}>
        <h4 className="col-span-full lb-s">
          {title} {fieldsArray.length > 1 ? (i + 1) : getLabel}
        </h4>
        {fields.map((field, index) => field.new && (
          <CompareTile key={index} field={field} />
        ))}
      </React.Fragment>
    ))}
  </>
);

const UpdateChangesPopup = ({ data, previousData, onClose, onConfirm }) => {
  const renderFields = (fields) => fields.map((field, index) =>
    field.new && <CompareTile key={index} field={field} />
  );
console.log(data);
  return (
    <Dialog open={true} fullWidth maxWidth='sm' onClose={onClose}>
      <div className="flex flex-col w-full min-h-96 h-full bg-white p-6 gap-5">

        <h4 className="h4-p">Preview Changes</h4>
        <div className="flex flex-col h-96 overflow-y-scroll no-scrollbar">
          <div className="grid grid-cols-20  gap-5 h-fit">
            {renderFields(personalFields(previousData, data))}

            {data?.personalInfo?.residentialAddress && (
              <Section
                title="Residential Address"
                fields={residentialAddressFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.personalInfo?.permanentAddress && (
              <Section
                title="Permanent Address"
                fields={permanentAddressFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.identityInfo && (
              <Section
                title="Identity Details"
                fields={identityFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.financialAndLegalInfo && (
              <Section
                title="Financial Details"
                fields={financialFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.education?.elementaryDetail && (
              <Section
                title="Elementary Details"
                fields={elementaryFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.education?.intermediateDetail && (
              <Section
                title="Intermediate Details"
                fields={intermediateFields(previousData, data).filter(field => field.new)}
              />
            )}

            <ArraySection
              title="Diploma"
              fieldsArray={diplomaFields(previousData, data)}
              getLabel="Details"
            />

            <ArraySection
              title="Undergraduate"
              fieldsArray={undergraduateFields(previousData, data)}
              getLabel="Details"
            />

            <ArraySection
              title="Postgraduate"
              fieldsArray={postgraduateFields(previousData, data)}
              getLabel="Details"
            />

            <ArraySection
              title="Doctorate"
              fieldsArray={doctorateFields(previousData, data)}
              getLabel="Details"
            />

            <ArraySection
              title="Certification"
              fieldsArray={certificationFields(previousData, data)}
              getLabel="Details"
            />

            {data?.familyInfo?.fatherDetail && (
              <Section
                title="Father Details"
                fields={fatherFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.familyInfo?.motherDetail && (
              <Section
                title="Mother Details"
                fields={motherFields(previousData, data).filter(field => field.new)}
              />
            )}

            {data?.familyInfo?.partnerDetail && (
              <Section
                title="Partner Details"
                fields={partnerFields(previousData, data).filter(field => field.new)}
              />
            )}

            <ArraySection
              title="Children"
              fieldsArray={childrenFields(previousData, data)}
              getLabel="Details"
            />

            {data?.familyInfo?.emergencyPersonDetail && (
              <Section
                title="Emergency Person Details"
                fields={emergencyPersonFields(previousData, data).filter(field => field.new)}
              />
            )}

            <ArraySection
              title="Employment"
              fieldsArray={employmentFields(previousData, data)}
              getLabel="Details"
            />
          </div>
        </div>

        <div className="flex flex-col xs:flex-row gap-5">
          <CloseButton fullWidth size="large" text="Cancel" onClick={onClose} />
          <ConfirmButton fullWidth size="large" text="Submit" onClick={() => onConfirm(data)} />
        </div>
      </div>
    </Dialog>
  )
}

export default UpdateChangesPopup;