import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSnackbar from './useSnackbar';

const HOST_URL = process.env.REACT_APP_HOST;
// const HOST_URL = 'http://localhost:4000';

const useGetData = (url, options = {}, delay = false, condition = true) => {
    const [data, setData] = useState();
    const [copyData, setCopyData] = useState();
    const [success, setSuccess] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [refetchIndex, setRefetchIndex] = useState(0);
    const [debouncing, setDebouncing] = useState(false);
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();

    useEffect(() => {
        if (!condition) return setIsLoading(false);

        const fetchData = async () => {
            const token = sessionStorage.getItem('auth_token');
            setIsLoading(true);
            setSuccess(false);

            try {
                const response = await fetch(HOST_URL + url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    credentials: 'include',
                    ...options,
                });

                if (response?.status === 403) {
                    handleSnackbarOpen('Your Session is timed out', 'warning')
                    return navigate('/login');
                }

                const result = await response.json();
                if (result.success) { setSuccess(true); setData(result.data); setCopyData(JSON.parse(JSON.stringify(result.data))) }
                else console.error("Error fetching data:", result.error);
            } catch (error) {
                console.error("Error fetching data:", error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (delay && !debouncing) {
            const timeout = setTimeout(() => {
                fetchData();
                setDebouncing(false);
            }, 300);

            return () => clearTimeout(timeout);
        }
        else fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, refetchIndex, condition]);

    const refreshData = () => {
        if (delay) setDebouncing(true);
        else setRefetchIndex(refetchIndex + 1);
    };

    const updateData = updateFn => setData(prevState => updateFn(prevState));

    return { data, copyData, updateData, success, isLoading, refreshData };
};

export default useGetData;
