import LoginInfo from '../../components/other/LoginInfo';
import useGetData from '../../hooks/useGetData'
import Form from './Form';

const Login = () => {
    useGetData('/api/cookies/flushCookies');

    return (
        <div className="relative font-work-sans flex justify-center items-center min-h-screen h-full  md:p-10 md:px-16 bg-gradient-to-t from-regal-purple-950 to-regal-purple-800">

            <div className="relative z-10 grid grid-cols-12 min-h-[670px] h-full w-full max-w-8xl md:rounded-xl bg-white shadow-inner">
                <LoginInfo />
                <Form />
            </div>

        </div>
    )
}

export default Login;