import React from 'react'
import { PurpleButton } from '../../../components/mui/button/Button'
import { Group } from '@mui/icons-material';
import usePopup from '../../../hooks/usePopup';
import SupportIcon from '../../../assets/icons/SupportCircle.svg'

const TechSupport = ({ className }) => {
    const { openPopup } = usePopup();

    const onRequest = (data) => {
        console.log(data);
    }

    const handleRequest = () => openPopup('Request Technical Support',
        'Please provide details about the issue you are facing or the assistance you need', SupportIcon,
        onRequest, undefined, 'input', fields)

    const fields = [
        {
            type: 'input',
            label: 'Message',
            name: 'message',
            placeholder: 'Describe the issue or request for technical assistance...',
            multiline: true, rows: 5
        }
    ]

    return (
        <div className={`flex flex-col gap-8 ${className}`}>
            <div className='flex items-center gap-2'>
                <Group sx={{ color: '#475569' }} />
                <span className='body-semibold text-neutral'>Technical Support</span>
            </div>

            <div className='flex flex-col gap-2'>
                <PurpleButton fullWidth text='Request Technical Support' onClick={handleRequest} />
            </div>
        </div>
    )
}

export default TechSupport;