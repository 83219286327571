import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Link, useNavigate } from 'react-router-dom'

import { IconButton } from '@mui/material'
import { AccountCircle, Lock, Visibility, VisibilityOff } from '@mui/icons-material'

import { loadingState, loggedInState } from '../../recoil/state'
import useFormOperations from '../../hooks/useFormOperations'
import usePostData from '../../hooks/usePostData'
import useSnackbar from '../../hooks/useSnackbar'

import { PurpleButton } from '../../components/mui/button/Button'
import { CustomTextField } from '../../components/mui/Input'

const LoginPassword = () => {
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const setLoggedInState = useSetRecoilState(loggedInState);
    const { postData } = usePostData();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const onSubmit = async (formData) => {
        setLoading(true)
        const { data, success, message } = await postData('/api/login', formData)
        setLoading(false)
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Invalid Credentials', 'error', null, 'bottom', 'left');

        const { loginType } = data;
        setLoggedInState(loginType);
        handleSnackbarOpen('Login successful', 'success', null, 'bottom', 'left');
        navigate('/admin/dashboard');
    };

    const { formData, handleSubmit, handleChange } = useFormOperations({}, onSubmit);

    return (
        <form className='flex flex-col gap-6 w-full justify-center items-center' onSubmit={handleSubmit}>
            <CustomTextField
                label="User ID"
                name="username"
                value={formData?.username ?? ''}
                placeholder='Enter your official User ID'
                fullWidth
                startIcon={<AccountCircle className="text-regal-purple-600" />}
                onChange={handleChange}
            />

            <div className='flex flex-col gap-[7px] w-full'>
                <CustomTextField
                    label="Password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    startIcon={<Lock className="text-regal-purple-600" />}
                    endIcon={
                        <IconButton aria-label="toggle password visibility"
                            onClick={handleClickShowPassword} edge="end"
                        >
                            {showPassword ? <VisibilityOff className="text-regal-purple-600" /> : <Visibility className="text-regal-purple-600" />}
                        </IconButton>
                    } onChange={handleChange}
                />

                <Link to='/forgotPassword' className='text-s hover:text-regal-purple-500 hover:underline w-fit'>
                    Forgot Password?
                </Link>
            </div>

            <PurpleButton size='large' text='login' type='submit' fullWidth sx={{ paddingX: 3, paddingY: 1.3 }} />
        </form>
    )
}

export default LoginPassword