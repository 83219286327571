import React from "react";
import Lottie from "lottie-react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useMediaQuery } from "@mui/material";
import { Search } from "@mui/icons-material";

import useTable from "../../hooks/useTable";
import SkeletonText from "./text/SkeltonText";
import { dateFormat } from "../../utils/dateFormat";
import NoData from '../../assets/lotties/NoData.json'
import { CustomSelect } from "./Input";

const CustomTable = ({ heading, text, isLoading, columns, rows, sortParameters, filterOptions, elevation = 0, handleRowClick = () => { }, pagination = true, activeOperations = false, extraOperations }) => {
    const isTabScreen = useMediaQuery('(max-width:890px)');

    const contentFunctions = {
        text: (row, column, index, columnIndex) => column.render ? column.render(row) : columnIndex === 0 ? (page * rowsPerPage + index + 1) : row[column?.id] ?? 'N/A',
        date: (row, column) => column.render ? column.render(row) : dateFormat(row[column?.id]),
        array: (row, column) => column.render ? column.render(row) :
            row[column?.id]?.join(', ') ?? 'N/A',
        icon: (row, column) => React.cloneElement(column?.icon[row[column?.id]]),
        iconButton: (row, column) => column.render
            ? column.render(row)
            : (
                <IconButton onClick={(event) => {
                    event.stopPropagation();
                    column.onClick(event, row?._id, row);
                }} disableRipple>
                    {column?.icon[row[column?.id]]}
                </IconButton>
            )
    };

    const { sortedRows, currentRows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage,
        handleRequestSort, orderState, handleSearch } = useTable(rows, columns.filter(col => sortParameters.includes(col.id)).map(col => ({ id: col.id, customSort: col.customSort })), pagination);

    return (
        <div className={activeOperations ? "flex flex-col min-h-[20rem] gap-5 bg-violet-50 rounded-t-lg" : "flex w-full"}>

            {activeOperations && <div className="px-3 pt-4 xs:pt-6 xs:px-6 flex flex-col xs:flex-row justify-between gap-5">
                <div className="flex flex-col">
                    <h2 className="h2-p">{heading} </h2>
                    <p className="h5-p text-[#16c098]">{text}</p>
                </div>
                <div className='h-fit'>
                    {extraOperations && extraOperations()}
                </div>
            </div>}

            {activeOperations && <div className="flex sm:flex-row flex-col px-3 sm:px-5 space-y-5 sm:space-y-0 sm:space-x-10 sm:justify-between">
                <div className='flex items-center h-14 gap-2 bg-white px-2 sm:px-3 rounded-lg w-full'>
                    <IconButton size={isTabScreen ? 'small' : 'medium'} aria-label="search Icon">
                        <Search fontSize={isTabScreen ? 'small' : 'medium'} />
                    </IconButton>
                    <input
                        type="text"
                        placeholder='Search here...'
                        className='flex w-full items-center justify-center font-sans font-medium text-gray-900'
                        style={{ outline: 'none', padding: '2px', fontSize: '15px' }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <CustomSelect required={false} label="Filter By" className="w-52" options={filterOptions} />
            </div>}

            <Paper className='border' sx={{ width: '100%', overflow: 'hidden' }} elevation={elevation}>
                <TableContainer sx={{ minHeight: (rows?.length !== undefined && rows?.length !== 0) ? 280 : 0, maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns?.map(column => (!column?.hidden || isLoading || column?.hidden === undefined) &&
                                    <TableCell key={column?.id} align={column?.align ? column?.align : 'left'} style={{ fontWeight: 'bold' }}
                                        sortDirection={orderState?.orderBy === column?.id ? orderState?.order : false}
                                    >
                                        {sortParameters?.includes(column?.id) ? (
                                            <TableSortLabel active={orderState?.orderBy === column?.id}
                                                direction={orderState?.orderBy === column?.id ? orderState?.order : 'asc'}
                                                onClick={() => handleRequestSort(column?.id)}
                                            >
                                                {column?.label}
                                            </TableSortLabel>
                                        ) : column?.label}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>

                        {!(!isLoading && (currentRows?.length === undefined || currentRows?.length === 0)) && (
                            <TableBody>
                                {currentRows?.length > 0 ? currentRows?.map((row, index) => (
                                    <TableRow hover tabIndex={-1} key={index} onClick={(event) => handleRowClick(event, row?._id, row)} sx={{ cursor: "pointer" }}>

                                        {columns?.map((column, columnIndex) => {
                                            const cellContent = contentFunctions[column.type](row, column, index, columnIndex);
                                            return (
                                                (!column?.hidden || column?.hidden === undefined) &&
                                                <TableCell key={column.id} align={column.align || 'left'} sx={{ textAlign: column.align || 'left', transition: 'background-color 0.3s', '&:hover': { backgroundColor: column.type === 'iconButton' ? '#F0F0F0' : '' }, }}
                                                    onClick={column.type === 'iconButton' ? (event) => {
                                                        event.stopPropagation(); column.onClick(event, row._id, row);
                                                    } : undefined}>
                                                    {cellContent}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )) : (
                                    Array.from({ length: 10 }).map((_, index) => (
                                        <TableRow tabIndex={-1} key={index}>
                                            {columns?.map((column, columnIndex) => (
                                                <TableCell key={column?.id} align="left">
                                                    <SkeletonText displayEmpty={false} key={index} loading={isLoading} />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                {(!isLoading && (currentRows?.length === undefined || currentRows?.length === 0)) && (
                    <div className='flex flex-col justify-center items-center text-center p-12 h-full w-full'>
                        <Lottie className='h-60' animationData={NoData} loop={true} />
                        <div className='body-regular text-light'>No admission data found.</div>
                    </div>
                )}

                {pagination && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={sortedRows?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </div>
    );
}

export default CustomTable;