import React from 'react'
import renderFormField from '../../../../../utils/renderFormField';

const FinanceInfo = ({ formData, handleNestedChange, isLoading }) => {
    const financialDetailsFields = [
        { type: 'input', label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName, name: "formData.financialAndLegalInfo.bankName" },
        { type: 'input', label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo, name: "formData.financialAndLegalInfo.accountNo", customType: "number" },
        { type: 'input', label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc, name: "formData.financialAndLegalInfo.ifsc" },
        { type: 'input', label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo, name: "formData.financialAndLegalInfo.aadharCardNo", customType: "number" },
        { type: 'input', label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo, name: "formData.financialAndLegalInfo.panCardNo", required: false },
    ]
    
    return (
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
            <h5 className="col-span-full h4-p">Financial Details</h5>
            {financialDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
        </div>
    )
}

export default FinanceInfo;