import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import mail from "../../assets/icons/loginMail.svg"
import password from "../../assets/icons/loginPassword.svg"
import LoginGoogle from './LoginGoogle';
import LoginPassword from './LoginPassword'
import LoginOtp from './LoginOtp'

const Fields = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const activeMode = searchParams.get('type')

    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const handleSignInModeChange = async () => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', activeMode === 'otp' ? 'password' : 'otp');
            return Object.fromEntries(newParams.entries());
        });
    }

    return (
        <div className='flex flex-col gap-5 max-w-md w-full' >
            <div className='flex flex-col items-center gap-3'>
                {activeMode === 'otp' ? <LoginOtp emailSubmitted={emailSubmitted} setEmailSubmitted={setEmailSubmitted} /> : <LoginPassword />}
            </div>

            <div className='flex justify-center items-center w-full gap-5'>
                <hr className='border w-full' />
                <p className='text-s font-semibold text-center min-w-fit'>Reconnect with a touch</p>
                <hr className='border w-full' />
            </div>

            <div className='grid grid-cols-2 gap-5'>
                <LoginGoogle />

                <button className='flex p-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-md w-full select-none' onClick={handleSignInModeChange}>
                    <img draggable={false} src={activeMode === 'otp' ? password : mail} className='h-6 w-6' alt="googleImage" />
                    <h5 className='text-p font-semibold'>{activeMode === 'otp' ? 'Sign in  with Password' : 'Sign in with OTP'}</h5>
                </button>
            </div>

            <p className='text-s text-end'>Not registered yet? <Link to='/admissions/login?type=Student' className='hover:text-regal-purple-500 hover:underline'>Join with Us.</Link></p>
        </div>
    )
}

export default Fields;