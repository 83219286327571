import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const PostgraduateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const pgDetailFields = formData?.education?.pgDetails?.map((pg, index) => [
        { label: 'Degree', value: formData?.education?.pgDetails?.[index]?.degree?.status, data: pg?.degree?.data, name: `formData.education.pgDetails.${index}.degree.status` },
        { label: 'Major', value: formData?.education?.pgDetails?.[index]?.major?.status, data: pg?.major?.data, name: `formData.education.pgDetails.${index}.major.status` },
        { label: 'Completion Year', value: formData?.education?.pgDetails?.[index]?.passingYear?.status, data: pg?.passingYear?.data, name: `formData.education.pgDetails.${index}.passingYear.status` },
        { label: 'Degree Type', value: formData?.education?.pgDetails?.[index]?.degreeType?.status, data: pg?.degreeType?.data, name: `formData.education.pgDetails.${index}.degreeType.status` },
        { label: 'College Name', value: formData?.education?.pgDetails?.[index]?.collegeName?.status, data: pg?.collegeName?.data, name: `formData.education.pgDetails.${index}.collegeName.status` },
        { label: 'College City', value: formData?.education?.pgDetails?.[index]?.city?.status, data: pg?.city?.data, name: `formData.education.pgDetails.${index}.city.status` },
        { label: 'College State', value: formData?.education?.pgDetails?.[index]?.state?.status, data: pg?.state?.data, name: `formData.education.pgDetails.${index}.state.status` },
        { label: 'College Country', value: formData?.education?.pgDetails?.[index]?.country?.status, data: pg?.country?.data, name: `formData.education.pgDetails.${index}.country.status` },
        { label: 'Percentage', value: formData?.education?.pgDetails?.[index]?.percentage?.status, data: pg?.percentage?.data, name: `formData.education.pgDetails.${index}.percentage.status` },
        { label: 'Grade', value: formData?.education?.pgDetails?.[index]?.grade?.status, data: pg?.grade?.data, name: `formData.education.pgDetails.${index}.grade.status` },
        { label: 'CGPA', value: formData?.education?.pgDetails?.[index]?.cgpa?.status, data: pg?.cgpa?.data, name: `formData.education.pgDetails.${index}.cgpa.status` }
    ]);

    return (
        pgDetailFields?.map((fields, i) =>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Postgraduate ${pgDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Postgraduate {pgDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                </CustomCheckBox>

                {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                    <div key={`${i}-${index}`}>
                        <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field.label} value={field.data} loading={isLoading} />
                        </CustomCheckBox>
                    </div>
                )}
            </div>
        )
    )
}

export default PostgraduateInfo;