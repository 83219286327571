import { useParams, useSearchParams } from 'react-router-dom';
import { LocalPhone, MailOutline, CalendarMonth, VpnKey, PendingOutlined, VerifiedOutlined, CancelOutlined } from '@mui/icons-material';
import { Avatar, useMediaQuery } from '@mui/material';

import useGetData from "../../../../hooks/useGetData";
import useViewPopup from '../../../../hooks/useViewPopup';
import { dateFormat } from '../../../../utils/dateFormat';

import TabSmall from '../../../../components/mui/tab/TabSmall';
import SkeletonText from '../../../../components/mui/text/SkeltonText';
import BubbleTile from '../../../../components/tiles/BubbleTileFields';
import BlackToolTip from '../../../../components/mui/BlackToolTip';

import BasicInfo from './BasicInfo';
import PersonalDetails from './PersonalDetails';
import FamilyDetails from './FamilyDetails';
import EducationDetails from './EducationDetails';
import FinanceDetails from './FinanceDetails';
import DocumentDetails from './DocumentDetails';
import BackNavigate from '../../../../components/mui/BackNavigate';

const Profile = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const { openPopup } = useViewPopup();

    const { data: applicantData, isLoading: applicantDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getApplicant/${id}` : "/api/admission/student/getApplicant");
    const { data: statusData, isLoading: statusDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getStatus/${id}` : "/api/admission/student/getStatus");

    const activeTab = searchParams.get('type');
    const isSmallScreen = useMediaQuery('(max-width:500px)');

    const tabs = ['Personal', 'Education', 'Family', 'Finance', 'Documents'];
    const fullName = `${applicantData?.firstName} ${applicantData?.middleName ?? ""} ${applicantData?.lastName}`;

    const statusIconMap = {
        Pending: <PendingOutlined className='text-amber-500' />,
        Verified: <VerifiedOutlined className='text-green' />,
        Rejected: <CancelOutlined className='text-signal' />,
    };

    const contactInfoMap = [
        { label: 'Admission Year', data: `${applicantData?.admissionYear}-${(applicantData?.admissionYear + 1).toString().slice(-2)}`, icon: <CalendarMonth color='secondary' /> },
        { label: 'Admission ID', data: applicantData?.admissionId, icon: <VpnKey color='warning' /> },
        { label: 'Contact Number', data: applicantData?.phNo, icon: <LocalPhone color='success' /> },
        { label: 'Email ID', data: applicantData?.emailId, icon: <MailOutline color='error' /> },
    ];

    const statusMap = [
        {
            label: 'Form Submitted',
            date: statusData?.submittedTimeStamp ?? '',
            icon: statusData?.submitted ? statusIconMap.Verified : statusIconMap.Pending
        },
        {
            label: 'Online Verification',
            date: statusData?.onlineVerificationStatus !== 'Pending' ? statusData?.onlineVerificationTimeStamp : '',
            icon: statusData?.onlineVerificationStatus ? statusIconMap[statusData?.onlineVerificationStatus] : statusIconMap.Pending,
            title: statusData?.onlineVerificationStatus === 'Rejected' && 'View Online Verification Details',
            onClick: () => statusData?.onlineVerificationStatus === 'Rejected' && openPopup('Online Verification Rejection Reason', statusData?.onlineVerificationReason ?? 'No Reason mentioned by the authority', 'reject')
        },
        {
            label: 'Offline Verification',
            date: statusData?.offlineVerificationStatus !== 'Pending' ? statusData?.offlineVerificationTimeStamp : '',
            icon: statusData?.offlineVerificationStatus ? statusIconMap[statusData?.offlineVerificationStatus] : statusIconMap.Pending
        },
    ];

    return (
        <div className='flex flex-col gap-y-5 w-full h-full bg-secondary xs:p-5'>
            <div className='flex flex-col gap-5 bg-white p-5 xs:py-6 xs:px-8 xs:rounded-lg drop-shadow-md min-h-svh'>

                <div className='relative flex flex-col tab:flex-row items-center tab:items-start gap-5'>
                    {id && <BackNavigate previousLink='/admin/student/applicants' text='Back' subText='Return to applicants' className='absolute left-0 top-0 tab:hidden' />}
                    <Avatar loading={applicantDataLoading} src={applicantData?.photoURL} alt={fullName} variant="rounded" sx={{ fontSize: 125, width: 200, height: 200 }} />
                    <div className='flex flex-col gap-6 w-full'>
                        <div className='flex flex-col gap-1 items-center tab:items-start tab:w-full'>
                            <div className='flex justify-between tab:w-full'>
                                <SkeletonText loading={applicantDataLoading} width={'50%'} className='h4-p sm:h2-p'>{fullName}</SkeletonText>
                                {id && <BackNavigate previousLink='/admin/student/applicants' text='Back' subText='Return to applicants' className='hidden tab:flex' />}
                            </div>
                            <SkeletonText loading={applicantDataLoading} lines={isSmallScreen ? 2 : 1}
                                className='lb-s text-center' width={'75%'}>
                                {applicantData?.admissionQuota} | {applicantData?.programKey}
                                <span className='text-purple'>| {applicantData?.collegeName} </span>
                            </SkeletonText>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-8'>
                            <div className='grid xs:grid-cols-2 xl:grid-cols-4 gap-5 w-fit xs:w-full truncate'>
                                {contactInfoMap?.map((info, index) => (
                                    <div className='flex flex-col gap-1' key={index}>
                                        <div className='flex gap-2 items-center'>
                                            {info?.icon}
                                            <span className='text-p text-[15px]'>{info.label}</span>
                                        </div>
                                        <SkeletonText loading={applicantDataLoading} width={150} className='text-s'>{info.data}</SkeletonText>
                                    </div>
                                ))}
                            </div>

                            <div className='grid xs:grid-cols-2 md:grid-cols-3 tab:grid-cols-2 xl:grid-cols-4 place-items-start sm:place-items-stretch tab:place-items-start gap-5 w-fit xs:w-full truncate'>
                                {statusMap?.map((status, index) => (
                                    <BlackToolTip title={status.title} onClick={status.onClick} className='flex flex-col gap-1' key={index}>
                                        <div className='flex gap-2 items-center cursor-pointer w-full mb-1'>
                                            {status?.icon}
                                            <span className='text-p text-[15px] w-full'>{status.label}</span>
                                        </div>
                                        <SkeletonText loading={statusDataLoading} width={150} className='text-s cursor-pointer w-full'>{dateFormat(status.date, null, 'Not Available')}</SkeletonText>
                                    </BlackToolTip>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>

                <div className='flex flex-col-reverse lg:flex-row gap-10 w-full h-full'>
                    <div className='flex flex-col flex-shrink-0 w-full lg:w-56 gap-5 mt-3 xs:mt-5 lg:mt-9'>
                        <BasicInfo basicData={applicantData?.personalInfo} loading={applicantDataLoading} />
                        <BubbleTile title="Hobbies" data={applicantData?.additionalInfo?.hobbies} loading={applicantDataLoading} />
                    </div>

                    <div className='flex flex-col w-full gap-5'>
                        <TabSmall tabs={tabs} />
                        {/* todo: fix tab heights lg:h-[calc(100svh-400px)]*/}
                        <div className='flex flex-col w-full'>
                            {activeTab === "Personal" && <PersonalDetails data={applicantData} loading={applicantDataLoading} />}
                            {activeTab === "Education" && <EducationDetails educationData={applicantData?.exams} loading={applicantDataLoading} />}
                            {activeTab === "Family" && <FamilyDetails familyData={applicantData?.familyInfo} loading={applicantDataLoading} />}
                            {activeTab === "Finance" && <FinanceDetails financeData={applicantData?.financialAndLegalInfo} loading={applicantDataLoading} />}
                            {activeTab === "Documents" && <DocumentDetails documentData={applicantData?.documents} loading={applicantDataLoading} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;