import React from 'react'
import Trophy from '../../../assets/images/trophy.png'
import SkeletonText from '../../../components/mui/text/SkeltonText';

const Occasion = ({ className, smallScreen, isLoading }) => {
    return (
        <div className={`flex items-center justify-between gap-5 ${className}`}>
            <p className='flex flex-col truncate gap-1'>
            <SkeletonText loading={false} width='50%' className='h3-p font-semibold whitespace-normal'>Happy Work Anniversary!</SkeletonText>
                <SkeletonText loading={false} width='80%' className='lb-s whitespace-normal text-purple'>Congratulations on completing another year with us.Your dedication and hard work are truly appreciated.</SkeletonText>
            </p>
            {smallScreen && <img className='h-24 w-32' src={Trophy} alt='Byteswrite'/>}
        </div>
    )
}

export default Occasion;