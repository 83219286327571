export const dateFormat = (dateString, showTime = false, defaultValue = 'N/A') => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };

    if (showTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
        options.hour12 = true;
    }

    return dateString ? new Date(dateString).toLocaleString('en-US', options) : defaultValue;
};

export const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const pastYearsArray = (startYear = 1950) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
        const year = currentYear - i;
        return { value: year, label: `${year}-${(year + 1) % 100}` };
    });
};

export const futureYearsArray = (startYear = new Date().getFullYear(), futureLimit = 10) => {
    return Array.from({ length: futureLimit }, (_, i) => {
        const year = startYear + i;
        return { value: year, label: `${year}-${(year + 1) % 100}` };
    });
};

export const calculateExperience = (startDate, endDate = new Date()) => {
    const diffInMs = new Date(endDate) - new Date(startDate);
    if (diffInMs < 0) return 'Invalid Experience';

    const totalDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const years = Math.floor(totalDays / 365);
    const days = totalDays % 365;

    return `${years ? `${years} year${years > 1 ? 's' : ''} ` : ''}${days ? `${days} day${days > 1 ? 's' : ''}` : ''}`.trim() || '0 days';
};