import React from "react";
import useFetchData from "../../../../hooks/useFetchData";
import useSnackbar from "../../../../hooks/useSnackbar";
import DocumentBox from "../../../../components/tiles/documents/DocumentBox";
import { useParams } from "react-router-dom";

const DocumentSection = ({ title, documents, loading, handleClick, index }) => {
    return (
        (documents?.length > 0 || loading) && (
            <React.Fragment>
                {index!==0 && <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />}
                <div className='flex flex-col w-full gap-5'>
                    <div className='flex items-center gap-4'>
                        <p className='lb-s whitespace-nowrap'>{title}</p>
                        <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                        {(!loading ? documents : Array.from({ length: 4 })).map((document, index) => (
                            <DocumentBox
                                key={index}
                                label={document?.title}
                                value={document?.description}
                                status={document?.onlineStatus}
                                handleClick={() => handleClick(document?.key)}
                                loading={loading}
                            />
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    );
};

const DocumentDetails = ({ documentData, loading }) => {
    const { id } = useParams();
    const { fetchData, error } = useFetchData();
    const { handleSnackbarOpen } = useSnackbar();

    const handleClick = async (key) => {
        const signedURL = await fetchData(id ? `/api/employee/admission/applicants/getViewURL/${id}?key=${key}` : `/api/admission/student/getViewURL?key=${key}`);
        if (error) handleSnackbarOpen('Oops.. Some error occurred', 'warning');
        else window.open(signedURL, '_blank');
    };

    const sections = [
        { title: "Common Documents", documents: documentData?.commonDocuments || [], optional: documentData?.optionalCommonDocuments || [] },
        { title: "Academic Documents", documents: documentData?.academicDocuments || [] },
        { title: "Program Documents", documents: documentData?.programDocuments || [] },
        { title: "Gap Documents", documents: documentData?.gapDocuments || [] },
        { title: "Category Documents", documents: documentData?.categoryDocuments || [] },
        { title: "Quota Documents", documents: documentData?.quotaDocuments || [] },
        { title: "Round Documents", documents: documentData?.roundDocuments || [] }
    ];

    return (
        <div className='flex flex-col w-full space-y-8'>
            {sections.map((section, index) => (
                <DocumentSection
                    key={index}
                    index={index}
                    title={section.title}
                    documents={section.documents.concat(section.optional || [])}
                    loading={loading}
                    handleClick={handleClick}
                />
            ))}
        </div>
    );
};

export default DocumentDetails;