import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const GeneralInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const generalInfoFields = [
        { label: "Admission Year", value: formData?.admissionYear?.status, data: `${formData?.admissionYear?.data}-${(formData?.admissionYear?.data + 1).toString().slice(-2)}`, name: 'formData.admissionYear.status' },
        { label: "Email ID", value: formData?.emailId?.status, data: formData?.emailId?.data, name: 'formData.emailId.status' },
        { label: "Program Name", value: formData?.programName?.status, data: formData?.programName?.data, name: 'formData.programName.status' },
        { label: "Round Type", value: formData?.roundType?.status, data: formData?.roundType?.data, name: 'formData.roundType.status' },
        { label: "Round ID", value: formData?.roundId?.status, data: formData?.roundId?.data, name: 'formData.roundId.status' },
        { label: "Admission Quota", value: formData?.admissionQuota?.status, data: formData?.admissionQuota?.data, name: 'formData.admissionQuota.status' }
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full w-fit" onChange={(e) => handleMasterCheckBox(e, 'General Details')} value={masterCheckBoxValue(generalInfoFields, checkBoxOptions)} name={generalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">General Details</h4>
            </CustomCheckBox>

            {generalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
            </CustomCheckBox>
            )}
        </div>
    )
}

export default GeneralInfo;