import { Checkbox } from '@mui/material';

import { degreeTypes, boards, diplomas, diplomaMajors, underGraduation, undergraduateMajors, postGraduation, postgraduateMajors, doctorate, certification } from '../../../../constants/education';

import renderFormField from '../../../../utils/renderFormField';
import { generateGeoOptions } from '../../../../utils/geoLocationOptions';
import { deleteKeys } from '../../../../utils/formFunctions';
import { pastYearsArray } from '../../../../utils/dateFormat';

import BottomTile from './BottomTile';
import { CustomMultiCheckboxSelect } from '../../../../components/mui/Input';
import AddComponent from '../../../../components/mui/AddComponent';

const AcademicsDetails = ({ id, formData, verifiedValues, formOperations, isLoading, steps }) => {
  const { handleNestedChange, handleSubmit, updateData, clearForm } = formOperations;

  const selectedDegrees = formData?.education ? (Object?.keys(formData?.education)?.filter(key => key !== 'elementaryDetail')) : [];

  const { countryOptions, stateOptions: elementaryState, cityOptions: elementaryCity } = generateGeoOptions(formData?.education?.elementaryDetail?.country, formData?.education?.elementaryDetail?.state);
  const { stateOptions: intermediateState, cityOptions: intermediateCity } = generateGeoOptions(formData?.education?.intermediateDetail?.country, formData?.education?.intermediateDetail?.state);

  const handleAddDelete = (newlyAddedElement, newlyDeletedElement) => {
    const ignoredElements = (element) => element && !['intermediateDetail'].includes(element);

    if (newlyAddedElement) {
      if (!ignoredElements(newlyAddedElement)) return handleNestedChange({ target: { name: `formData.education.${newlyAddedElement}`, value: {} } });
      handleNestedChange({ target: { name: `formData.education.${newlyAddedElement}`, value: [{}] } });
    } else if (newlyDeletedElement) {
      let updatedEducation = { ...formData.education };
      delete updatedEducation[newlyDeletedElement];
      handleNestedChange({ target: { name: 'formData.education', value: updatedEducation } });
    }
  }

  const handleExperienceCheckbox = () => {
    if (formData?.experience?.length > 0) {
      let updatedFormData = { ...formData };
      deleteKeys(updatedFormData, ['experience'])
      updateData((formData) => ({ ...updatedFormData }));
    } else {
      handleNestedChange({ target: { name: 'formData.experience', value: [{}] } });
    }
  };

  const degreeTypeOptions = degreeTypes.map(type => ({ value: type, label: type }));
  const boardOptions = boards.map(board => ({ value: board, label: board }));
  const diplomaOptions = diplomas.map(diploma => ({ value: diploma, label: diploma }));
  const diplomaMajorOptions = diplomaMajors.map(diploma => ({ value: diploma, label: diploma }));
  const ugOptions = underGraduation.map(ug => ({ value: ug, label: ug }));
  const ugMajorOptions = undergraduateMajors.map(ug => ({ value: ug, label: ug }));
  const pgOptions = postGraduation.map(pg => ({ value: pg, label: pg }));
  const pgMajorOptions = postgraduateMajors.map(pg => ({ value: pg, label: pg }));
  const doctorateOptions = doctorate.map(phd => ({ value: phd, label: phd }));
  const certificationOptions = certification.map(certificate => ({ value: certificate, label: certificate }));

  const elementaryFields = [
    { type: 'select', size: 'full', name: 'formData.education.elementaryDetail.boardName', label: 'Board Name', options: boardOptions, value: formData?.education?.elementaryDetail?.boardName, skeletonClassName: 'col-span-full' },
    { type: 'select', name: 'formData.education.elementaryDetail.examYear', label: 'Exam Year', options: pastYearsArray(), value: formData?.education?.elementaryDetail?.examYear },
    { type: 'input', name: 'formData.education.elementaryDetail.schoolName', label: 'School Name', value: formData?.education?.elementaryDetail?.schoolName },
    { type: 'select', name: 'formData.education.elementaryDetail.country', label: 'School Country', value: formData?.education?.elementaryDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.education.elementaryDetail.state', label: 'School State', value: formData?.education?.elementaryDetail?.state, options: elementaryState, noOptionsMessage: 'Select country first' },
    { type: 'select', name: 'formData.education.elementaryDetail.city', label: 'School City', value: formData?.education?.elementaryDetail?.city, options: elementaryCity, noOptionsMessage: 'Select state first' },
    { type: 'space' },
    { type: 'input', name: 'formData.education.elementaryDetail.obtainedMarks', label: 'Obtained Marks', customType: 'number', value: formData?.education?.elementaryDetail?.obtainedMarks },
    { type: 'input', name: 'formData.education.elementaryDetail.totalMarks', label: 'Total Marks', customType: 'number', value: formData?.education?.elementaryDetail?.totalMarks },
    { type: 'input', name: 'formData.education.elementaryDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.education?.elementaryDetail?.percentage },
    { type: 'input', name: 'formData.education.elementaryDetail.grade', label: 'Grade', customType: 'grade', value: formData?.education?.elementaryDetail?.grade, required: false },
    { type: 'input', name: 'formData.education.elementaryDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.education?.elementaryDetail?.cgpa, required: false }
  ];

  const intermediateFields = [
    { type: 'select', size: 'full', name: 'formData.education.intermediateDetail.boardName', label: 'Board Name', options: boardOptions, value: formData?.education?.intermediateDetail?.boardName, skeletonClassName: 'col-span-full' },
    { type: 'select', name: 'formData.education.intermediateDetail.examYear', label: 'Exam Year', options: pastYearsArray(), value: formData?.education?.intermediateDetail?.examYear },
    { type: 'input', name: 'formData.education.intermediateDetail.schoolName', label: 'School Name', value: formData?.education?.intermediateDetail?.schoolName },
    { type: 'select', name: 'formData.education.intermediateDetail.country', label: 'School Country', value: formData?.education?.intermediateDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.education.intermediateDetail.state', label: 'School State', value: formData?.education?.intermediateDetail?.state, options: intermediateState, noOptionsMessage: 'Select country first' },
    { type: 'select', name: 'formData.education.intermediateDetail.city', label: 'School City', value: formData?.education?.intermediateDetail?.city, options: intermediateCity, noOptionsMessage: 'Select state first' },
    { type: 'space' },
    { type: 'input', name: 'formData.education.intermediateDetail.obtainedMarks', label: 'Obtained Marks', customType: 'number', value: formData?.education?.intermediateDetail?.obtainedMarks },
    { type: 'input', name: 'formData.education.intermediateDetail.totalMarks', label: 'Total Marks', customType: 'number', value: formData?.education?.intermediateDetail?.totalMarks },
    { type: 'input', name: 'formData.education.intermediateDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.education?.intermediateDetail?.percentage },
    { type: 'input', name: 'formData.education.intermediateDetail.grade', label: 'Grade', required: false, value: formData?.education?.intermediateDetail?.grade, customType: 'grade' },
    { type: 'input', name: 'formData.education.intermediateDetail.cgpa', label: 'CGPA', customType: 'decimal', required: false, value: formData?.education?.intermediateDetail?.cgpa }
  ];

  const diplomaFields = [
    { type: 'select', size: 'full', name: 'degree', label: 'Diploma Course', options: diplomaOptions },
    { type: 'select', name: 'major', label: 'Major', options: diplomaMajorOptions },
    { type: 'select', name: 'degreeType', label: 'Degree Type', options: degreeTypeOptions },
    { type: 'select', name: 'passingYear', label: 'Completion Year', options: pastYearsArray() },
    { type: 'input', name: 'collegeName', label: 'College Name' },
    { type: 'select', name: 'country', label: 'College Country' },
    { type: 'select', name: 'state', label: 'College State' },
    { type: 'select', name: 'city', label: 'College City' },
    { type: 'space' },
    { type: 'input', name: 'percentage', label: 'Percentage', customType: 'decimal' },
    { type: 'input', name: 'grade', label: 'Grade', customType: 'grade', required: false },
    { type: 'input', name: 'cgpa', label: 'CGPA', customType: 'decimal', required: false }
  ];

  const ugFields = [
    { type: 'select', label: 'Degree', name: 'degree', options: ugOptions },
    { type: 'select', label: 'Major', name: 'major', options: ugMajorOptions },
    { type: 'select', name: 'degreeType', label: 'Degree Type', options: degreeTypeOptions },
    { type: 'select', label: 'Completion Year', name: 'passingYear', options: pastYearsArray() },
    { type: 'input', label: 'College Name', name: 'collegeName' },
    { type: 'select', label: 'College Country', name: 'country' },
    { type: 'select', label: 'College State', name: 'state' },
    { type: 'select', label: 'College City', name: 'city' },
    { type: 'space' },
    { type: 'input', label: 'CGPA', name: 'cgpa', customType: 'decimal' },
    { type: 'input', label: 'Percentage', name: 'percentage', customType: 'decimal', required: false },
    { type: 'input', label: 'Grade', name: 'grade', customType: 'grade', required: false }
  ];

  const pgFields = [
    { type: 'select', label: 'Degree', name: 'degree', options: pgOptions },
    { type: 'select', label: 'Major', name: 'major', options: pgMajorOptions },
    { type: 'select', name: 'degreeType', label: 'Degree Type', options: degreeTypeOptions },
    { type: 'select', label: 'Completion Year', name: 'passingYear', options: pastYearsArray() },
    { type: 'input', label: 'College Name', name: 'collegeName' },
    { type: 'select', label: 'College Country', name: 'country' },
    { type: 'select', label: 'College State', name: 'state' },
    { type: 'select', label: 'College City', name: 'city' },
    { type: 'space' },
    { type: 'input', label: 'CGPA', name: 'cgpa', customType: 'decimal' },
    { type: 'input', label: 'Percentage', name: 'percentage', customType: 'decimal', required: false },
    { type: 'input', label: 'Grade', name: 'grade', customType: 'grade', required: false }
  ];

  const doctorateFields = [
    { type: 'select', name: 'degree', label: 'Degree', options: doctorateOptions },
    { type: 'select', name: 'degreeType', label: 'Degree Type', options: degreeTypeOptions },
    { type: 'input', name: 'dissertationTitle', label: 'Dissertation Title' },
    { type: 'input', name: 'supervisor', label: 'Supervisor' },
    { type: 'select', name: 'completionYear', label: 'Completion Year', options: pastYearsArray() },
    { type: 'input', name: 'universityName', label: 'University Name' },
    { type: 'select', name: 'country', label: 'University Country' },
    { type: 'select', name: 'state', label: 'University State' },
    { type: 'select', name: 'city', label: 'University City' },
    { type: 'space' },
    { type: 'input', name: 'cgpa', label: 'CGPA', customType: 'decimal' },
    { type: 'input', name: 'percentage', label: 'Percentage', customType: 'decimal', required: false },
    { type: 'input', name: 'grade', label: 'Grade', customType: 'grade', required: false },
  ];

  const certificationFields = [
    { type: 'select', name: 'certification', label: 'Certification', options: certificationOptions },
    { type: 'select', name: 'certificationType', label: 'Certification Type', options: degreeTypeOptions },
    { type: 'input', name: 'issuingOrganization', label: 'Issuing Organization' },
    { type: 'date', name: 'issueDate', label: 'Issue Date', customType: 'date' },
    { type: 'date', name: 'expiryDate', label: 'Expiry Date', customType: 'date', required: false },
    { type: 'input', name: 'certificateID', label: 'Certificate ID' },
    { type: 'input', name: 'score', label: 'Score', customType: 'decimal', required: false },
    { type: 'input', name: 'certificateUrl', label: 'Certification URL' },
    { type: 'input', name: 'license', label: 'License Number' }
  ];

  const experienceFields = [
    { type: 'input', label: "Organization Name", name: 'organizationName' },
    { type: 'input', label: "Position", name: 'position' },
    { type: 'date', label: "Start Date", name: 'startDate' },
    { type: 'date', label: "End Date", name: 'endDate' },
  ];

  const EducationOptions = [
    { label: 'Intermediate', value: 'intermediateDetail' },
    { label: 'Diploma', value: 'diplomaDetails' },
    { label: 'Under Graduation', value: 'ugDetails' },
    { label: 'Post Graduation', value: 'pgDetails' },
    { label: 'Doctorate (Ph.D.)', value: 'doctorateDetails' },
    { label: 'Professional Certification', value: 'certificationDetails' }
  ];

  const handleDisable = (name) => {
    if (!verifiedValues) return false;
    const value = name?.split('.')?.reduce((acc, key) => acc?.[key], verifiedValues);
    return value?.status !== 'Rejected';
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>

      <div className="flex flex-col gap-4 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        <h5 className="col-span-full h4-p">Education Details</h5>
        <CustomMultiCheckboxSelect className='bg-white' label='Select the education Degrees' value={selectedDegrees} name='formData.qualifications' onChange={handleNestedChange} options={EducationOptions} handleAddDelete={handleAddDelete} color='secondary' skeletonLoading={isLoading} skeletonClassName='col-span-full' disabled={!!verifiedValues} />
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-transparent">
        <h5 className="col-span-full h4-p">Elementary (10<sup>th</sup> Standard) Details</h5>
        {elementaryFields?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('education.elementaryDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading))}
      </div>

      {formData?.education?.intermediateDetail && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        <h5 className="col-span-full h4-p">Intermediate (12<sup>th</sup> Standard) Details</h5>
        {intermediateFields?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('education.intermediateDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading))}
      </div>}

      {formData?.education?.diplomaDetails && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        <AddComponent array={formData?.education?.diplomaDetails} name='formData.education.diplomaDetails' properties={diplomaFields} label='Diploma' handleChange={handleNestedChange} loading={isLoading} verifiedValues={verifiedValues?.education?.diplomaDetails} />
      </div>}

      {formData?.education?.ugDetails && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-transparent">
        <AddComponent array={formData?.education?.ugDetails} name='formData.education.ugDetails' properties={ugFields} label='Under Graduation' handleChange={handleNestedChange} loading={isLoading} verifiedValues={verifiedValues?.education?.ugDetails} />
      </div>}

      {formData?.education?.pgDetails && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        <AddComponent array={formData?.education?.pgDetails} name='formData.education.pgDetails' properties={pgFields} label='Post Graduation' handleChange={handleNestedChange} loading={isLoading} verifiedValues={verifiedValues?.education?.pgDetails} />
      </div>}

      {formData?.education?.doctorateDetails && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-transparent">
        <AddComponent array={formData?.education?.doctorateDetails} name='formData.education.doctorateDetails' properties={doctorateFields} label='Doctorate' handleChange={handleNestedChange} loading={isLoading} verifiedValues={verifiedValues?.education?.doctorateDetails} />
      </div>}

      {formData?.education?.certificationDetails && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        <AddComponent array={formData?.education?.certificationDetails} name='formData.education.certificationDetails' properties={certificationFields} label='Certification' handleChange={handleNestedChange} loading={isLoading} verifiedValues={verifiedValues?.education?.certificationDetails} />
      </div>}

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
        {isLoading ?
          <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
          : <div className="col-span-full flex items-center">
            <Checkbox id="experience-checkbox" checked={!!formData?.experience?.length} disabled={!!verifiedValues} className='bg-purple-800' color="secondary" onChange={handleExperienceCheckbox} />
            <label htmlFor="experience-checkbox" className="text-s cursor-pointer">Do you have any prior experience?</label>
          </div>}
        {formData?.experience?.length > 0 && (
          <AddComponent array={formData?.experience} name='formData.experience' properties={experienceFields} label='Experience' handleChange={handleNestedChange} verifiedValues={verifiedValues?.experience} loading={isLoading} />
        )}
      </div>

      <BottomTile id={id} clearForm={clearForm} steps={steps} onSubmit={handleSubmit} />
    </form >
  )
}

export default AcademicsDetails;