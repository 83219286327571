import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import InProgressIcon from "../assets/lotties/InProgress.gif"
import { loggedInState } from '../recoil/state';

const InProgress = () => {
    const loginType = useRecoilValue(loggedInState);

    return (
        <div className='flex flex-col items-center justify-center min-h-[calc(100svh-5rem)] h-full px-5 pb-10 xs:p-10 xs:pb-14 md:pb-20 md:px-16 xl:px-20 lg:pb-28'>
            <img className='h-48 w-60 xs:h-80 xs:w-96' src={InProgressIcon} alt='BytesWrite Coming Soon' />

            <span className='h1-p tracking-wider'>Coming Soon...</span>

            <div className='flex flex-col items-center text-center gap-2 lb-s font-normal mt-2 max-w-3xl leading-7'>
                Exciting things are on the way! We're diligently crafting new features and enhancements to improve your experience. Please check back soon for updates, or feel free to reach out to us for any inquiries.
            </div>

            <div className='flex items-center w-fit h-full body-regular lb-s font-normal gap-5 xs:gap-8 mt-10'>
                <Link to={loginType === 'Admin Login' ? "/login" :
                    loginType === 'Student Admission Login' ? "/admissions/login?type=Student" :
                        "/admissions/login?type=Employee"} className='hover:lb-p'>Login</Link>

                <hr className='border h-4 rounded-full' />

                <Link to={loginType === 'Admin Login' ? "/admin/profile" :
                    loginType === 'Student Admission Login' ? "/admissions/student/profile" :
                        "/admissions/employee/profile"} className='hover:lb-p'>Profile</Link>
            </div>
        </div>
    )
}

export default InProgress