import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'
import { dateFormat } from '../../../../../../utils/dateFormat';

const PersonalInfo = ({ data }) => {

  const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

  const personalInfoFields = [
    { label: "First Name", value: formData?.firstName?.status, data: formData?.firstName?.data, name: 'formData.firstName.status' },
    { label: "Middle Name", value: formData?.middleName?.status, data: formData?.middleName?.data, name: 'formData.middleName.status' },
    { label: "Last Name", value: formData?.lastName?.status, data: formData?.lastName?.data, name: 'formData.lastName.status' },
    { label: "Gender", value: formData?.personalInfo?.gender?.status, data: formData?.personalInfo?.gender?.data, name: 'formData.personalInfo.gender.status' },
    { label: "Date of Birth", value: formData?.personalInfo?.dob?.status, data: dateFormat(formData?.personalInfo?.dob?.data), name: 'formData.personalInfo.dob.status' },
    { label: "Blood Group", value: formData?.personalInfo?.bloodGroup?.status, data: formData?.personalInfo?.bloodGroup?.data, name: 'formData.personalInfo.bloodGroup.status' },
    { label: "Phone Number", value: formData?.phNo?.status, data: formData?.phNo?.data, name: 'formData.phNo.status' },
  ];

  return (
    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
      <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Personal Details')} value={masterCheckBoxValue(personalInfoFields, checkBoxOptions)} name={personalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
        <h4 className="col-span-full h4-p text-grey">Personal Details</h4>
      </CustomCheckBox>

      {personalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
        <TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
    </div>
  )
}

export default PersonalInfo;