import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const FamilyInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const fatherInfoFields = [
        { label: "Father Alive", value: formData?.familyInfo?.fatherDetail?.isDeceased?.status, data: formData?.familyInfo?.fatherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.fatherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.fatherDetail?.firstName?.status, data: formData?.familyInfo?.fatherDetail?.firstName?.data, name: 'formData.familyInfo.fatherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.fatherDetail?.middleName?.status, data: formData?.familyInfo?.fatherDetail?.middleName?.data, name: 'formData.familyInfo.fatherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.fatherDetail?.lastName?.status, data: formData?.familyInfo?.fatherDetail?.lastName?.data, name: 'formData.familyInfo.fatherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.fatherDetail?.phNo?.status, data: formData?.familyInfo?.fatherDetail?.phNo?.data, name: 'formData.familyInfo.fatherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.fatherDetail?.emailId?.status, data: formData?.familyInfo?.fatherDetail?.emailId?.data, name: 'formData.familyInfo.fatherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.fatherDetail?.income?.status, data: formData?.familyInfo?.fatherDetail?.income?.data, name: 'formData.familyInfo.fatherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.fatherDetail?.education?.status, data: formData?.familyInfo?.fatherDetail?.education?.data, name: 'formData.familyInfo.fatherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.fatherDetail?.profession?.status, data: formData?.familyInfo?.fatherDetail?.profession?.data, name: 'formData.familyInfo.fatherDetail.profession.status' },
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Father Details')} value={masterCheckBoxValue(fatherInfoFields, checkBoxOptions)} name={fatherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Father Details</h4>
            </CustomCheckBox>

            {fatherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
            </CustomCheckBox>)}
        </div>
    )
}

export default FamilyInfo;