import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

const PAGE_TITLES = {
    "/login": "Administrator Login",
    "/admissions/login": "Admissions Login",
    "/forgotpassword": "Password Recovery",
    "/privacypolicy": "Privacy Policy",
    "/termsandconditions": "Terms of Service",
    "/admin": "Administrator",
    "/admissions": "Admissions",
};

const DEFAULT_TITLE = "Higher Education Registration and Enrollment";
const APP_NAME = "REACH";

const usePageMetadata = () => {
    const location = useLocation();

    const pageTitle = useMemo(() => {
        const matchingKey = Object.keys(PAGE_TITLES).find(key => location.pathname.startsWith(key));
        return matchingKey ? PAGE_TITLES[matchingKey] : DEFAULT_TITLE;
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `${APP_NAME} - ${pageTitle}`;
    }, [pageTitle]);
};

export default usePageMetadata;