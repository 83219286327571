import TextBox from '../../../components/mui/text/TextBox';

const BasicInfo = ({ basicData, loading }) => {
    const fields = [
        { label: 'Gender', value: basicData?.gender },
        { label: 'Blood Group', value: basicData?.bloodGroup },
        { label: 'Residential Address', value: basicData?.residentialAddress?.address + ", " + basicData?.residentialAddress?.town + ", " + basicData?.residentialAddress?.city + ", " + basicData?.residentialAddress?.state + ", " + basicData?.residentialAddress?.pincode },
        { label: 'Permanent Address', value: basicData?.permanentAddress?.address + ", " + basicData?.permanentAddress?.town + ", " + basicData?.permanentAddress?.city + ", " + basicData?.permanentAddress?.state + ", " + basicData?.permanentAddress?.pincode },
    ]

    return (
        <div className="flex flex-col gap-4">
            <div className='flex items-center gap-4'>
                <p className='lb-s whitespace-nowrap'>Basic Info</p>
                <hr className='w-full h-0.5 bg-slate-400' />
            </div>

            <div className='flex flex-col gap-4'>
                {fields?.map((field, index) => (
                    <TextBox key={index} label={field.label} value={field.value} loading={loading} lines={field?.lines} />
                ))}
            </div>
        </div>
    )
}

export default BasicInfo;