import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'
import { dateFormat } from '../../../../../../../utils/dateFormat';

const CertificationInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const certificationDetailFields = formData?.education?.certificationDetails?.map((certificate, index) => [
        { label: 'Certification', name: `formData.education.certificationDetails.${index}.certification.status`, value: formData?.education?.certificationDetails?.[index]?.certification?.status, data: certificate?.certification?.data },
        { label: 'Issuing Organization', name: `formData.education.certificationDetails.${index}.issuingOrganization.status`, value: formData?.education?.certificationDetails?.[index]?.issuingOrganization?.status, data: certificate?.issuingOrganization?.data },
        { label: 'Issue Date', name: `formData.education.certificationDetails.${index}.issueDate.status`, value: formData?.education?.certificationDetails?.[index]?.issueDate?.status, data: dateFormat(certificate?.issueDate?.data) },
        { label: 'Expiry Date', name: `formData.education.certificationDetails.${index}.expiryDate.status`, value: formData?.education?.certificationDetails?.[index]?.expiryDate?.status, data: dateFormat(certificate?.expiryDate?.data) },
        { label: 'Certificate ID', name: `formData.education.certificationDetails.${index}.certificateID.status`, value: formData?.education?.certificationDetails?.[index]?.certificateID?.status, data: certificate?.certificateID?.data },
        { label: 'Score', name: `formData.education.certificationDetails.${index}.score.status`, value: formData?.education?.certificationDetails?.[index]?.score?.status, data: certificate?.score?.data },
        { label: 'Certification URL', name: `formData.education.certificationDetails.${index}.certificateUrl.status`, value: formData?.education?.certificationDetails?.[index]?.certificateUrl?.status, data: certificate?.certificateUrl?.data },
        { label: 'License Number', name: `formData.education.certificationDetails.${index}.license.status`, value: formData?.education?.certificationDetails?.[index]?.license?.status, data: certificate?.license?.data }
    ]);

    return (
        certificationDetailFields?.map((fields, i) =>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Certification ${certificationDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Certification {certificationDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                </CustomCheckBox>

                {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                    <div key={`${i}-${index}`}>
                        <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field.label} value={field.data} loading={isLoading} />
                        </CustomCheckBox>
                    </div>
                )}
            </div>
        )
    )
}

export default CertificationInfo;