import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import updateLocale from "dayjs/plugin/updateLocale";

// Extend dayjs for custom locale handling
dayjs.extend(updateLocale);
dayjs.updateLocale("en", { weekStart: 1 });

// Utility to check if a date is a weekend
const isWeekend = (day) => dayjs(day).day() === 6 || dayjs(day).day() === 0;

// ServerDay component with custom weekend styling
const ServerDay = ({ day, outsideCurrentMonth, ...other }) => {
  if (outsideCurrentMonth) {
    return <PickersDay {...other} day={day} outsideCurrentMonth />;
  }

  return (
    <PickersDay
      {...other}
      day={day}
      sx={{
        backgroundColor: isWeekend(day) ? '#F5F5F5' : undefined,
        '&:hover': { backgroundColor: isWeekend(day) ? '#eeeeee' : undefined },
        '&.Mui-selected': {
          backgroundColor: '#5D45DB !important',
          color: '#fff !important',
        },
      }}
    />
  );
};

const Calendar = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={dayjs()}
        slots={{ day: ServerDay }}
        sx={{
          // width: '100%', // Full width
          '& .MuiPickersDay-root': { fontWeight: 'regular' },
          '& .MuiPickersDay-today': {
            color: '#5D45DB',
            '&:not(.Mui-selected)': { border: '1px solid #5D45DB' },
          },
          '& .Mui-selected': {
            backgroundColor: '#5D45DB !important',
            color: '#fff !important',
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;