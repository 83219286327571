import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DemoteIcon from '../../../../assets/icons/DemoteIcon'
import CustomTable from "../../../../components/mui/Table";
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg'
import TransferIcon from "../../../../assets/icons/TransferIcon";
import RenderStats from "../../../../utils/RenderStats";
import BlinkingBadge from "../../../../components/mui/BlinkingBadge";
import { convertArrayCamelCaseToFirstWords } from "../../../../utils/functions";
import { calculateExperience } from "../../../../utils/dateFormat";

const CurrentFaculty = () => {
    const { data: facultyData, isLoading: facultyDataLoading, updateData } = useGetData('/api/employee/hr/currents/getCurrents')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/currents/stats/getDesignationsStats');
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [userId, setUserId] = useState('');

    const handleRow = (_event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/employee/currents/profile/" + value + '?type=Personal'));

    const handleDemote = (_event, value) => {
        setUserId(value);
        openPopup('Demote to Recruit', "Are you sure you want to demote this Employee to Recruit? If the college is using the CAMPUS ERP system, the employee's access will also be terminated from it.",
            'edit', onDemote, undefined, 'input', demoteFields)
    }

    const onDemote = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/currents/updateCurrentDemotion/${userId}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => { return prevState.filter(user => user._id !== userId) });
        handleSnackbarOpen(`Employee successfully Demoted!`, 'success');
    }

    const displayStatus = (_event, id) => {
        const user = facultyData?.find(user => user._id === id) || {};
        if (!user?.pendingVerification) return handleRow(null, user?._id);
        const verificationStatus = convertArrayCamelCaseToFirstWords(user?.pendingVerification);
        return handleSnackbarOpen(`${verificationStatus?.join(', ')} Verification is pending for Employee.`, 'warning');
    };

    const handleOffBoarding = (_event, value) => {
        setUserId(value);
        openPopup('Transfer to Former', 'Are you sure you want to transfer this Employee to Former?',
            'edit', onOffBoarding, undefined, 'input', transferFields)
    }

    const onOffBoarding = async (formData) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/currents/updateCurrentTransfer/${userId}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => { return prevState.filter(user => user._id !== userId) });
        handleSnackbarOpen(`Employee successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        {
            id: 'name', label: 'Faculty Name', minWidth: 200, type: 'iconButton',
            onClick: displayStatus,
            render: (row) => (
                row?.pendingVerification ?
                    <BlinkingBadge sx={{
                        '& .MuiBadge-anchorOriginTopLeftCircular': {
                            top: '0px',
                            left: '-8px',
                        },
                    }}
                        position={{ vertical: 'top', horizontal: 'left' }}
                        active={row?.pendingVerification} badgeColor='#F97316'>
                        {row?.name}
                    </BlinkingBadge> : <p> {row?.name} </p>
            )
        },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'employeeId', label: 'Employee ID', minWidth: 100, type: 'text' },
        {
            id: 'joiningDate', label: 'Experience', minWidth: 80, type: 'text', onClick: handleRow,
            render: (row) => calculateExperience(row?.joiningDate)
        },
        { id: 'designation', label: 'Designation', minWidth: 180, type: 'text' },
        { id: 'departments', label: 'Department', minWidth: 180, type: 'array' },
        {
            id: 'demote', label: 'Transfer to Recruit', type: 'iconButton',
            align: 'center', onClick: handleDemote,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <DemoteIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'offboarding', label: 'Transfer to Former', type: 'iconButton',
            align: 'center', onClick: handleOffBoarding,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    const transferFields = [
        {
            type: 'date',
            name: 'leavingDate',
            label: 'Leaving Date',
        }
    ]

    const demoteFields = [
        {
            type: 'input',
            name: 'statusMessage',
            label: 'Remark',
            rows: 4,
            multiline: true,
            placeholder: 'Add a remark'
        }
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 rounded-lg drop-shadow-md min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={ProfessorIcon} data={statsData} statsLoading={statsLoading} />
            <CustomTable
                heading="All Employees"
                text="Employees data"
                isLoading={facultyDataLoading}
                columns={columns} rows={facultyData}
                sortParameters={['name', 'designation', 'departments', 'joiningDate', 'employeeId']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
            />
        </div>
    )
}

export default CurrentFaculty;