import React, { useState } from 'react';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer, Legend } from 'recharts';

const colorScheme = ['#512C9D', '#7954B7', '#9A7CD5', '#B6A6EA', '#D7CFFA'];

const selectColors = (numSections) => {
  const step = Math.max(1, Math.floor((colorScheme.length - 1) / (numSections - 1)));
  return Array.from({ length: numSections }, (_, i) => colorScheme[Math.min(i * step, colorScheme.length - 1)]);
};

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, valuePrefix = '', valueSuffix = '' } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text x={cx} y={cy - 5} textAnchor="middle" fill="#512C9D">
        {payload.label}
      </text>
      <text x={cx} y={cy + 15} textAnchor="middle" fill="#333">
        {`${valuePrefix}`}
        <tspan>{value}</tspan>
        <tspan fontSize={10} dy={-1}> ({(percent * 100).toFixed(2)}%)</tspan>
        {`${valueSuffix}`}
      </text>
    </g>
  );
};

const CustomPieChart = ({
  data,
  width = "100%",
  height = "100%",
  innerRadius = 60,
  outerRadius = 80,
  valuePrefix = "",
  valueSuffix = "",
  dataKey = "value",
  nameKey = "label",
  halfPie = false,
  isLoading = false
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  if (isLoading) {
    return (
      <div className="w-full h-full min-h-[260px] flex flex-col items-center justify-center gap-8 pt-4">
        <div className="size-[180px] rounded-full animate-pulse bg-muiLight"></div>
        <div className="w-full flex justify-center gap-2">
          {[1, 2, 3].map((i) => (
            <div key={i} className="w-16 h-4 rounded animate-pulse bg-muiLight"></div>
          ))}
        </div>
      </div>
    );
  }

  if (!data || data.length === 0) return <div>No data provided</div>;

  const colors = selectColors(data.length);
  const onPieEnter = (_, index) => setActiveIndex(index);

  return (
    <div className="w-full h-full min-h-[260px]">
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={(props) => renderActiveShape({ ...props, valuePrefix, valueSuffix, halfPie })}
            data={data}
            cx="50%"
            cy={halfPie ? "100%" : "50%"}
            startAngle={halfPie ? 180 : 0}
            endAngle={halfPie ? 0 : 360}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey={dataKey}
            nameKey={nameKey}
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend iconType='circle' />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomPieChart;