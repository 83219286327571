import { Dialog } from "@mui/material";
import useGetData from "../../../../hooks/useGetData";
import useFormOperations from "../../../../hooks/useFormOperations";
import { CloseButton, ConfirmButton } from "../../../mui/button/Button";
import { CustomAutoComplete, CustomTextField } from "../../../mui/Input";

const PromoteApplicantDialog = ({ id, onClose, onConfirm }) => {
    const { data: applicantData, isLoading } = useGetData(`/api/employee/admission/applicants/getApplicantPromotionDetails/${id}`);

    const { formData, handleChange, handleSubmit } = useFormOperations(applicantData, onConfirm, "", applicantData);
    const emailFormats = formData?.emailFormats?.map(email => ({ value: email, label: email })) || [];

    return (
        <Dialog open={true} onClose={onClose}>
            <form onSubmit={handleSubmit} className="flex flex-col p-5 gap-5 w-full max-w-[32rem] bg-white rounded-lg h-full no-scrollbar">
            
                <div className="flex gap-5">
                    <img className="w-12 h-12" alt="ICON" loading="eager" src='/icons/tabs/Edit.svg' />
                    <div className="flex flex-col" >
                        <h2 className="h4-p">Promote Applicant</h2>
                        <p className="text-s">Are you sure you want to promote this Applicant to enrolled Student?</p>
                    </div>
                </div>

                <div className="flex flex-col gap-4 w-full max-h-[500px] overflow-y-auto py-2 no-scrollbar">
                    <CustomTextField label='Name' name='name' value={formData?.name} onChange={handleChange} skeletonLoading={isLoading} disabled required={false} skeletonClassName='min-h-14' />
                    <CustomTextField label='Email ID' name='emailId' value={formData?.emailId} onChange={handleChange} skeletonLoading={isLoading} disabled required={false} skeletonClassName='min-h-14' />
                    { (applicantData?.password || isLoading) && <CustomTextField label='Default Password' name='password' value={formData?.password} onChange={handleChange} skeletonLoading={isLoading} skeletonClassName='min-h-14' />}
                    <CustomTextField label='Student ID' name='studentId' value={formData?.studentId} onChange={handleChange} skeletonLoading={isLoading} skeletonClassName='min-h-14' />

                    {(applicantData?.officialEmailId || isLoading) && <CustomAutoComplete
                        label='Official Email ID'
                        placeholder="Select an email ID format"
                        name='officialEmailId'
                        value={formData?.officialEmailId || []}
                        options={emailFormats}
                        onChange={handleChange}
                        allowCustom={true}
                        skeletonLoading={isLoading}
                        skeletonClassName='min-h-14'
                        required={true}
                        type='email'
                        multiple={false}
                    />}

                    <CustomTextField multiline rows={4} label='Remarks' name='remark' value={formData?.remark} onChange={handleChange} required={false} skeletonLoading={isLoading} skeletonClassName='min-h-[126px]' />
                </div>

                <div className="flex sm:flex-row justify-between gap-4">
                    <CloseButton onClick={onClose} text='Cancel' fullWidth />
                    <ConfirmButton text="Confirm" type='submit' fullWidth disabled={isLoading} />
                </div>
            </form>
        </Dialog>
    )
}

export default PromoteApplicantDialog;