import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const FinancialInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const financialAndLegalInfoFields = [
        { label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName?.status, data: formData?.financialAndLegalInfo?.bankName?.data, name: 'formData.financialAndLegalInfo.bankName.status' },
        { label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo?.status, data: formData?.financialAndLegalInfo?.accountNo?.data, name: 'formData.financialAndLegalInfo.accountNo.status' },
        { label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc?.status, data: formData?.financialAndLegalInfo?.ifsc?.data, name: 'formData.financialAndLegalInfo.ifsc.status' },
        { label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo?.status, data: formData?.financialAndLegalInfo?.aadharCardNo?.data, name: 'formData.financialAndLegalInfo.aadharCardNo.status' },
        { label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo?.status, data: formData?.financialAndLegalInfo?.panCardNo?.data, name: 'formData.financialAndLegalInfo.panCardNo.status' }
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Financial Details')} value={masterCheckBoxValue(financialAndLegalInfoFields, checkBoxOptions)} name={financialAndLegalInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Financial Details</h4>
            </CustomCheckBox>

            {financialAndLegalInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
        </div>
    )
}

export default FinancialInfo;