import React from 'react';
import Badge from '@mui/material/Badge';
import { keyframes } from '@mui/system';

// Keyframes for blinking animation
const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
`;

const BlinkingBadge = ({ active, badgeColor, sx, position = { vertical: 'top', horizontal: 'right' }, children, ...props }) => {
  return (
    <Badge overlap="circular" variant="dot"
      anchorOrigin={{
        vertical: position.vertical,
        horizontal: position.horizontal,
      }}
      sx={{ '& .MuiBadge-dot': { backgroundColor: badgeColor, animation: `${active ? blinkAnimation : 'none'} 1.5s ease-in-out infinite` }, ...sx }} {...props}>
      {children}
    </Badge>
  );
};

export default BlinkingBadge;
