import { Link } from 'react-router-dom';
import { TypeAnimation } from "react-type-animation";

import useGetClientData from '../../hooks/useGetClientData';

const LoginInfo = () => {
    const { organizationName, logoDefaultURL, officialWebsite } = useGetClientData();

    return (
        <div className="lg:col-span-6 hidden lg:flex flex-col px-10 xl:px-16 py-10 lg:py-20 gap-20 bg-regal-purple-10 md:rounded-l-xl">
            <Link to={officialWebsite} target="_blank" rel="noopener noreferrer" className='hidden tab:block'>
                <img draggable={false} className='h-20 w-fit' src={logoDefaultURL} alt={organizationName} />
            </Link>

            <p className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-700 to-slate-500">Join a vibrant community where innovation meets opportunity! We foster an inclusive culture, empowering students to excel and reach their full potential. Take the first step toward a transformative educational experience with us.</p>

            <div className='flex flex-col gap-10'>
                <TypeAnimation
                    key={organizationName}
                    sequence={[`Welcome to ${organizationName}`, 2000]}
                    wrapper="span"
                    speed={10}
                    style={{ fontSize: '1.5em', display: 'inline-block', fontWeight: 500, color: '#2C0A63' }}
                />

                <div className='flex flex-col gap-2 text-sm text-medium text-slate-600'>
                    <div className="flex gap-2">
                        <Link className="hover:text-regal-purple-700" to='/privacypolicy'>Privacy Policy</Link>
                        |
                        <Link className="hover:text-regal-purple-700" to='/termsandconditions'>Terms and Conditions</Link>
                    </div>
                    <span className='text-slate-400'>© {new Date().getFullYear()} Byteswrite. All Rights Reserved</span>
                </div>
            </div>
        </div>
    )
}

export default LoginInfo