import React from 'react'
import Lottie from 'lottie-react';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useGetData from '../../../../hooks/useGetData';

import SkeletonText from '../../../../components/mui/text/SkeltonText';
import { dateFormat } from '../../../../utils/dateFormat';
import Success from '../../../../assets/icons/Success.svg';
import Pending from '../../../../assets/icons/Pending.svg';
import Rejected from '../../../../assets/icons/Rejected.svg';
import NoData from '../../../../assets/lotties/NoData.json';

const ChangesInfo = ({ handlePreviewClick }) => {
    const { data: changesData, isLoading } = useGetData('/api/employee/personal/getCurrentChangesDetails')

    const iconMap = {
        Verified: Success,
        Pending: Pending,
        Rejected: Rejected,
    }

    return (
        <div className='flex flex-col py-4 px-5 bg-white drop-shadow rounded-lg gap-3 h-full'>
            <div className='flex justify-between items-center'>
                <h5 className='font-semibold font-work-sans'>Latest Changes</h5>
                <IconButton onClick={handlePreviewClick}>
                    <OpenInNewIcon sx={{ fontSize: 20 }} />
                </IconButton>
            </div>

            {isLoading || changesData ? <div className='flex flex-col gap-2'>
                {
                    (changesData ?? Array.from({ length: 3 })).map((change, index) => (
                        <div key={index} className='flex items-center gap-2 cursor-pointer'>
                            <img className='h-12' src={iconMap[change?.status] ?? Pending} alt="status" />
                            <div className='flex flex-col w-full'>
                                <SkeletonText width='80%' loading={isLoading}><p className='lb-p text-[13px] line-clamp-1' onClick={handlePreviewClick}>{change?.heading}</p></SkeletonText>
                                <SkeletonText width='40%' loading={isLoading}><p className='text-s'>{dateFormat(change?.dateOfChange)}</p></SkeletonText>
                            </div>
                        </div>
                    ))}
            </div>
                :
                <div className='flex flex-col justify-center items-center h-full'>
                    <Lottie className='h-28' animationData={NoData} loop={true} />
                    <div className='text-s'>You have not updated anything yet.</div>
                </div>}
        </div>
    )
}

export default ChangesInfo;