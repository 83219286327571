export const camelCaseToNormal = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1').trim();

    return result?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())?.join(' ');
}

export const convertArrayCamelCaseToFirstWords = (camelCaseArray) => {
    return camelCaseArray?.map(item => {
        const normalForm = camelCaseToNormal(item);
        return normalForm?.split(' ')[0];
    });
}