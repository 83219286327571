import React from 'react'
import { Campaign } from '@mui/icons-material';
import { LightButton, PurpleButton } from '../../../components/mui/button/Button';
import usePopup from '../../../hooks/usePopup';
import AnnounceIcon from '../../../assets/icons/AnnounceCircle.svg'

const Announcement = ({ className }) => {
    const { openPopup } = usePopup();

    const onAnnouncement = (data) => {
        console.log(data);
    }

    const studentReceiverOptions = [
        { label: 'Unverified Applicants', value: 'unverifiedApplicants' },
        { label: 'In-progress Applicants', value: 'inProgressApplicants' },
        { label: 'Blocked Applicants', value: 'blockedApplicants' },
        { label: 'Previous Applicants', value: 'previousApplicants' },
        { label: 'Enrolled Students', value: 'enrolleds' },
        { label: 'Dropped-out Students', value: 'droppedOuts' },
        { label: 'Alumni', value: 'alumnis' }
    ];

    const employeeReceiverOptions = [
        { label: 'Unverified Recruits', value: 'unverifiedRecruits' },
        { label: 'In-progress Recruits', value: 'inProgressRecruits' },
        { label: 'Blocked Recruits', value: 'blockedRecruits' },
        { label: 'Previous Recruits', value: 'previousRecruits' },
        { label: 'Current Employees', value: 'currents' },
        { label: 'Former Employees', value: 'formers' }
    ];

    const openAnnouncementPopup = (options) => {
        const updatedFields = [
            {
                type: 'multiSelect',
                label: 'Select Receivers',
                name: 'receiver',
                options: options, color: 'secondary'
            },
            {
                type: 'input',
                label: 'Announcement Message',
                name: 'message',
                placeholder: 'Write your announcement here...',
                multiline: true, rows: 5
            }
        ];

        openPopup('Create New Announcement', 'Choose the receivers and compose the message for your announcement',
            AnnounceIcon, onAnnouncement, undefined, 'input', updatedFields);
    };

    return (
        <div className={`flex flex-col gap-8 ${className}`}>
            <div className='flex items-center gap-2'>
                <Campaign sx={{ color: '#475569' }} />
                <span className='body-semibold text-neutral'>Announcements</span>
            </div>

            <div className='flex flex-col gap-2'>
                <LightButton
                    fullWidth
                    text='Announce to Students'
                    onClick={() => openAnnouncementPopup(studentReceiverOptions)}
                />
                <PurpleButton
                    fullWidth
                    text='Announce to Employees'
                    onClick={() => openAnnouncementPopup(employeeReceiverOptions)}
                />
            </div>
        </div>
    );
}

export default Announcement;