import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSnackbar from './useSnackbar';

const useFormOperations = (defaultValues = {}, onSubmitCallback, path, asyncValues) => {
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbar();

  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    if (asyncValues) setFormData({ ...asyncValues });
  }, [asyncValues])

  const setValue = (fieldName, specificValue) =>
    setFormData((prevData) => ({ ...prevData, [fieldName]: specificValue }));

  const updateData = updateFn => setFormData(prevState => updateFn(prevState));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNestedSelectImmediateChange = (e) => {
    const { value, name } = e.target;
    const keys = name.split('.').slice(1);

    let nestedStructure = formData;
    for (let i = 0; i < keys?.length - 1; i++) {
      const key = keys[i];
      if (!nestedStructure[key]) {
        nestedStructure[key] = isNaN(keys[i + 1]) ? {} : [];
      }
      nestedStructure = nestedStructure[key];
    }
    nestedStructure[keys[keys.length - 1]] = value;
  };

  const handleNestedChange = e => {
    const { value, name } = e.target;
    const keys = name.split('.').slice(1);
    setFormData(prevData => {
      const updatedData = { ...prevData };
      let nestedStructure = updatedData;
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!nestedStructure[key]) nestedStructure[key] = isNaN(keys[i + 1]) ? {} : [];
        nestedStructure = nestedStructure[key];
      }
      nestedStructure[keys[keys.length - 1]] = value;
      return updatedData;
    });
  };

  const handleCheckboxChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    handleNestedChange({ target: { name: name, value: value } });
  }

  const handleMasterCheckBox = (event, label) => {
    const newValue = event.target.value;
    const fields = event.target.name;
    fields.forEach((field) => {
      (field.data && field.data !== '') && handleNestedChange({ target: { name: field.name, value: newValue } });
    });

    const message = newValue
      ? `All fields under ${label} have been marked as Verified.`
      : `All fields under ${label} have been marked as Rejected.`;

    const variant = newValue ? 'success' : 'error';
    handleSnackbarOpen(message, variant);
  };

  const masterCheckBoxValue = (fields, options) => {
    const relevantFields = fields.filter(field => field.data);
    const trueCount = relevantFields.filter(field => options?.[field.value] === true).length;
    const falseCount = relevantFields.filter(field => options?.[field.value] === false).length;
    const totalFields = relevantFields.length;

    if (trueCount === totalFields && totalFields !== 0) return options?.true;
    else if (falseCount === totalFields && totalFields !== 0) return options?.false;
    else return options?.undefined;
  };

  const toPascalCase = (str) => {
    return str.replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  };

  const handlePascalCase = (e) => {
    const { value, name } = e.target;
    const pascalCaseValue = toPascalCase(value);
    setValue(name, pascalCaseValue);
  };

  const resetValue = (fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: defaultValues[fieldName],
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitCallback(formData, event);
  };

  const clearForm = () => setFormData({ ...defaultValues });
  const onDiscard = () => navigate(path);

  return {
    formData,

    handleChange,
    handleNestedChange,
    handleNestedSelectImmediateChange,

    masterCheckBoxValue,
    handleCheckboxChange,
    handleMasterCheckBox,

    handlePascalCase,

    handleSubmit,
    setValue,
    updateData,
    resetValue,
    clearForm,
    onDiscard,
  };
};

export default useFormOperations;
