import React from 'react'
import StatusTile from '../../../components/tiles/StatusTile'
import { InsertDriveFile, Info, Work } from '@mui/icons-material';

const Stats = ({ className }) => {

    const statsData = [
        { heading: 'New Student Applications', count: 5, latest: 4, btn: 'View Applications', link: '/admin/student/applicants', icon: InsertDriveFile, content: [{ label: 'pending Online', value: 2 }, { label: 'pending Offline', value: 3 }] },
        { heading: 'New Employee Applications', count: 10, latest: 2, btn: 'View Applications', link: '/admin/employee/recruits', icon: Work, content: [{ label: 'pending Online', value: 4 }, { label: 'pending Offline', value: 6 }] },
        { heading: 'Resubmitted Applications', count: 7, latest: 1, btn: 'Review Submissions', link: '/admin/student/applicants', icon: Info, content: [{ label: 'students', value: 3 }, { label: 'employees', value: 4 }] },
    ]

    return (
        <div className={`grid grid-cols-6 gap-3 ${className}`}>
            {statsData?.map((data, index) => <StatusTile key={index} className='col-span-full md:col-span-3 p-5 border bg-white drop-shadow rounded-md' heading={data?.heading} icon={data?.icon} count={data?.count} latest={data?.latest} content={data?.content} btn={data?.btn} link={data?.link} />)}
        </div>
    )
}

export default Stats;