import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const IntermediateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const intermediateFields = [
        { label: "Board Name", value: formData?.exams?.intermediateDetail?.boardName?.status, data: formData?.exams?.intermediateDetail?.boardName?.data, name: 'formData.exams.intermediateDetail.boardName.status' },
        { label: "Roll No", value: formData?.exams?.intermediateDetail?.rollNo?.status, data: formData?.exams?.intermediateDetail?.rollNo?.data, name: 'formData.exams.intermediateDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.exams?.intermediateDetail?.examYear?.status, data: formData?.exams?.intermediateDetail?.examYear?.data, name: 'formData.exams.intermediateDetail.examYear.status' },
        { label: "School Name", value: formData?.exams?.intermediateDetail?.schoolName?.status, data: formData?.exams?.intermediateDetail?.schoolName?.data, name: 'formData.exams.intermediateDetail.schoolName.status' },
        { label: "School City", value: formData?.exams?.intermediateDetail?.city?.status, data: formData?.exams?.intermediateDetail?.city?.data, name: 'formData.exams.intermediateDetail.city.status' },
        { label: "School State", value: formData?.exams?.intermediateDetail?.state?.status, data: formData?.exams?.intermediateDetail?.state?.data, name: 'formData.exams.intermediateDetail.state.status' },
        { label: "School Country", value: formData?.exams?.intermediateDetail?.country?.status, data: formData?.exams?.intermediateDetail?.country?.data, name: 'formData.exams.intermediateDetail.country.status' },
        { label: "Obtained Marks", value: formData?.exams?.intermediateDetail?.obtainedMarks?.status, data: formData?.exams?.intermediateDetail?.obtainedMarks?.data, name: 'formData.exams.intermediateDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.exams?.intermediateDetail?.totalMarks?.status, data: formData?.exams?.intermediateDetail?.totalMarks?.data, name: 'formData.exams.intermediateDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.exams?.intermediateDetail?.percentage?.status, data: formData?.exams?.intermediateDetail?.percentage?.data, name: 'formData.exams.intermediateDetail.percentage.status' },
        { label: "Grade", value: formData?.exams?.intermediateDetail?.grade?.status, data: formData?.exams?.intermediateDetail?.grade?.data, name: 'formData.exams.intermediateDetail.grade.status' },
        { label: "CGPA", value: formData?.exams?.intermediateDetail?.cgpa?.status, data: formData?.exams?.intermediateDetail?.cgpa?.data, name: 'formData.exams.intermediateDetail.cgpa.status' },
    ];

    const subjectFields = [
        { type: 'input', size: 1, name: 'subjectName', label: 'Subject Name' },
        { type: 'input', size: 1, name: 'obtainedMarks', label: 'Obtained Marks' },
        { type: 'input', size: 1, name: 'totalMarks', label: 'Total Marks' }
    ]

    return (
        <React.Fragment>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Intermediate Details')} value={masterCheckBoxValue(intermediateFields, checkBoxOptions)} name={intermediateFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Intermediate Details</h4>
                </CustomCheckBox>

                {intermediateFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                    <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                        <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                    </CustomCheckBox>
                )}
            </div>

            {formData?.exams?.intermediateDetail?.subjects?.map((subject, index) => {
                const fields = subjectFields?.map(field => ({
                    value: formData?.exams?.intermediateDetail?.subjects?.[index]?.[field.name]?.status,
                    name: `formData.exams.intermediateDetail.subjects.${[index]}.${field.name}.status`,
                    label: field.label, data: subject[field?.name]?.data, loading: isLoading
                }));

                const masterCheckboxValue = { value: masterCheckBoxValue(fields, checkBoxOptions), name: fields };

                return (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Elementary Detail ${index + 1}`)} value={masterCheckboxValue.value} name={masterCheckboxValue.name} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey">Intermediate - {subject?.subjectName?.data} Details</h4>
                        </CustomCheckBox>

                        {fields?.map((field, fieldIndex) => (isLoading || field?.data !== undefined) &&
                            <div key={`${index}-${field.name}`}>
                                <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field.label} value={field.data} loading={field.loading} />
                                </CustomCheckBox>
                            </div>
                        )}
                    </div>
                );
            })}
        </React.Fragment>
    )
}

export default IntermediateInfo;