import React from "react";
import { useLocation, useParams } from "react-router-dom";
import useFetchData from "../../../hooks/useFetchData";
import useSnackbar from "../../../hooks/useSnackbar";
import DocumentBox from "../../../components/tiles/documents/DocumentBox";

const DocumentDetails = ({ documentData, loading }) => {
    const { id } = useParams();
    const location = useLocation();
    const { fetchData, error } = useFetchData();
    const { handleSnackbarOpen } = useSnackbar();

    const routeMap = {
        former: `/api/employee/hr/formers/getViewURL/${id}`,
        current: `/api/employee/hr/currents/getViewURL/${id}`
    };

    const type = location.pathname.includes('former') ? 'former' : 'current';

    const handleClick = async (key) => {
        const apiUrl = id ? `${routeMap[type]}?key=${key}` : `/api/employee/personal/getViewURL?key=${key}`;
        const signedURL = await fetchData(apiUrl);
        if (error) return handleSnackbarOpen('Oops... Some Error occurred', 'warning');
        window.open(signedURL, '_blank');
    };

    const documentCategories = [
        { title: 'Common Documents', key: 'commonDocuments', length: 8 },
        { title: 'Academic Documents', key: 'academicDocuments' },
        { title: 'Category Documents', key: 'categoryDocuments' },
        { title: 'Program Documents', key: 'programDocuments' },
        { title: 'Quota Documents', key: 'quotaDocuments' },
        { title: 'Round Documents', key: 'roundDocuments' },
    ];

    return (
        <div className='flex flex-col w-full gap-8'>
            {documentCategories.map(({ title, key, length = 4 }, index) => {
                const documents = documentData?.[key];

                return (
                    (documents?.length > 0 || loading) &&
                    <React.Fragment>
                        {index !== 0 && <hr className="hidden lg:block w-full bg-slate-400 border-[1px]" />}
                        <div className='flex flex-col w-full gap-5'>
                            <div className='flex items-center gap-4'>
                                <p className='lb-s whitespace-nowrap'>{title}</p>
                                <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
                            </div>

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5 ml-1'>
                                {(loading ? Array.from({ length }) : documents)?.map((document, index) => (
                                    <DocumentBox
                                        key={index}
                                        label={document?.title}
                                        value={document?.description}
                                        status={document?.onlineStatus}
                                        handleClick={() => handleClick(document?.key)}
                                        loading={loading}
                                    />
                                ))}
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default DocumentDetails;