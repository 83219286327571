import { Dialog } from "@mui/material";
import renderFormField from '../../../utils/renderFormField';
import { CloseButton, ConfirmButton } from "../../mui/button/Button";
import useFormOperations from "../../../hooks/useFormOperations";

const InputPopup = ({ heading, icon, subText, inputFields, onConfirm, onClose }) => {

    const { formData, handleChange, handleSubmit } = useFormOperations({}, onConfirm);

    return (
        <Dialog open={true} onClose={onClose}>
            <div className="flex flex-col p-6 gap-8 w-full max-w-[32rem] bg-white rounded-lg">
                <div className="flex gap-3">
                    <img className="col-span-2 w-12 h-12" alt="ICON" loading="eager" src={icon} />
                    <div className="flex flex-col">
                        <h2 className="h4-p">{heading}</h2>
                        <p className="text-s">{subText}</p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col gap-5 w-full">
                    {inputFields.map((field, index) => renderFormField({ ...field, value: formData?.[field?.name], onChange: handleChange }, index))}
                    <div className="flex flex-col sm:flex-row items-center justify-between gap-5">
                        <CloseButton fullWidth size="large" text="Cancel" onClick={onClose} />
                        <ConfirmButton fullWidth size="large" text="Confirm" type='submit' />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default InputPopup;