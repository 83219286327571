import React from "react";
import Lottie from "lottie-react";
import { Dialog } from "@mui/material";

import useGetData from "../../../../hooks/useGetData";

import SkeletonText from '../../../mui/text/SkeltonText';
import Success from '../../../../assets/icons/Success.svg';
import Pending from '../../../../assets/icons/Pending.svg';
import Rejected from '../../../../assets/icons/Rejected.svg';
import NoData from '../../../../assets/lotties/NoData.json';
import { CrossButton } from "../../../mui/button/Button";
import { dateFormat } from '../../../../utils/dateFormat';

const PreviewChangesPopup = ({ onClose }) => {
  const { data: changesData, isLoading } = useGetData('/api/employee/personal/getCurrentChanges')

  const iconMap = {
    Verified: Success,
    Pending: Pending,
    Rejected: Rejected,
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='sm'>
      <div className="flex flex-col justify-between bg-white p-6 rounded-lg gap-5">

        <div className="flex justify-between">
          <h4 className="h4-p">Previous Changes</h4>
          <CrossButton onClose={onClose} />
        </div>

        {isLoading || changesData ? <div className='flex flex-col gap-4 xs:gap-2'>
          {
            (changesData ?? Array.from({ length: 3 })).map((change, index) => (
              <div key={index} className='flex flex-col xs:flex-row xs:items-center justify-between gap-2 xs:gap-10'>
                <div className="flex items-center gap-2">
                  <div className="h-16 w-16">
                    <img className='h-16' src={iconMap[change?.status] ?? Pending} alt="status" />
                  </div>
                  <div className='flex flex-col w-full'>
                    <SkeletonText loading={isLoading} width={200}><p className='font-semibold line-clamp-1'>{change?.heading}</p></SkeletonText>
                    <SkeletonText loading={isLoading} width={300}><p className='lb-s'><span className="text-purple">{change?.approvingAuthority}</span> | {dateFormat(change?.dateOfChange)}</p></SkeletonText>
                  </div>
                </div>

                <SkeletonText sx={{ borderRadius: 'full' }} loading={isLoading} height='40px' width={100}><div className="text-center text-sm text-purple font-semibold bg-regal-purple-30 rounded-full px-5 py-2">
                  {change?.fieldsChangedCount} {change?.fieldsChangedCount > 1 ? 'Changes' : 'Change'}
                </div></SkeletonText>
              </div>
            ))}
        </div>
          :
          <div className='flex flex-col justify-center items-center h-full p-5'>
            <Lottie className='h-48' animationData={NoData} loop={true} />
            <div className='lb-s'>You have not updated anything yet.</div>
          </div>}
      </div>
    </Dialog>
  )
}

export default PreviewChangesPopup;