import { useNavigate } from 'react-router-dom'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import usePostData from '../../hooks/usePostData'
import useSnackbar from '../../hooks/useSnackbar'

import google from "../../assets/logo/google.svg"

const LoginGoogle = ({ emailSubmitted }) => {
    const navigate = useNavigate();

    const { handleSnackbarOpen } = useSnackbar();
    const { postData } = usePostData();

    const SignGoogle = async (data) => {
        const { success, message } = await postData('/api/login/loginGoogleSuccess', data)
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some error occurred from our end, pls try again!", 'error');
        navigate('/admin/dashboard');
    };

    const showErrorPopup = () => handleSnackbarOpen("Opps.. Some error occurred!", 'error');

    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: SignGoogle,
        onNonOAuthError: (error) => { if (error.type !== 'popup_closed') showErrorPopup(); },
        onError: showErrorPopup,
        ux_mode: 'popup',
        hint: 'Select your Official Account only'
    });

    useGoogleOneTapLogin({
        onError: (error) => handleSnackbarOpen("Opps.. Some error occurred!", 'error'),
        onSuccess: SignGoogle,
        googleAccountConfigs: {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
        }
    });

    return (
        <button className='flex p-3 gap-3 justify-center items-center bg-regal-purple-10 hover:bg-opacity-70 shadow-sm rounded-md w-full select-none'
            onClick={handleGoogleLogin} >
            <div className='h-fit w-fit flex p-1 bg-white rounded-full'>
                <img draggable={false} src={google} className='h-5 w-5' alt="googleImage" />
            </div>
            <h5 className='text-p font-semibold'>Sign in with Google</h5>
        </button>
    )
}

export default LoginGoogle;