import React from 'react'
import { useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ArrowBack, NotificationAdd } from '@mui/icons-material';

import { loadingState } from '../../../../../recoil/state';
import useGetData from '../../../../../hooks/useGetData';
import usePutData from '../../../../../hooks/usePutData';
import usePostData from '../../../../../hooks/usePostData';
import useSnackbar from '../../../../../hooks/useSnackbar';

import { CloseButton, LightButton, PurpleButton } from '../../../../../components/mui/button/Button';
import VerifyDocuments from '../../../../../components/tiles/documents/VerifyDocuments';
import { SquareIconButton } from '../../../../../components/mui/button/SquareIconButton';

const OfflineVerification = () => {
    const { id } = useParams();
    const { data: documentsData, isLoading: isDocumentsLoading, updateData } = useGetData(`/api/employee/admission/applicants/getDocuments/${id}`);
    const { data: statusData } = useGetData(`/api/employee/admission/applicants/getStatus/${id}`);

    const { putData } = usePutData();
    const { postData } = usePostData();

    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const generateViewUrl = (key) => `/api/employee/admission/applicants/getViewURL/${id}?key=${key}`;
    const disabled = !documentsData || ![...Object?.values(documentsData)]?.flat()?.every(doc => doc?.offlineStatus === "Verified");

    const updateDocumentStatus = async (key, newStatus, allUpdate = false) => {
        const body = { key, type: 'offline', status: newStatus };
        const { message, success } = await putData(`/api/employee/admission/applicants/updateDocumentStatus/${id}`, body);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred while Updating status', 'warning');

        const snackbarMessage = newStatus === 'Verified'
            ? 'Document status updated to verified successfully!'
            : 'Document reverted to pending! Status change was successful.';

        updateData(prevState => {
            const updatedDocuments = { ...prevState };
            Object.keys(updatedDocuments).forEach(category => {
                updatedDocuments[category] = updatedDocuments[category].map(doc =>
                    doc.key === key ? { ...doc, offlineStatus: newStatus } : doc
                );
            });

            return updatedDocuments;
        });

        if (!allUpdate) handleSnackbarOpen(snackbarMessage, 'success');
    }

    const handleAllUpdateStatus = async () => {
        try {
            setLoading(true);
            const newStatus = disabled ? 'Verified' : 'Pending';

            const documentsToUpdate = [];
            Object.values(documentsData).forEach(category => { category.forEach(document => { if (document.offlineStatus !== newStatus) { documentsToUpdate.push(document); } }); });

            const promises = documentsToUpdate.map(document => updateDocumentStatus(document.key, newStatus, true));
            await Promise.all(promises);
            handleSnackbarOpen('All documents updated successfully!', 'success');
        } catch (error) {
            handleSnackbarOpen('Error updating documents. Please try again.', 'error');
        } finally { setLoading(false); }
    };

    const handleVerifyOffline = async () => {
        setLoading(true);
        const { message, success } = await putData(`/api/employee/admission/applicants/verifyOfflineAdmission/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred while Updating status', 'warning');
        const approvalType = statusData?.offlineVerificationStatus !== 'Verified' ? 'approved' : 'made pending';
        handleSnackbarOpen(`Student Application ${approvalType} offline successfully.`, 'success');
        navigate('/admin/student/applicants');
    };

    const handleNotifyApplicant = async () => {
        setLoading(true);
        const { message, success } = await postData(`/api/employee/admission/applicants/notifyOfflineDocuments/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred!', 'error');
        handleSnackbarOpen(`Applicant has been notified for the document verification`, 'success');
    }

    const handleBackward = () => navigate('/admin/student/applicants')
    const renderLoadingTiles = (count = 5) => Array.from({ length: count }, (_, index) => <VerifyDocuments key={`loading-${index}`} loading />);

    return (
        <div className='flex flex-col w-full mx-auto'>
            <div className='flex justify-between px-5 py-7 tab:px-14 tab:py-10'>
                <div className='flex items-center gap-3'>
                    <IconButton size="small" className="w-fit" aria-label="back" onClick={handleBackward}>
                        <ArrowBack fontSize="small" />
                    </IconButton>
                    <h5 className='h4-p xs:h3-p'>Offline Document Verification</h5>
                </div>

                {statusData?.offlineVerificationStatus !== 'Verified' && <SquareIconButton title='Notify Applicant' sx={{ backgroundColor: !disabled ? '#E0E0E0' : '#F0E5FC', '&:hover': { backgroundColor: !disabled ? '#E0E0E0' : '#E7D8F8' } }} icon={<NotificationAdd sx={{ color: "#5B21B6", fontSize: 24 }} />} onClick={handleNotifyApplicant} disabled={!disabled} />}
            </div>

            <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-tertiary'>
                <h5 className='h5-p xs:h4-p'>General Proofs</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles(8)
                        : documentsData && <>
                            {documentsData?.commonDocuments?.map((document) =>
                                <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />)}
                            {documentsData?.optionalCommonDocuments?.map((document) =>
                                <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />)}
                        </>
                    }
                </div>
            </div>

            {documentsData?.categoryDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Category Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles()
                        : documentsData && documentsData?.categoryDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.quotaDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-tertiary'>
                <h5 className='h4-p'>Quota Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles(1)
                        : documentsData && documentsData?.quotaDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.academicDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Academic Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles()
                        : documentsData && documentsData?.academicDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.programDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-tertiary'>
                <h5 className='h4-p'>Additional Academic Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles(2)
                        : documentsData && documentsData?.programDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.gapDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Gap Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles()
                        : documentsData && documentsData?.gapDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            {documentsData?.roundDocuments?.length > 0 && <div className='flex flex-col gap-4 px-7 tab:px-14 py-8 border-y-2 bg-secondary'>
                <h5 className='h4-p'>Round Document</h5>
                <div className='flex flex-col gap-5'>
                    {isDocumentsLoading
                        ? renderLoadingTiles(2)
                        : documentsData && documentsData?.roundDocuments?.map((document) =>
                            <VerifyDocuments doc={document} viewURL={generateViewUrl(document?.key)} loading={isDocumentsLoading} updateStatus={updateDocumentStatus} />
                        )}
                </div>
            </div>}

            <div className='flex flex-col-reverse sm:flex-row justify-end gap-3 xs:gap-5 px-7 py-10 tab:p-14 bg-white'>
                {statusData?.offlineVerificationStatus !== 'Verified' && <LightButton size='large' onClick={handleAllUpdateStatus} text={disabled ? 'Mark all Submitted' : 'Revert all to Pending'} />}

                {statusData?.offlineVerificationStatus !== 'Verified' ?
                    <PurpleButton size='large' onClick={handleVerifyOffline} disabled={disabled} text='Approve Offline' />
                    : <CloseButton size='large' variant='contained' onClick={handleVerifyOffline} disabled={disabled} text='Revoke Approval' />
                }
            </div>
        </div>
    )
}

export default OfflineVerification;