import React from 'react'
import StackedBarChart from '../graphs/StackedBarChart'
import NoData from '../../assets/images/noDataFound.webp'

const BarChartWrapper = ({ data, heading, isLoading, className, ...props }) => {
    return (
        <div className={`flex flex-col w-full gap-3 ${className}`}>
            <h1 className='body-semibold text-dark'>{heading}</h1>
            {data || isLoading ? <StackedBarChart data={data} isLoading={isLoading} {...props} /> :
                <div className='flex flex-col items-center text-center gap-1'>
                    <img className='size-40' src={NoData} alt="Data" />
                    <span className='lb-s'>No Data available to display</span>
                </div>
            }
        </div>
    )
}

export default BarChartWrapper;