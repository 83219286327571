import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom"
import { KeyboardBackspace } from '@mui/icons-material';

import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import TransferIcon from '../../../../assets/icons/TransferIcon'
import CustomTable from "../../../../components/mui/Table";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";

const DropoutStudents = () => {
    const { data: dropoutData, isLoading: dropoutDataLoading, updateData } = useGetData('api/employee/admission/enrolleds/getDropouts');
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [userId, setUserId] = useState('');

    const customOperations = () => {
        return <SquareIconButton title='Enrolled' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }}
            icon={<KeyboardBackspace sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={() => navigate('/admin/student/enrolleds')} />
    }

    const handleRow = (_event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/applicants/blocked/profile/" + value + '?type=Personal'));

    const handleDropout = (_event, value) => {
        setUserId(value);
        openPopup('Transfer to Enrolled Student', 'Are you sure you want to transfer this dropout student to Enrolled Student?',
            'edit', onDropout);
    }

    const onDropout = async () => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateDropout/${userId}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => { return prevState.filter(user => user._id !== userId) });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'studentId', label: 'Student ID', minWidth: 200, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 140, type: 'text' },
        { id: 'dropoutTimeStamp', label: 'Dropped Out Date', type: 'date' },
        {
            id: 'dropout', label: 'Transfer to Enrolled Student', type: 'iconButton',
            align: 'center', onClick: handleDropout,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-8 w-8 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <CustomTable
                heading="All Dropout Students"
                text="Dropout Students data"
                isLoading={dropoutDataLoading}
                columns={columns} rows={dropoutData}
                sortParameters={['name', 'programKey', 'admissionYear', 'deletedTimeStamp']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
                extraOperations={customOperations}
            />
        </div>
    )
}

export default DropoutStudents;