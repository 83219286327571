import React, { useEffect, useState } from 'react';
import { TextField, Select, Autocomplete, MenuItem, FormControl, InputLabel, FormHelperText, InputAdornment, Button, Checkbox, ListItemText, OutlinedInput, Chip, Box, CircularProgress } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Check, Clear } from '@mui/icons-material';
import dayjs from 'dayjs';

const CustomTextField = ({ className = '', skeletonClassName, customType, value, onChange = () => { }, required = true, multiline = false, style, startIcon, endIcon, color = "primary", maxLength, skeletonLoading, ...props }) => {
    const handleArrowKey = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown')
            event.preventDefault();
    };

    const handleInputChange = (event) => {
        if (customType === 'dashedNumber') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9-]/g, '');
            event.target.value = sanitizedValue;
        }
        if (customType === 'number') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
            event.target.value = sanitizedValue;
        }

        if (customType === 'decimal') {
            const inputValue = event.target.value;
            let sanitizedValue = inputValue.replace(/[^0-9.]/g, '');

            if ((sanitizedValue.match(/\./g) || []).length > 1)
                sanitizedValue = sanitizedValue.substring(0, sanitizedValue.indexOf('.') + 1) + sanitizedValue.substring(sanitizedValue.indexOf('.') + 1).replace(/\./g, '');

            event.target.value = sanitizedValue;
        }

        if (customType === 'grade') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^a-zA-Z+-]/g, '');
            event.target.value = sanitizedValue;
        }

        if (customType === 'letters') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^a-zA-Z]/g, '');
            event.target.value = sanitizedValue;
        }

        onChange(event);
    };

    return (
        !skeletonLoading ? <TextField
            className={className}
            style={{ style }}
            variant="outlined"
            color={color}
            required={required}
            multiline={multiline}
            value={value}
            onChange={handleInputChange}
            {...props}
            onKeyDown={handleArrowKey}
            onKeyUp={handleArrowKey}
            InputProps={{
                ...props?.InputProps,
                maxLength: 10,
                startAdornment: startIcon ? (
                    <InputAdornment position="start">
                        {startIcon}
                    </InputAdornment>
                ) : null,
                endAdornment: endIcon ? (
                    <InputAdornment position="end">
                        {endIcon}
                    </InputAdornment>
                ) : null,
            }}
        /> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

const CustomSelect = ({ className, skeletonClassName, options, onChange, required = true, helperText, disabled, color, skeletonLoading, ...props }) => (
    !skeletonLoading ? <FormControl className={className} variant="outlined" required={required} disabled={disabled} color={color}>
        <InputLabel color={color}>{props.label}</InputLabel>
        <Select color={color} onChange={onChange} required={required} MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} {...props}>
            {options?.map((option) => (
                <MenuItem key={option?.value} value={option?.value} color={color}>
                    {option?.label}
                </MenuItem>
            ))}
        </Select>
        {helperText && <FormHelperText className='text-xs text-s'>{helperText}</FormHelperText>}
    </FormControl> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
);

const CustomMultiCheckboxSelect = ({ className, skeletonClassName, options = [], name, value = [], onChange, handleAddDelete, required = true, helperText, disabled, color = 'secondary', noOptionsMessage, loading, skeletonLoading, ...props }) => {

    const [data, setData] = useState(Array.isArray(value) ? value : []);
    const [updatedOptions, setUpdatedOptions] = useState(options);
    useEffect(() => {
        setData(Array.isArray(value) ? value : []);
        if (options !== updatedOptions) setUpdatedOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options]);

    const selectColor = color === 'secondary' ? '#4F38C9' : color;
    const sortByOptionOrder = (arr, options) => {
        const sortMap = options.reduce((acc, option, index) => {
            acc[option.value] = index;
            return acc;
        }, {});

        return arr.sort((a, b) => sortMap[a] - sortMap[b]);
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        const updatedValue = sortByOptionOrder(newValue, updatedOptions);

        const newlyAddedElement = updatedValue.find(item => !data.includes(item));
        const newlyDeletedElement = data.find(item => !updatedValue.includes(item));

        if (handleAddDelete) {
            onChange(event);
            handleAddDelete(newlyAddedElement, newlyDeletedElement);
        } else onChange(event);
    };

    const NoArrowIcon = () => null;

    return (
        !skeletonLoading ? <FormControl className={className} variant="outlined" required={required} disabled={disabled} fullWidth>
            <InputLabel sx={{ '&.Mui-focused': { color: selectColor } }}>{props.label}</InputLabel>
            <Select
                multiple
                value={data}
                required={required}
                sx={{
                    '&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: selectColor,
                        },
                    }
                }}
                name={name}
                onChange={handleChange}
                input={<OutlinedInput
                    endAdornment={
                        loading ? (
                            <InputAdornment position="end">
                                <CircularProgress size={24} sx={{ color: '#757575' }} />
                            </InputAdornment>
                        ) : null
                    }
                    label={props.label}
                    inputProps={props?.InputProps}
                />}
                IconComponent={loading ? NoArrowIcon : undefined}
                renderValue={(selected) => {
                    const labelArray = updatedOptions.filter(option => selected.includes(option?.value))?.map(option => option?.label);

                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.8, overflowX: 'auto', maxWidth: '100%', '-ms-overflow-style': 'none', 'scrollbar-width': 'none', }}>
                            {labelArray.map((value) => (
                                <Chip key={value} label={value} size='small' sx={{ color: 'white', backgroundColor: selectColor }} />
                            ))}
                        </Box>)
                }}
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                {...props}
            >
                {loading ?
                    <MenuItem disabled>
                        <em>Please wait till the loading</em>
                    </MenuItem> :
                    updatedOptions?.length === 0 ?
                        <MenuItem disabled>
                            <em>{noOptionsMessage}</em>
                        </MenuItem>
                        : updatedOptions?.map((option) => (
                            <MenuItem key={option?.value} value={option?.value} color={selectColor}>
                                <Checkbox checked={data?.indexOf(option?.value) > -1} sx={{ color: selectColor, '&.Mui-checked': { color: selectColor } }} />
                                <ListItemText primary={option?.label} />
                            </MenuItem>
                        ))}
            </Select>
            {helperText && <FormHelperText className='text-xs text-s'>{helperText}</FormHelperText>}
        </FormControl> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

const CustomAsyncSelect = ({ className, skeletonClassName, value = '', options, onChange, required = true, helperText, noOptionsMessage = '', disabled, skeletonLoading, loading, ...props }) => {
    const [data, setData] = useState(value);
    const [updatedOptions, setUpdatedOptions] = useState(options);

    useEffect(() => {
        setData(value);
        setUpdatedOptions(options);
    }, [value, options]);

    const NoArrowIcon = () => null;

    return (
        !skeletonLoading ?
            <FormControl className={`rounded-[4px] ${className}`} variant="outlined" required={required} disabled={disabled}>
                <InputLabel color="primary">{props.label}</InputLabel>
                <Select
                    color="primary"
                    onChange={(e) => { onChange(e); setData(e.target.value); }}
                    name={props.name}
                    required={required}
                    value={data}
                    input={<OutlinedInput
                        endAdornment={
                            loading ? (
                                <InputAdornment position="end">
                                    <CircularProgress size={24} sx={{ color: '#757575' }} />
                                </InputAdornment>
                            ) : null
                        }
                        label={props.label}
                        inputProps={props?.InputProps}
                    />}
                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                    IconComponent={loading ? NoArrowIcon : undefined}
                    {...props}
                >
                    {loading ? (
                        <MenuItem disabled>
                            <em>Please wait till the loading</em>
                        </MenuItem>
                    ) : updatedOptions?.length === 0 ? (
                        <MenuItem disabled>
                            <em>{noOptionsMessage}</em>
                        </MenuItem>
                    ) : (
                        updatedOptions?.map((option) => (
                            <MenuItem key={option?.value} value={option?.value}>
                                {option?.label}
                            </MenuItem>
                        ))
                    )}
                </Select>
                {helperText && <FormHelperText className='text-xs text-s'>{helperText}</FormHelperText>}
            </FormControl> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

const CustomYearMonthPicker = ({ onChange, yearValue, monthValue, className, name, required, skeletonClassName, skeletonLoading, ...props }) => {
    return (
        !skeletonLoading ? <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                localeText={{
                    fieldMonthPlaceholder: () => 'MM',
                }}
                className={className}
                views={['year', 'month']}
                variant="outlined"
                defaultValue={yearValue && dayjs().year(yearValue).month(monthValue - 1)}
                onChange={(date) => {
                    const selectedDate = dayjs(date);
                    const year = selectedDate.year();
                    const month = selectedDate.month() + 1;
                    onChange({ target: { name, value: { year: year, month: month } } })
                }}

                {...props}
                slotProps={{ textField: { required: required } }}
            />
        </LocalizationProvider> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

const CustomAcademicYearPicker = ({ className = 'w-36', currentYear, handleYearChange, years, year, skeletonClassName, skeletonLoading }) => {
    return (
        !skeletonLoading ?
            <FormControl className={className} size="small" variant="outlined">
                <InputLabel id="year-label" size="small" shrink={true}>Academic Year </InputLabel>
                <Select labelId="year-label" label="Academic Year" value={year} name="year" size="small" defaultValue={currentYear} onChange={handleYearChange}
                    MenuProps={{ PaperProps: { style: { maxHeight: "150px" } } }}
                    InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value='total'>Total</MenuItem>
                    {years.map((year) => {
                        const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                        return (
                            <MenuItem key={formattedYear} value={year} size="small">
                                {formattedYear}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    )
};

const CustomYearPicker = ({ onChange, name, value = null, className, required = true, skeletonClassName, skeletonLoading, ...props }) => {
    return (
        !skeletonLoading ? <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                name={name}
                className={className}
                variant="outlined"
                value={value && dayjs(value)}
                onChange={(newValue) => onChange({ target: { name, value: newValue } })}
                disableFuture
                slotProps={{ textField: { required: required } }}
                {...props}
            />
        </LocalizationProvider> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

const CustomCheckBox = ({ className, name, value, options, children, onChange, disabled }) => {
    const [updatedValue, setUpdatedValue] = useState(options?.[value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setUpdatedValue(options?.[value]); }, [value]);

    const handleClick = () => {
        const newValue = !updatedValue;
        setUpdatedValue(newValue);
        onChange({ target: { name, value: options?.[`${newValue}`] } });
    };

    const icon = updatedValue === undefined ?
        <div className='w-5 h-5 mt-1 border-2 rounded-sm border-zinc-400' />
        : updatedValue ? <Check className={`text-white ${disabled ? 'bg-slate-400' : 'bg-green-700'} rounded-sm`} style={{ marginTop: '4px' }} />
            : <Clear className={`text-white ${disabled ? 'bg-slate-400' : 'bg-red-500'} rounded-sm`} style={{ marginTop: '4px' }} />;

    return (
        <Button disabled={disabled} className={`transition-transform duration-100 w-fit ${className}`} sx={{
            justifyContent: 'flex-start', alignItems: 'flex-start',
            textAlign: 'left', textTransform: 'none',
            paddingY: 0,
            '&:hover': { backgroundColor: 'transparent', }
        }} onClick={handleClick} disableRipple startIcon={icon}>{children}</Button>)
};


const CustomAutoComplete = ({ className, placeholder, label, value, name, type, onChange, required = false, helperText, disabled, color, skeletonLoading, skeletonClassName, multiple = true, allowCustom = true, options = [], loading = false, ...props }) => {
    const [selectedValues, setSelectedValues] = useState(multiple ? value || [] : value || "");

    useEffect(() => {
        setSelectedValues(multiple ? value || [] : value || "");
    }, [value, multiple]);

    if (skeletonLoading)
        <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />

    const handleSelectChange = (_, newValue) => {
        let updatedValue;

        if (multiple) {
            updatedValue = newValue.map((item) => {
                if (typeof item === "string") {
                    const matchingOption = options.find(
                        (option) =>
                            option.label.toLowerCase() === item.toLowerCase() ||
                            option.value.toLowerCase() === item.toLowerCase()
                    );
                    return matchingOption ? matchingOption.value : item;
                }
                return item.value;
            });
        } else {
            updatedValue = newValue ? newValue.value || newValue : "";
        }

        setSelectedValues(updatedValue);
        onChange({ target: { name, value: updatedValue } });
    };

    const getOptionLabel = (option) => {
        if (typeof option === "string") return option;
        return option.label || "";
    };

    const isOptionEqualToValue = (option, value) => {
        if (typeof option === "string" && typeof value === "string") {
            return option.toLowerCase() === value.toLowerCase();
        }
        return option.value === value || option.label === value;
    };

    return (
        !skeletonLoading ? <Autocomplete
            className={className}
            multiple={multiple}
            options={options}
            freeSolo={allowCustom}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            value={
                multiple
                    ? selectedValues.map((value) => {
                        const option = options.find(
                            (opt) => opt.value === value || opt.label === value
                        );
                        return option || value;
                    })
                    : options.find(
                        (opt) =>
                            opt.value === selectedValues || opt.label === selectedValues
                    ) || selectedValues
            }
            onChange={handleSelectChange}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    disabled={disabled}
                    color={color}
                    name={name}
                    type={type}
                    required={required}
                    onChange={onChange}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            {...props}
        /> : <div className={`animate-pulse h-14 w-full rounded-md bg-muiLight ${skeletonClassName}`} />
    );
};

export {
    CustomTextField, CustomSelect,
    CustomAsyncSelect, CustomMultiCheckboxSelect,
    CustomYearMonthPicker, CustomYearPicker, CustomAcademicYearPicker,
    CustomCheckBox, CustomAutoComplete
};