import renderFormField from '../../../../../utils/renderFormField';
import { CustomTextField, CustomAsyncSelect } from '../../../../../components/mui/Input'
import { maritalStatus } from "../../../../../constants/personal";
import { Checkbox } from "@mui/material";
import AddComponent from "../../../../../components/mui/AddComponent";

const FamilyInfo = ({ formData, handleNestedChange, isLoading }) => {

    const familyPropertyFields = [
        { type: 'input', label: "Middle Name", name: 'middleName', required: false },
        { type: 'input', label: "Last Name", name: 'lastName' },
        { type: 'input', label: "Phone Number", name: 'phNo', customType: "number" },
        { type: 'input', label: "Email ID", name: 'emailId', inputType: "email", required: false },
        { type: 'input', label: "Income", name: "income", customType: "number" },
        { type: 'input', label: "Education", name: "education" },
        { type: 'input', label: "Profession", name: "profession" },
    ];

    const emergencyPersonFields = [
        { type: 'input', label: "Middle Name", name: 'middleName', required: false },
        { type: 'input', label: "Last Name", name: 'lastName' },
        { type: 'input', label: "Relation", name: 'relation' },
        { type: 'input', label: "Phone Number", name: 'phNo', customType: "number" },
    ];

    const childrenProperties = [
        { type: 'input', label: "First Name", name: "firstName" },
        { type: 'input', label: "Middle Name", name: "middleName", required: false },
        { type: 'input', label: "Last Name", name: "lastName" },
        { type: 'date', label: "DOB", name: "dob" },
        { type: 'input', label: "Education", name: "education" }
    ];

    const handleChildrenCheckbox = () => {
        if (formData?.familyInfo?.childrenDetail?.length > 0) {
            let updatedFamilyData = { ...formData.familyInfo };
            delete updatedFamilyData.children;
            handleNestedChange({ target: { name: 'formData.familyInfo', value: updatedFamilyData } });
        } else {
            handleNestedChange({ target: { name: 'formData.familyInfo.children', value: [{}] } });
        }
    };

    const handleMotherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.motherDetail', value: { isDeceased: !formData?.familyInfo?.motherDetail?.isDeceased } } })
    const handleFatherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.fatherDetail', value: { isDeceased: !formData?.familyInfo?.fatherDetail?.isDeceased } } })

    const handleMaritalStatus = (e) => {
        if (e.target.value !== 'Married') {
            delete formData?.familyInfo?.partnerDetail;
        }; handleNestedChange(e);
    }

    return (
        <div className="flex flex-col gap-8">
            <CustomAsyncSelect
                className='bg-white' label="Marital Status"
                options={maritalStatus?.map(option => ({ value: option, label: option }))} onChange={handleMaritalStatus}
                name='formData.additionalInfo.maritalStatus' value={formData?.additionalInfo?.maritalStatus}
            />
            {formData?.additionalInfo?.maritalStatus === 'Married' &&
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Partner Details</h5>
                    <div className="flex w-full">
                        <CustomAsyncSelect
                            className='bg-white w-24' label="Title"
                            options={[
                                { value: 'Dr', label: 'Dr' },
                                { value: 'Mr', label: 'Mr' },
                                { value: 'Mrs', label: 'Mrs' },
                                { value: 'Ms', label: 'Ms' },
                            ]} onChange={handleNestedChange}
                            name='formData.familyInfo.partnerDetail.title' value={formData?.familyInfo?.partnerDetail?.title}
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            skeletonClassName='border-r-none' skeletonLoading={isLoading}
                        />

                        <CustomTextField
                            label="First Name" name='formData.familyInfo.partnerDetail.firstName' value={formData?.familyInfo?.partnerDetail?.firstName} onChange={handleNestedChange}
                            fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading}
                        />
                    </div>

                    {familyPropertyFields.map((field, index) => (renderFormField({
                        ...field,
                        value: formData?.familyInfo?.partnerDetail?.[field.name],
                        name: `formData.familyInfo.partnerDetail.${field?.name}`,
                        onChange: handleNestedChange
                    }, index, isLoading)))}
                </div>}

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                {!formData?.familyInfo?.fatherDetail?.isDeceased && <><h5 className="h4-p col-span-full">Father Details</h5>
                    <div className="flex w-full">
                        <CustomAsyncSelect
                            className='bg-white w-24' label="Title"
                            options={[
                                { value: 'Dr', label: 'Dr' },
                                { value: 'Mr', label: 'Mr' },
                                { value: 'Mrs', label: 'Mrs' },
                                { value: 'Ms', label: 'Ms' },
                            ]} onChange={handleNestedChange}
                            name='formData.familyInfo.fatherDetail.title'
                            value={formData?.familyInfo?.fatherDetail?.title}
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            skeletonClassName='rounded-r-none'
                            skeletonLoading={isLoading}
                        />
                        <CustomTextField
                            label="Father First Name" name='formData.familyInfo.fatherDetail.firstName' value={formData?.familyInfo?.fatherDetail?.firstName} onChange={handleNestedChange}
                            fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
                            skeletonLoading={isLoading}
                        />
                    </div>

                    {familyPropertyFields.map((field, index) => (renderFormField({
                        ...field,
                        value: formData?.familyInfo?.fatherDetail?.[field?.name],
                        name: `formData.familyInfo.fatherDetail.${field?.name}`,
                        onChange: handleNestedChange
                    }, index, isLoading)))}
                </>}

                {isLoading ?
                    <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                    : <div className="col-span-full flex items-center">
                        <Checkbox id="father-checkbox" checked={formData?.familyInfo?.fatherDetail?.isDeceased} color="secondary" onChange={handleFatherDeceased} />
                        <label htmlFor="father-checkbox" className="text-s cursor-pointer">Is your father deceased?</label>
                    </div>}
            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                {!formData?.familyInfo?.motherDetail?.isDeceased && <>
                    <h5 className="h4-p col-span-full">Mother Details</h5>
                    <div className="flex w-full">
                        <CustomAsyncSelect
                            className='bg-white w-24' label="Title"
                            options={[
                                { value: 'Dr', label: 'Dr' },
                                { value: 'Mr', label: 'Mr' },
                                { value: 'Mrs', label: 'Mrs' },
                                { value: 'Ms', label: 'Ms' },
                            ]} onChange={handleNestedChange}
                            name='formData.familyInfo.motherDetail.title' value={formData?.familyInfo?.motherDetail?.title}
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            skeletonClassName='rounded-r-none'
                            skeletonLoading={isLoading}
                        />

                        <CustomTextField
                            label="Mother First Name" name='formData.familyInfo.motherDetail.firstName' value={formData?.familyInfo?.motherDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading}
                        />
                    </div>

                    {familyPropertyFields.map((field, index) => (
                        renderFormField({
                            ...field,
                            value: formData?.familyInfo?.motherDetail?.[field.name],
                            name: `formData.familyInfo.motherDetail.${field?.name}`,
                            required: ['phNo'].includes(field?.name) ? false : field?.required,
                            onChange: handleNestedChange
                        }, index, isLoading)
                    ))}
                </>}
                {isLoading ?
                    <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                    : <div className="col-span-full flex items-center">
                        <Checkbox id="mother-checkbox" checked={formData?.familyInfo?.motherDetail?.isDeceased} color="secondary" onChange={handleMotherDeceased} />
                        <label htmlFor="mother-checkbox" className="text-s cursor-pointer">Is your mother deceased?</label>
                    </div>}
            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                {isLoading ?
                    <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                    : <div className="col-span-full flex items-center">
                        <Checkbox id="children-checkbox" checked={!!formData?.familyInfo?.childrenDetail?.length} className='bg-purple-800' color="secondary" onChange={handleChildrenCheckbox} />
                        <label htmlFor="children-checkbox" className="text-s cursor-pointer">Do you have children?</label>
                    </div>}

                {formData?.familyInfo?.childrenDetail?.length > 0 && (
                    <AddComponent array={formData?.familyInfo?.childrenDetail} name='formData.familyInfo.children' properties={childrenProperties} label='Children' handleChange={handleNestedChange} loading={isLoading} />
                )}
            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                <h5 className="h4-p col-span-full">Emergency Person Details</h5>
                <div className="flex w-full">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange}
                        name='formData.familyInfo.emergencyPersonDetail.title' value={formData?.familyInfo?.emergencyPersonDetail?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        skeletonClassName='rounded-r-none'
                        skeletonLoading={isLoading}
                    />

                    <CustomTextField
                        label="First Name" name='formData.familyInfo.emergencyPersonDetail.firstName' value={formData?.familyInfo?.emergencyPersonDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading}
                    />
                </div>

                {emergencyPersonFields.map((field, index) => (
                    renderFormField({ ...field, name: `formData.familyInfo.emergencyPersonDetail.${field?.name}`, value: formData?.familyInfo?.emergencyPersonDetail?.[field?.name], onChange: handleNestedChange }, index, isLoading)
                ))}
            </div>
        </div>
    )
}

export default FamilyInfo;