import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input'
import TextBox from '../../../../../../components/mui/text/TextBox'

const FamilyInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const fatherInfoFields = [
        { label: "Father Alive", value: formData?.familyInfo?.fatherDetail?.isDeceased?.status, data: formData?.familyInfo?.fatherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.fatherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.fatherDetail?.firstName?.status, data: formData?.familyInfo?.fatherDetail?.firstName?.data, name: 'formData.familyInfo.fatherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.fatherDetail?.middleName?.status, data: formData?.familyInfo?.fatherDetail?.middleName?.data, name: 'formData.familyInfo.fatherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.fatherDetail?.lastName?.status, data: formData?.familyInfo?.fatherDetail?.lastName?.data, name: 'formData.familyInfo.fatherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.fatherDetail?.phNo?.status, data: formData?.familyInfo?.fatherDetail?.phNo?.data, name: 'formData.familyInfo.fatherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.fatherDetail?.emailId?.status, data: formData?.familyInfo?.fatherDetail?.emailId?.data, name: 'formData.familyInfo.fatherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.fatherDetail?.income?.status, data: formData?.familyInfo?.fatherDetail?.income?.data, name: 'formData.familyInfo.fatherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.fatherDetail?.education?.status, data: formData?.familyInfo?.fatherDetail?.education?.data, name: 'formData.familyInfo.fatherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.fatherDetail?.profession?.status, data: formData?.familyInfo?.fatherDetail?.profession?.data, name: 'formData.familyInfo.fatherDetail.profession.status' }
    ];

    const motherInfoFields = [
        { label: "Mother Alive", value: formData?.familyInfo?.motherDetail?.isDeceased?.status, data: formData?.familyInfo?.motherDetail?.isDeceased?.data ? 'No' : 'Yes', name: 'formData.familyInfo.motherDetail.isDeceased.status' },
        { label: "First Name", value: formData?.familyInfo?.motherDetail?.firstName?.status, data: formData?.familyInfo?.motherDetail?.firstName?.data, name: 'formData.familyInfo.motherDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.motherDetail?.middleName?.status, data: formData?.familyInfo?.motherDetail?.middleName?.data, name: 'formData.familyInfo.motherDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.motherDetail?.lastName?.status, data: formData?.familyInfo?.motherDetail?.lastName?.data, name: 'formData.familyInfo.motherDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.motherDetail?.phNo?.status, data: formData?.familyInfo?.motherDetail?.phNo?.data, name: 'formData.familyInfo.motherDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.motherDetail?.emailId?.status, data: formData?.familyInfo?.motherDetail?.emailId?.data, name: 'formData.familyInfo.motherDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.motherDetail?.income?.status, data: formData?.familyInfo?.motherDetail?.income?.data, name: 'formData.familyInfo.motherDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.motherDetail?.education?.status, data: formData?.familyInfo?.motherDetail?.education?.data, name: 'formData.familyInfo.motherDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.motherDetail?.profession?.status, data: formData?.familyInfo?.motherDetail?.profession?.data, name: 'formData.familyInfo.motherDetail.profession.status' }
    ];

    const guardianInfoFields = [
        { label: "Guardian Exist", value: formData?.familyInfo?.guardianDetail?.isGuardianExist?.status, data: formData?.familyInfo?.guardianDetail?.isGuardianExist?.data ? 'Yes' : 'No', name: 'formData.familyInfo.guardianDetail.isGuardianExist.status' },
        { label: "First Name", value: formData?.familyInfo?.guardianDetail?.firstName?.status, data: formData?.familyInfo?.guardianDetail?.firstName?.data, name: 'formData.familyInfo.guardianDetail.firstName.status' },
        { label: "Middle Name", value: formData?.familyInfo?.guardianDetail?.middleName?.status, data: formData?.familyInfo?.guardianDetail?.middleName?.data, name: 'formData.familyInfo.guardianDetail.middleName.status' },
        { label: "Last Name", value: formData?.familyInfo?.guardianDetail?.lastName?.status, data: formData?.familyInfo?.guardianDetail?.lastName?.data, name: 'formData.familyInfo.guardianDetail.lastName.status' },
        { label: "Phone Number", value: formData?.familyInfo?.guardianDetail?.phNo?.status, data: formData?.familyInfo?.guardianDetail?.phNo?.data, name: 'formData.familyInfo.guardianDetail.phNo.status' },
        { label: "Email", value: formData?.familyInfo?.guardianDetail?.emailId?.status, data: formData?.familyInfo?.guardianDetail?.emailId?.data, name: 'formData.familyInfo.guardianDetail.emailId.status' },
        { label: "Income", value: formData?.familyInfo?.guardianDetail?.income?.status, data: formData?.familyInfo?.guardianDetail?.income?.data, name: 'formData.familyInfo.guardianDetail.income.status' },
        { label: "Education", value: formData?.familyInfo?.guardianDetail?.education?.status, data: formData?.familyInfo?.guardianDetail?.education?.data, name: 'formData.familyInfo.guardianDetail.education.status' },
        { label: "Profession", value: formData?.familyInfo?.guardianDetail?.profession?.status, data: formData?.familyInfo?.guardianDetail?.profession?.data, name: 'formData.familyInfo.guardianDetail.profession.status' }
    ];

    return (
        <React.Fragment>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Father Details')} value={masterCheckBoxValue(fatherInfoFields, checkBoxOptions)} name={fatherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Father Details</h4>
                </CustomCheckBox>

                {fatherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
            </div>

            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Mother Details')} value={masterCheckBoxValue(motherInfoFields, checkBoxOptions)} name={motherInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Mother Details</h4>
                </CustomCheckBox>

                {motherInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}><TextBox label={field?.label} value={field?.data} loading={isLoading} /></CustomCheckBox>)}
            </div>

            {formData?.familyInfo?.guardianDetail && <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Guardian Details')} value={masterCheckBoxValue(guardianInfoFields, checkBoxOptions)} name={guardianInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Guardian Details</h4>
                </CustomCheckBox>

                {guardianInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
                )}
            </div>}
        </React.Fragment>
    )
}

export default FamilyInfo;