import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { loadingState } from "../../../../../recoil/state";
import useFormOperations from "../../../../../hooks/useFormOperations";
import useFetchData from "../../../../../hooks/useFetchData";
import useSnackbar from "../../../../../hooks/useSnackbar";
import usePutData from "../../../../../hooks/usePutData";
import useGetData from "../../../../../hooks/useGetData";

import VerifyData from "./VerifyData";
import DocumentBar from "./DocumentBar";
import RejectConfirmationPopup from "../../../../../components/popups/localPopup/verification/RejectConfirmationPopup";
import { useSetRecoilState } from "recoil";

const OnlineVerification = () => {
    const { id } = useParams();
    const { data: recruitData, isLoading: recruitDataLoading } = useGetData(`/api/employee/hr/recruits/getVerificationFields/${id}`);
    const { data: documentsData, isLoading: isDocumentsLoading } = useGetData(`/api/employee/hr/recruits/getDocuments/${id}`);
    const { data: status } = useGetData(`/api/employee/hr/recruits/getStatus/${id}`);

    const { fetchData } = useFetchData();
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [documentState, setDocumentState] = useState({ isOpen: false, key: '', url: '' });
    const [dialogueData, setDialogueData] = useState({ open: false, message: '', onConfirm: () => { } });

    const toggleDocument = async (key = null) => {
        if (documentState.isOpen && documentState.key === key)
            return setDocumentState(prevState => ({ ...prevState, isOpen: false, url: '', key: '' }));

        const signedURL = await fetchData(`/api/employee/hr/recruits/getViewURL/${id}?key=${key}`);
        setDocumentState(prevState => ({ ...prevState, isOpen: true, url: signedURL, key }));
    };

    const handleDocumentAction = async (status) => {
        const updatedData = { ...documentsData };
        const key = documentState.key;

        const updateStatus = documents => {
            const doc = documents.find(doc => doc.key === key);
            if (doc) doc.onlineStatus = status;
        };
        Object.values(updatedData).forEach(updateStatus);

        const body = { status, key, type: 'online' }
        const { message, success } = await putData(`/api/employee/hr/recruits/updateDocumentStatus/${id}`, body);
        if (message) return handleSnackbarOpen(message, "warning");
        if (!success) return handleSnackbarOpen(`Opps.. Some Error occurred`, "error");
        handleSnackbarOpen(`The document status has been updated to ${status}.`, "success");
    };

    const onSubmit = async (formData, event, confirmation = false) => {
        const form = new FormData(event.target);
        const body = { data: formData, message: form.get('remark') }
        setLoading(true);
        const { data, message, success } = await putData(`/api/employee/hr/recruits/verifyOnlineAdmission/${id}?confirmation=${confirmation}`, body)
        setLoading(false);

        if (data?.confirmationRequired) return setDialogueData({ open: true, message: data.rejectedMessage, onConfirm: () => onSubmit(formData, event, true) });
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps.. some error occurred!', 'error');
        handleSnackbarOpen(`Student Application is ${data.status} successfully`, 'success');
        navigate('/admin/employee/recruits');
    };

    const { formData, masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit, onDiscard, clearForm } = useFormOperations(recruitData, onSubmit, "", recruitData);
    const formOperations = { masterCheckBoxValue, handleCheckboxChange, handleMasterCheckBox, handleSubmit, onDiscard, clearForm };

    return (
        <div className="grid grid-cols-16 w-full transition-transform delay-500 duration-500">
            <VerifyData id={id} formData={formData} documentOpen={documentState?.isOpen} isLoading={recruitDataLoading} formOperations={formOperations} onlineStatus={status?.onlineVerificationStatus} />

            <DocumentBar formData={formData} data={documentsData} documentState={documentState} toggleDocument={toggleDocument} handleDocumentAction={handleDocumentAction} loading={isDocumentsLoading} />

            {dialogueData.open && <RejectConfirmationPopup icon='edit' heading='Reject Recruit' text='Do you confirm that you are going to reject the admission form ?' message={dialogueData.message} onConfirm={dialogueData.onConfirm} onClose={() => setDialogueData((previousData) => ({ ...previousData, open: false }))} />}
        </div>
    );
};

export default OnlineVerification;