import React from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BlackToolTip from "./BlackToolTip";

const BackNavigate = ({ previousLink, text='Back', subText, className, ...props }) => {
    return (
        <BlackToolTip title={subText}>
            <Link to={previousLink} className={`flex items-center gap-1 h-fit group ${className}`} {...props}>
                <KeyboardBackspaceIcon className="text-zinc-400 group-hover:text-purple p-0.5 rounded-full bg-tertiary xs:p-0 xs:bg-inherit xs:rounded-none" />
                <p className="hidden xs:block lb-s group-hover:text-purple transition-all duration-300 ease-in-out group-hover:ml-1">{text}</p>
            </Link>
        </BlackToolTip>
    )
}

export default BackNavigate;