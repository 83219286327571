import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from "recoil";
import { useSearchParams, useNavigate } from 'react-router-dom'

import { loadingState } from "../../../../recoil/state";
import useGetData from '../../../../hooks/useGetData'
import useFormOperations from '../../../../hooks/useFormOperations'
import usePutData from '../../../../hooks/usePutData'
import useSnackbar from '../../../../hooks/useSnackbar';

import UpdateInfoImg from '../../../../assets/images/updateInfo.webp'

import ChangesInfo from './ChangesInfo'
import PersonalInfo from './informationTabs/PersonalInfo'
import EducationInfo from './informationTabs/EducationInfo'
import EmploymentInfo from './informationTabs/EmploymentInfo'
import IdentityInfo from './informationTabs/IdentityInfo'
import FinanceInfo from './informationTabs/FinanceInfo'
import AddressInfo from './informationTabs/AddressInfo'
import FamilyInfo from './informationTabs/FamilyInfo'
import TabSmall from '../../../../components/mui/tab/TabSmall'
import { CustomTextField } from '../../../../components/mui/Input'
import UpdateChangesPopup from '../../../../components/popups/localPopup/settings/UpdateChangesPopup'
import { ConfirmButton, CloseButton } from '../../../../components/mui/button/Button'
import PreviewChangesPopup from '../../../../components/popups/localPopup/settings/PreviewChangesPopup';
import { findUpdatedKeys } from '../../../../utils/formFunctions';

export const UpdateInfo = () => {
    const { data: employeeData, copyData: originalData, isLoading } = useGetData('/api/employee/personal/getCurrent')
    const { data: departmentData, isLoading: departmentDataLoading } = useGetData('/api/employee/personal/getDepartments');
    const { data: designationData, isLoading: designationDataLoading } = useGetData('/api/employee/personal/getDesignations');
    const { data: postData, isLoading: postDataLoading } = useGetData('/api/employee/personal/getPosts');
    const { data: categoryData, isLoading: categoryDataLoading } = useGetData('/api/employee/personal/getCategories')
    const { putData } = usePutData();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { handleSnackbarOpen } = useSnackbar();

    const setLoading = useSetRecoilState(loadingState);
    const [openUpdatePopup, setOpenUpdatePopup] = useState({ open: false, data: {} })
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
    const activeTab = searchParams.get('type');

    const handlePreviewChanges = () => setOpenUpdatePopup({ open: true, data: findUpdatedKeys(originalData, formData) });

    const onSubmit = async (formData) => {
        setOpenUpdatePopup({ open: false, data: {} });
        setLoading(true);
        const { success, message } = await putData(`/api/employee/personal/updateDetails`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        handleSnackbarOpen('Information Updated Successfully!', 'success');
        navigate('/admin/settings')
    }

    const { formData, handleNestedChange, handleSubmit, clearForm, setValue, updateData } = useFormOperations(employeeData, handlePreviewChanges, "", employeeData);

    const handleDiscard = () => {
        clearForm();
        navigate('/admin/settings');
    }

    return (
        <div className='grid grid-cols-1 lg:grid-cols-20 w-full gap-4 bg-white p-5'>
            <div className='col-span-full lg:col-span-14 hidden md:flex flex-row items-center text-left px-5 py-5 gap-4 lg:gap-6 bg-white drop-shadow rounded-lg h-full'>
                <img src={UpdateInfoImg} alt="missedImage" className='h-44 lg:h-52' />
                <div className='flex flex-col justify-center space-y-1'>
                    <h5 className='h3-p font-semibold'>Missed Something! Still not late!</h5>
                    <p className='lb-s leading-6 max-w-2xl'>These results are stepping stones to your academic Everest. Let them fuel your curiosity and guide you towards even greater brilliance!</p>
                </div>
            </div>

            <div className='col-span-full lg:col-span-6'>
                <ChangesInfo handlePreviewClick={() => setOpenPreviewPopup(true)} />
            </div>

            <form className='col-span-full flex flex-col gap-4' onSubmit={handleSubmit}>
                <div className='col-span-full flex flex-col bg-white drop-shadow rounded-lg p-5 md:pb-8 md:px-8 gap-8'>
                    <div className='flex justify-center lg:justify-start w-full'>
                        <TabSmall
                            tabs={['Personal', 'Education', 'Address', 'Identity', 'Family', "Finance", 'Employment']} />
                    </div>
                    <div className='flex flex-col w-full h-full'>
                        {activeTab === "Personal" && <PersonalInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} setValue={setValue} />}

                        {activeTab === "Education" && <EducationInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}

                        {activeTab === "Address" && <AddressInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} updateData={updateData} />}

                        {activeTab === "Identity" && <IdentityInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} data={{ categoryData: categoryData, categoryDataLoading: categoryDataLoading }} />}

                        {activeTab === "Family" && <FamilyInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}

                        {activeTab === "Finance" && <FinanceInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} />}

                        {activeTab === "Employment" && <EmploymentInfo formData={formData} isLoading={isLoading} handleNestedChange={handleNestedChange} data={{ departmentData: departmentData, departmentDataLoading: departmentDataLoading, designationData: designationData, designationDataLoading: designationDataLoading, postData: postData, postDataLoading: postDataLoading }} />}
                    </div>
                </div>

                <div className='col-span-full flex flex-col bg-white drop-shadow rounded-lg p-5 md:p-8 gap-5 xs:gap-8' >
                    <CustomTextField label='Reason' multiline rows={4} className='no-scrollbar' skeletonLoading={isLoading} skeletonClassName='h-[126px]' />
                    <div className='flex flex-col-reverse xs:flex-row justify-end gap-3 xs:gap-5 w-full'>
                        <CloseButton size='large' text="Discard" onClick={handleDiscard} />
                        <ConfirmButton size='large' text='Confirm' type='submit'
                            disabled={findUpdatedKeys(originalData, formData) ? false : true}
                        />
                    </div>
                </div>
            </form>

            {openUpdatePopup?.open && <UpdateChangesPopup data={openUpdatePopup?.data} previousData={originalData} onClose={() => setOpenUpdatePopup({ open: false, data: {} })} onConfirm={onSubmit} />}
            {openPreviewPopup && <PreviewChangesPopup onClose={() => setOpenPreviewPopup(false)} />}
        </div>
    )
}

export default UpdateInfo;