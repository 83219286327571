import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const DiplomaInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const diplomaFields = [
        { label: 'Degree', value: formData?.exams?.lateralEntryDetail?.degree?.status, data: formData?.exams?.lateralEntryDetail?.degree?.data, name: 'formData.exams.lateralEntryDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.lateralEntryDetail?.major?.status, data: formData?.exams?.lateralEntryDetail?.major?.data, name: 'formData.exams.lateralEntryDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.lateralEntryDetail?.degreeType?.status, data: formData?.exams?.lateralEntryDetail?.degreeType?.data, name: 'formData.exams.lateralEntryDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.lateralEntryDetail?.passingYear?.status, data: formData?.exams?.lateralEntryDetail?.passingYear?.data, name: 'formData.exams.lateralEntryDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.lateralEntryDetail?.collegeName?.status, data: formData?.exams?.lateralEntryDetail?.collegeName?.data, name: 'formData.exams.lateralEntryDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.lateralEntryDetail?.country?.status, data: formData?.exams?.lateralEntryDetail?.country?.data, name: 'formData.exams.lateralEntryDetail.country.status' },
        { label: 'College State', value: formData?.exams?.lateralEntryDetail?.state?.status, data: formData?.exams?.lateralEntryDetail?.state?.data, name: 'formData.exams.lateralEntryDetail.state.status' },
        { label: 'College City', value: formData?.exams?.lateralEntryDetail?.city?.status, data: formData?.exams?.lateralEntryDetail?.city?.data, name: 'formData.exams.lateralEntryDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.lateralEntryDetail?.percentage?.status, data: formData?.exams?.lateralEntryDetail?.percentage?.data, name: 'formData.exams.lateralEntryDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.lateralEntryDetail?.grade?.status, data: formData?.exams?.lateralEntryDetail?.grade?.data, name: 'formData.exams.lateralEntryDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.lateralEntryDetail?.cgpa?.status, data: formData?.exams?.lateralEntryDetail?.cgpa?.data, name: 'formData.exams.lateralEntryDetail.cgpa.status' }
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Diploma Details')} value={masterCheckBoxValue(diplomaFields, checkBoxOptions)} name={diplomaFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Lateral Entry Details</h4>
            </CustomCheckBox>

            {diplomaFields?.map((field, index) => ((isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <TextBox label={field?.label} value={field?.data} loading={isLoading} />
            </CustomCheckBox>
            ))}
        </div>
    )
}

export default DiplomaInfo;