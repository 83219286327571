import React, { useState, useEffect } from 'react';
import { dateFormat } from '../../../utils/dateFormat';
import SkeletonText from '../../../components/mui/text/SkeltonText';
import { AccessTime } from '@mui/icons-material';

const Clock = ({ className }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className={`flex flex-col justify-center gap-3 w-full text-purple ${className}`}>
      <div className='flex flex-col gap-1'>
        <span className="text-4xl font-bold tracking-wider font-mono text-center">
          {formatTime(date)}
        </span>

        <span className="font-medium opacity-80 text-center">
          {formatDate(date)}
        </span>
      </div>

      <SkeletonText loading={false} width={160} height={32} skeletonClassName='w-full'>
        <div className='flex text-center justify-center gap-1 bg-purple px-4 py-2 text-purple text-sm font-medium w-full rounded-md'>
          <AccessTime sx={{ fontSize: 20 }} />
          Last Login: {dateFormat(date, true)}
        </div>
      </SkeletonText>
    </div>
  );
};

export default Clock;