import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const PostgraduateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const pgFields = [
        { label: 'Degree', value: formData?.exams?.pgDetail?.degree?.status, data: formData?.exams?.pgDetail?.degree?.data, name: 'formData.exams.pgDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.pgDetail?.major?.status, data: formData?.exams?.pgDetail?.major?.data, name: 'formData.exams.pgDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.pgDetail?.degreeType?.status, data: formData?.exams?.pgDetail?.degreeType?.data, name: 'formData.exams.pgDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.pgDetail?.passingYear?.status, data: formData?.exams?.pgDetail?.passingYear?.data, name: 'formData.exams.pgDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.pgDetail?.collegeName?.status, data: formData?.exams?.pgDetail?.collegeName?.data, name: 'formData.exams.pgDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.pgDetail?.country?.status, data: formData?.exams?.pgDetail?.country?.data, name: 'formData.exams.pgDetail.country.status' },
        { label: 'College State', value: formData?.exams?.pgDetail?.state?.status, data: formData?.exams?.pgDetail?.state?.data, name: 'formData.exams.pgDetail.state.status' },
        { label: 'College City', value: formData?.exams?.pgDetail?.city?.status, data: formData?.exams?.pgDetail?.city?.data, name: 'formData.exams.pgDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.pgDetail?.percentage?.status, data: formData?.exams?.pgDetail?.percentage?.data, name: 'formData.exams.pgDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.pgDetail?.grade?.status, data: formData?.exams?.pgDetail?.grade?.data, name: 'formData.exams.pgDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.pgDetail?.cgpa?.status, data: formData?.exams?.pgDetail?.cgpa?.data, name: 'formData.exams.pgDetail.cgpa.status' }
    ];

    return (
        <div className={`grid gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Post Graduation Details')} value={masterCheckBoxValue(pgFields, checkBoxOptions)} name={pgFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Post Graduation Details</h4>
            </CustomCheckBox>
            
            {pgFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
            )}
        </div>
    )
}

export default PostgraduateInfo;