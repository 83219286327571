import React from 'react'
import CustomPieChart from '../graphs/PieChart';

const PieChartWrapper = ({ data, heading, isLoading, className, ...props }) => {
    return (
        <div className={`flex flex-col w-full drop-shadow bg-white rounded-md p-5 gap-2 ${className}`}>
            <h1 className='body-semibold text-dark'><span className='line-clamp-1'>{heading}</span></h1>
            <hr className='border' />
            <CustomPieChart data={data} isLoading={isLoading} {...props} />
        </div>
    )
}

export default PieChartWrapper