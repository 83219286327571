import { dateFormat } from "../utils/dateFormat";

export const personalFields = (formData, updatedData) => [
    { label: 'Title', value: formData?.title, new: updatedData?.title },
    { label: 'First Name', value: formData?.firstName, new: updatedData?.firstName },
    { label: "Middle Name", value: formData?.middleName, new: updatedData?.middleName },
    { label: "Last Name", value: formData?.lastName, new: updatedData?.lastName },
    { label: "Gender", value: formData?.personalInfo?.gender, new: updatedData?.personalInfo?.gender },
    { label: "Date of Birth", value: dateFormat(formData?.personalInfo?.dob, null, null), new: dateFormat(updatedData?.personalInfo?.dob, null, null) },
    { label: "Blood Group", value: formData?.personalInfo?.bloodGroup, new: updatedData?.personalInfo?.bloodGroup },
    { label: "Marital Status", value: formData?.additionalInfo?.maritalStatus, new: updatedData?.additionalInfo?.maritalStatus },
    { label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue, new: updatedData?.additionalInfo?.motherTongue },
    { label: "Disability", value: formData?.additionalInfo?.disability, new: updatedData?.additionalInfo?.disability }
];

export const residentialAddressFields = (formData, updatedData) => [
    { label: 'Address', value: formData?.personalInfo?.residentialAddress?.address, new: updatedData?.personalInfo?.residentialAddress?.address },
    { label: 'Town', value: formData?.personalInfo?.residentialAddress?.town, new: updatedData?.personalInfo?.residentialAddress?.town },
    { label: 'City', value: formData?.personalInfo?.residentialAddress?.city, new: updatedData?.personalInfo?.residentialAddress?.city },
    { label: 'State', value: formData?.personalInfo?.residentialAddress?.state, new: updatedData?.personalInfo?.residentialAddress?.state },
    { label: 'Country', value: formData?.personalInfo?.residentialAddress?.country, new: updatedData?.personalInfo?.residentialAddress?.country },
    { label: 'Pincode', value: formData?.personalInfo?.residentialAddress?.pincode, new: updatedData?.personalInfo?.residentialAddress?.pincode }
]

export const permanentAddressFields = (formData, updatedData) => [
    { label: 'Address', value: formData?.personalInfo?.permanentAddress?.address, new: updatedData?.personalInfo?.permanentAddress?.address },
    { label: 'Town', value: formData?.personalInfo?.permanentAddress?.town, new: updatedData?.personalInfo?.permanentAddress?.town },
    { label: 'City', value: formData?.personalInfo?.permanentAddress?.city, new: updatedData?.personalInfo?.permanentAddress?.city },
    { label: 'State', value: formData?.personalInfo?.permanentAddress?.state, new: updatedData?.personalInfo?.permanentAddress?.state },
    { label: 'Country', value: formData?.personalInfo?.permanentAddress?.country, new: updatedData?.personalInfo?.permanentAddress?.country },
    { label: 'Pincode', value: formData?.personalInfo?.permanentAddress?.pincode, new: updatedData?.personalInfo?.permanentAddress?.pincode }
]

export const identityFields = (formData, updatedData) => [
    { label: "Nationality", value: formData?.identityInfo?.nationality, new: updatedData?.identityInfo?.nationality },
    { label: "Category", value: formData?.identityInfo?.category, new: updatedData?.identityInfo?.category },
    { label: "Religion", value: formData?.identityInfo?.religion, new: updatedData?.identityInfo?.religion },
    { label: "Caste", value: formData?.identityInfo?.caste, new: updatedData?.identityInfo?.caste },
    { label: "Sub Caste", value: formData?.identityInfo?.subCaste, new: updatedData?.identityInfo?.subCaste }
]

export const financialFields = (formData, updatedData) => [
    { label: 'Bank Name', value: formData?.financialAndLegalInfo?.bankName, new: updatedData?.financialAndLegalInfo?.bankName },
    { label: 'Account Number', value: formData?.financialAndLegalInfo?.accountNo, new: updatedData?.financialAndLegalInfo?.accountNo },
    { label: 'IFSC', value: formData?.financialAndLegalInfo?.ifsc, new: updatedData?.financialAndLegalInfo?.ifsc },
    { label: 'Aadhar Card Number', value: formData?.financialAndLegalInfo?.aadharCardNo, new: updatedData?.financialAndLegalInfo?.aadharCardNo },
    { label: 'Pan Card Number', value: formData?.financialAndLegalInfo?.panCardNo, new: updatedData?.financialAndLegalInfo?.panCardNo }
]

export const elementaryFields = (formData, updatedData) => [
    { label: "Board Name", value: formData?.education?.elementaryDetail?.boardName, new: updatedData?.education?.elementaryDetail?.boardName },
    { label: "Exam Year", value: formData?.education?.elementaryDetail?.examYear, new: updatedData?.education?.elementaryDetail?.examYear },
    { label: "School Name", value: formData?.education?.elementaryDetail?.schoolName, new: updatedData?.education?.elementaryDetail?.schoolName },
    { label: "City", value: formData?.education?.elementaryDetail?.city, new: updatedData?.education?.elementaryDetail?.city },
    { label: "State", value: formData?.education?.elementaryDetail?.state, new: updatedData?.education?.elementaryDetail?.state },
    { label: "Country", value: formData?.education?.elementaryDetail?.country, new: updatedData?.education?.elementaryDetail?.country },
    { label: "Total Marks", value: formData?.education?.elementaryDetail?.totalMarks, new: updatedData?.education?.elementaryDetail?.totalMarks },
    { label: "Obtained Marks", value: formData?.education?.elementaryDetail?.obtainedMarks, new: updatedData?.education?.elementaryDetail?.obtainedMarks },
    { label: "Grade", value: formData?.education?.elementaryDetail?.grade, new: updatedData?.education?.elementaryDetail?.grade },
    { label: "Percentage", value: formData?.education?.elementaryDetail?.percentage, new: updatedData?.education?.elementaryDetail?.percentage },
    { label: "CGPA", value: formData?.education?.elementaryDetail?.cgpa, new: updatedData?.education?.elementaryDetail?.cgpa }
];

export const intermediateFields = (formData, updatedData) => [
    { label: "Board Name", value: formData?.education?.intermediateDetail?.boardName, new: updatedData?.education?.intermediateDetail?.boardName },
    { label: "Exam Year", value: formData?.education?.intermediateDetail?.examYear, new: updatedData?.education?.intermediateDetail?.examYear },
    { label: "School Name", value: formData?.education?.intermediateDetail?.schoolName, new: updatedData?.education?.intermediateDetail?.schoolName },
    { label: "City", value: formData?.education?.intermediateDetail?.city, new: updatedData?.education?.intermediateDetail?.city },
    { label: "State", value: formData?.education?.intermediateDetail?.state, new: updatedData?.education?.intermediateDetail?.state },
    { label: "Country", value: formData?.education?.intermediateDetail?.country, new: updatedData?.education?.intermediateDetail?.country },
    { label: "Total Marks", value: formData?.education?.intermediateDetail?.totalMarks, new: updatedData?.education?.intermediateDetail?.totalMarks },
    { label: "Obtained Marks", value: formData?.education?.intermediateDetail?.obtainedMarks, new: updatedData?.education?.intermediateDetail?.obtainedMarks },
    { label: "Grade", value: formData?.education?.intermediateDetail?.grade, new: updatedData?.education?.intermediateDetail?.grade },
    { label: "Percentage", value: formData?.education?.intermediateDetail?.percentage, new: updatedData?.education?.intermediateDetail?.percentage },
    { label: "CGPA", value: formData?.education?.intermediateDetail?.cgpa, new: updatedData?.education?.intermediateDetail?.cgpa }
];

export const diplomaFields = (formData, updatedData) => updatedData?.education?.diplomaDetails?.map((diploma, index) => [
    { label: "Degree", value: formData?.education?.diplomaDetails?.[index]?.degree, new: diploma?.degree },
    { label: "Major", value: formData?.education?.diplomaDetails?.[index]?.major, new: diploma?.major },
    { label: "Completion Year", value: formData?.education?.diplomaDetails?.[index]?.passingYear, new: diploma?.passingYear },
    { label: "Degree Type", value: formData?.education?.diplomaDetails?.[index]?.degreeType, new: diploma?.degreeType },
    { label: "College Name", value: formData?.education?.diplomaDetails?.[index]?.collegeName, new: diploma?.collegeName },
    { label: "City", value: formData?.education?.diplomaDetails?.[index]?.city, new: diploma?.city },
    { label: "State", value: formData?.education?.diplomaDetails?.[index]?.state, new: diploma?.state },
    { label: "Country", value: formData?.education?.diplomaDetails?.[index]?.country, new: diploma?.country },
    { label: "Percentage", value: formData?.education?.diplomaDetails?.[index]?.percentage, new: diploma?.percentage },
    { label: "Grade", value: formData?.education?.diplomaDetails?.[index]?.grade, new: diploma?.grade },
    { label: "CGPA", value: formData?.education?.diplomaDetails?.[index]?.cgpa, new: diploma?.cgpa }
]);

export const undergraduateFields = (formData, updatedData) => updatedData?.education?.ugDetails?.map((ug, index) => [
    { label: 'Degree', value: formData?.education?.ugDetails?.[index]?.degree, new: ug?.degree },
    { label: 'Major', value: formData?.education?.ugDetails?.[index]?.major, new: ug?.major },
    { label: 'Completion Year', value: formData?.education?.ugDetails?.[index]?.passingYear, new: ug?.passingYear },
    { label: 'Degree Type', value: formData?.education?.ugDetails?.[index]?.degreeType, new: ug?.degreeType },
    { label: 'College Name', value: formData?.education?.ugDetails?.[index]?.collegeName, new: ug?.collegeName },
    { label: 'City', value: formData?.education?.ugDetails?.[index]?.city, new: ug?.city },
    { label: 'State', value: formData?.education?.ugDetails?.[index]?.state, new: ug?.state },
    { label: 'Country', value: formData?.education?.ugDetails?.[index]?.country, new: ug?.country },
    { label: 'Percentage', value: formData?.education?.ugDetails?.[index]?.percentage, new: ug?.percentage },
    { label: 'Grade', value: formData?.education?.ugDetails?.[index]?.grade, new: ug?.grade },
    { label: 'CGPA', value: formData?.education?.ugDetails?.[index]?.cgpa, new: ug?.cgpa }
]);


export const postgraduateFields = (formData, updatedData) => updatedData?.education?.pgDetails?.map((pg, index) => [
    { label: 'Degree', value: formData?.education?.pgDetails?.[index]?.degree, new: pg?.degree },
    { label: 'Major', value: formData?.education?.pgDetails?.[index]?.major, new: pg?.major },
    { label: 'Completion Year', value: formData?.education?.pgDetails?.[index]?.passingYear, new: pg?.passingYear },
    { label: 'Degree Type', value: formData?.education?.pgDetails?.[index]?.degreeType, new: pg?.degreeType },
    { label: 'College Name', value: formData?.education?.pgDetails?.[index]?.collegeName, new: pg?.collegeName },
    { label: 'City', value: formData?.education?.pgDetails?.[index]?.city, new: pg?.city },
    { label: 'State', value: formData?.education?.pgDetails?.[index]?.state, new: pg?.state },
    { label: 'Country', value: formData?.education?.pgDetails?.[index]?.country, new: pg?.country },
    { label: 'Percentage', value: formData?.education?.pgDetails?.[index]?.percentage, new: pg?.percentage },
    { label: 'Grade', value: formData?.education?.pgDetails?.[index]?.grade, new: pg?.grade },
    { label: 'CGPA', value: formData?.education?.pgDetails?.[index]?.cgpa, new: pg?.cgpa }
]);

export const doctorateFields = (formData, updatedData) => updatedData?.education?.doctorateDetails?.map((phd, index) => [
    { label: 'Degree', value: formData?.education?.doctorateDetails?.[index]?.degree, new: phd?.degree },
    { label: 'Dissertation Title', value: formData?.education?.doctorateDetails?.[index]?.dissertationTitle, new: phd?.dissertationTitle },
    { label: 'Supervisor', value: formData?.education?.doctorateDetails?.[index]?.supervisor, new: phd?.supervisor },
    { label: 'Completion Year', value: formData?.education?.doctorateDetails?.[index]?.completionYear, new: phd?.completionYear },
    { label: 'University Name', value: formData?.education?.doctorateDetails?.[index]?.universityName, new: phd?.universityName },
    { label: 'City', value: formData?.education?.doctorateDetails?.[index]?.city, new: phd?.city },
    { label: 'State', value: formData?.education?.doctorateDetails?.[index]?.state, new: phd?.state },
    { label: 'Country', value: formData?.education?.doctorateDetails?.[index]?.country, new: phd?.country },
    { label: 'CGPA', value: formData?.education?.doctorateDetails?.[index]?.cgpa, new: phd?.cgpa }
]);

export const certificationFields = (formData, updatedData) => updatedData?.education?.certificationDetails?.map((certificate, index) => [
    { label: 'Certification', value: formData?.education?.certificationDetails?.[index]?.certification, new: certificate?.certification },
    { label: 'Issuing Organization', value: formData?.education?.certificationDetails?.[index]?.issuingOrganization, new: certificate?.issuingOrganization },
    { label: 'Issue Date', value: dateFormat(formData?.education?.certificationDetails?.[index]?.issueDate, null, null), new: dateFormat(certificate?.issueDate, null, null) },
    { label: 'Expiry Date', value: dateFormat(formData?.education?.certificationDetails?.[index]?.expiryDate, null, null), new: dateFormat(certificate?.expiryDate, null, null) },
    { label: 'Certificate ID', value: formData?.education?.certificationDetails?.[index]?.certificateID, new: certificate?.certificateID },
    { label: 'Score', value: formData?.education?.certificationDetails?.[index]?.score, new: certificate?.score },
    { label: 'Certification URL', value: formData?.education?.certificationDetails?.[index]?.certificateUrl, new: certificate?.certificateUrl },
    { label: 'License Number', value: formData?.education?.certificationDetails?.[index]?.license, new: certificate?.license }
]);

export const fatherFields = (formData, updatedData) => [
    { label: 'Title', value: formData?.familyInfo?.fatherDetail?.title, new: updatedData?.familyInfo?.fatherDetail?.title },
    { label: 'First Name', value: formData?.familyInfo?.fatherDetail?.firstName, new: updatedData?.familyInfo?.fatherDetail?.firstName },
    { label: 'Middle Name', value: formData?.familyInfo?.fatherDetail?.middleName, new: updatedData?.familyInfo?.fatherDetail?.middleName },
    { label: 'Last Name', value: formData?.familyInfo?.fatherDetail?.lastName, new: updatedData?.familyInfo?.fatherDetail?.lastName },
    { label: 'Phone Number', value: formData?.familyInfo?.fatherDetail?.phNo, new: updatedData?.familyInfo?.fatherDetail?.phNo },
    { label: 'Email ID', value: formData?.familyInfo?.fatherDetail?.emailId, new: updatedData?.familyInfo?.fatherDetail?.emailId },
    { label: 'Education', value: formData?.familyInfo?.fatherDetail?.education, new: updatedData?.familyInfo?.fatherDetail?.education },
    { label: 'Profession', value: formData?.familyInfo?.fatherDetail?.profession, new: updatedData?.familyInfo?.fatherDetail?.profession },
    { label: 'Income', value: formData?.familyInfo?.fatherDetail?.income, new: updatedData?.familyInfo?.fatherDetail?.income }
];

export const motherFields = (formData, updatedData) => [
    { label: 'Title', value: formData?.familyInfo?.motherDetail?.title, new: updatedData?.familyInfo?.motherDetail?.title },
    { label: 'First Name', value: formData?.familyInfo?.motherDetail?.firstName, new: updatedData?.familyInfo?.motherDetail?.firstName },
    { label: 'Middle Name', value: formData?.familyInfo?.motherDetail?.middleName, new: updatedData?.familyInfo?.motherDetail?.middleName },
    { label: 'Last Name', value: formData?.familyInfo?.motherDetail?.lastName, new: updatedData?.familyInfo?.motherDetail?.lastName },
    { label: 'Phone Number', value: formData?.familyInfo?.motherDetail?.phNo, new: updatedData?.familyInfo?.motherDetail?.phNo },
    { label: 'Email ID', value: formData?.familyInfo?.motherDetail?.emailId, new: updatedData?.familyInfo?.motherDetail?.emailId },
    { label: 'Education', value: formData?.familyInfo?.motherDetail?.education, new: updatedData?.familyInfo?.motherDetail?.education },
    { label: 'Profession', value: formData?.familyInfo?.motherDetail?.profession, new: updatedData?.familyInfo?.motherDetail?.profession },
    { label: 'Income', value: formData?.familyInfo?.motherDetail?.income, new: updatedData?.familyInfo?.motherDetail?.income }
];

export const partnerFields = (formData, updatedData) => [
    { label: 'Title', value: formData?.familyInfo?.partnerDetail?.title, new: updatedData?.familyInfo?.partnerDetail?.title },
    { label: 'First Name', value: formData?.familyInfo?.partnerDetail?.firstName, new: updatedData?.familyInfo?.partnerDetail?.firstName },
    { label: 'Middle Name', value: formData?.familyInfo?.partnerDetail?.middleName, new: updatedData?.familyInfo?.partnerDetail?.middleName },
    { label: 'Last Name', value: formData?.familyInfo?.partnerDetail?.lastName, new: updatedData?.familyInfo?.partnerDetail?.lastName },
    { label: 'Phone Number', value: formData?.familyInfo?.partnerDetail?.phNo, new: updatedData?.familyInfo?.partnerDetail?.phNo },
    { label: 'Email ID', value: formData?.familyInfo?.partnerDetail?.emailId, new: updatedData?.familyInfo?.partnerDetail?.emailId },
    { label: 'Education', value: formData?.familyInfo?.partnerDetail?.education, new: updatedData?.familyInfo?.partnerDetail?.education },
    { label: 'Profession', value: formData?.familyInfo?.partnerDetail?.profession, new: updatedData?.familyInfo?.partnerDetail?.profession },
    { label: 'Income', value: formData?.familyInfo?.partnerDetail?.income, new: updatedData?.familyInfo?.partnerDetail?.income }
];

export const emergencyPersonFields = (formData, updatedData) => [
    { label: 'Title', value: formData?.familyInfo?.emergencyPersonDetail?.title, new: updatedData?.familyInfo?.emergencyPersonDetail?.title },
    { label: 'First Name', value: formData?.familyInfo?.emergencyPersonDetail?.firstName, new: updatedData?.familyInfo?.emergencyPersonDetail?.firstName },
    { label: 'Middle Name', value: formData?.familyInfo?.emergencyPersonDetail?.middleName, new: updatedData?.familyInfo?.emergencyPersonDetail?.middleName },
    { label: 'Last Name', value: formData?.familyInfo?.emergencyPersonDetail?.lastName, new: updatedData?.familyInfo?.emergencyPersonDetail?.lastName },
    { label: 'Phone Number', value: formData?.familyInfo?.emergencyPersonDetail?.phNo, new: updatedData?.familyInfo?.emergencyPersonDetail?.phNo },
    { label: 'Relation', value: formData?.familyInfo?.emergencyPersonDetail?.relation, new: updatedData?.familyInfo?.emergencyPersonDetail?.relation },
];

export const employmentFields = (formData, updatedData) =>
    updatedData?.experience?.map((experience, index) => [
        { label: 'Organization Name', value: formData?.experience?.[index]?.organizationName, new: experience?.organizationName },
        { label: 'Position', value: formData?.experience?.[index]?.position, new: experience?.position },
        { label: 'Start Date', value: dateFormat(formData?.experience?.[index]?.startDate, null, null), new: dateFormat(experience?.startDate, null, null) },
        { label: 'End Date', value: dateFormat(formData?.experience?.[index]?.endDate, null, null), new: dateFormat(experience?.endDate, null, null) },
    ]);

export const childrenFields = (formData, updatedData) => updatedData?.familyInfo?.childrenDetail?.map((children, index) => [
    { label: "First Name", value: formData?.familyInfo?.childrenDetail?.[index]?.firstName, new: children?.firstName },
    { label: "Middle Name", value: formData?.familyInfo?.childrenDetail?.[index]?.middleName, new: children?.middleName },
    { label: "Last Name", value: formData?.familyInfo?.childrenDetail?.[index]?.lastName, new: children?.lastName },
    { label: "DOB", value: dateFormat(formData?.familyInfo?.childrenDetail?.[index]?.dob, null, null), new: dateFormat(children?.dob, null, null) },
    { label: "Education", value: formData?.familyInfo?.childrenDetail?.[index]?.education, new: children?.education }
]);

export const formLabels = {
    "title": "Title",
    "firstName": "First Name",
    "middleName": "Middle Name",
    "lastName": "Last Name",
    "gender": "Gender",
    "dob": "Date of Birth",
    "bloodGroup": "Blood Group",
    "maritalStatus": "Marital Status",
    "motherTongue": "Mother Tongue",
    "disability": "Disability",
    "address": "Address",
    "town": "Town",
    "city": "City",
    "state": "State",
    "country": "Country",
    "pincode": "Pincode",
    "nationality": "Nationality",
    "category": "Category",
    "religion": "Religion",
    "caste": "Caste",
    "subCaste": "Sub Caste",
    "bankName": "Bank Name",
    "accountNo": "Account Number",
    "ifsc": "IFSC",
    "aadharCardNo": "Aadhar Card Number",
    "panCardNo": "Pan Card Number",
    "boardName": "Board Name",
    "examYear": "Exam Year",
    "schoolName": "School Name",
    "totalMarks": "Total Marks",
    "obtainedMarks": "Obtained Marks",
    "grade": "Grade",
    "percentage": "Percentage",
    "cgpa": "CGPA",
    "degree": "Degree",
    "major": "Major",
    "passingYear": "Completion Year",
    "degreeType": "Degree Type",
    "collegeName": "College Name",
    "dissertationTitle": "Dissertation Title",
    "supervisor": "Supervisor",
    "completionYear": "Completion Year",
    "universityName": "University Name",
    "certification": "Certification",
    "issuingOrganization": "Issuing Organization",
    "issueDate": "Issue Date",
    "expiryDate": "Expiry Date",
    "certificateID": "Certificate ID",
    "score": "Score",
    "certificateUrl": "Certification URL",
    "license": "License Number",
    "phNo": "Phone Number",
    "emailId": "Email ID",
    "education": "Education",
    "profession": "Profession",
    "income": "Income",
    "relation": "Relation",
    "organizationName": "Organization Name",
    "position": "Position",
    "startDate": "Start Date",
    "endDate": "End Date"
}