import React from 'react'
import AddComponent from '../../../../../components/mui/AddComponent';
import { calculateExperience } from '../../../../../utils/dateFormat';
import { CustomMultiCheckboxSelect } from '../../../../../components/mui/Input';
import renderFormField from '../../../../../utils/renderFormField';

const EmploymentInfo = ({ formData, data, handleNestedChange, isLoading }) => {

    const departmentOptions = data?.departmentData?.map(department => ({ label: department?.name, value: department?._id })) || [];
    const designationOptions = data?.designationData?.map(designation => ({ label: designation, value: designation })) || [];
    const postOptions = data?.postData?.map(post => ({ label: post, value: post })) || [];

    const institutionalDetailsFields = [
        { type: 'input', label: "Employee ID", value: formData?.employeeId, name: 'formData.employeeId' },
        { type: 'select', label: "Designation", value: formData?.designation, name: 'formData.designation', options: designationOptions, loading: data?.designationDataLoading },
        { type: 'select', label: "Post", value: formData?.post, name: 'formData.post', options: postOptions, loading: data?.postDataLoading, required: false },
        { type: 'date', label: "Joining Date", value: formData?.joiningDate, name: 'formData.joiningDate' },
    ]

    const employmentProperties = [
        { type: 'input', label: "Organization Name", name: "organizationName" },
        { type: 'input', label: "Designation", name: "position" },
        { type: 'date', label: "Joining Date", name: "startDate" },
        { type: 'date', label: "Leaving Date", name: "endDate" },
        { type: 'input', label: "Experience", name1: 'startDate', name2: 'endDate', disabled: true, fnc: calculateExperience }
    ]

    return (
        <div class="flex flex-col w-full gap-8">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
                <h5 className="col-span-full h4-p">Current Employment</h5>
                {institutionalDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
                <CustomMultiCheckboxSelect
                    className='col-span-full bg-white'
                    loading={data?.departmentDataLoading}
                    label='Department'
                    value={formData?.departmentIds}
                    name='formData.departmentIds'
                    onChange={handleNestedChange}
                    options={departmentOptions}
                    color='secondary'
                    required={false}
                    skeletonLoading={isLoading}
                    skeletonClassName='col-span-full'
                    noOptionsMessage='Please Select College first'
                />
            </div>

            <div class="flex flex-col w-full gap-5">
                <h5 className="col-span-full h4-p">Previous Experiences</h5>
                <AddComponent array={formData?.experience} name='formData.experience' properties={employmentProperties} label='Experience' handleChange={handleNestedChange} loading={isLoading} />
            </div>
        </div>
    )
}

export default EmploymentInfo;