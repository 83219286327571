import React from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";

import TransferIcon from '../../../../assets/icons/TransferIcon'
import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import CustomTable from "../../../../components/mui/Table";
import RenderStats from "../../../../utils/RenderStats";

const AlumniStudents = () => {
    const { data: enrollData, isLoading: enrollDataLoading, updateData } = useGetData('/api/employee/admission/alumnis/getAlumnis');
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/alumnis/stats/getStudentsProgramsCount');
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const setLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/alumnis/profile/" + value + '?type=Personal'));

    const handleReEnrollment = (event, value) => openPopup('Transfer to Enrolled', 'Are you sure you want to transfer this Alumni to Enrolled?', 'edit', () => onReEnrollment(value));

    const onReEnrollment = async (id) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledTransfer/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== id);
        });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Student Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        {
            id: 'admissionYear', label: 'Admission Year', minWidth: 140, type: 'text',
            render: (row) => (
                <div>
                    {row?.admissionYear}-{(row?.admissionYear + 1).toString().slice(-2)}
                </div>
            )
        },
        {
            id: 'graduationYear', label: 'Graduation Year', minWidth: 140, type: 'text',
            render: (row) => (
                <div>
                    {row?.graduationYear}-{(row?.graduationYear + 1).toString().slice(-2)}
                </div>
            )
        },
        {
            id: 'reScholar', label: 'Transfer to Enrolled', type: 'iconButton',
            align: 'center', onClick: handleReEnrollment,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={StudentIcon} data={statsData} statsLoading={statsLoading} />

            <CustomTable
                heading="All Alumni Students"
                text="Alumni students data"
                isLoading={enrollDataLoading}
                columns={columns} rows={enrollData}
                sortParameters={['name', 'programKey', 'admissionYear', 'graduationYear']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
            />
        </div>
    )
}

export default AlumniStudents;