import { useState } from "react";
import { useNavigate } from "react-router-dom";

const HOST_URL = process.env.REACT_APP_HOST;

const useDeleteData = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const deleteData = async (url, options = {}) => {
        setIsLoading(true);
        setError(null);

        const token = sessionStorage.getItem('auth_token');

        try {
            const response = await fetch(HOST_URL + url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                ...options,
            });

            if (response?.status === 403) {
                navigate('/login');
                return { success: false, message: 'Your Session is timed out', data: '' };
            }
            
            const result = await response.json();
            setError(result?.error);
            setData(result?.data);
            return { message: result?.message, success: result?.success, data: result?.data, error: result?.error };

        } catch (error) { setError(error?.message); }
        finally { setIsLoading(false); }
    }

    return { data, isLoading, error, deleteData };
};

export default useDeleteData;