import React, { useState } from 'react'
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/state";

import useFormOperations from '../../../hooks/useFormOperations';
import useSnackbar from '../../../hooks/useSnackbar';
import usePutData from '../../../hooks/usePutData';

import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { CustomTextField } from '../../../components/mui/Input'
import { PurpleButton } from '../../../components/mui/button/Button';
import { Link } from 'react-router-dom';

const ChangePassword = () => {
    const { putData } = usePutData();
    const setLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();

    const [showPassword, setShowPassword] = useState({ oldPassword: false, newPassword: false, confirmPassword: false });

    const handleClickShowPassword = (name) => {
        setShowPassword((prevData) => ({ ...prevData, [name]: !showPassword[name] }))
    }

    const onSubmit = async () => {
        if (formData.confirmPassword !== undefined && formData.newPassword !== formData.confirmPassword)
            return handleSnackbarOpen("Confirm Password does not match your new password", 'error');
        setLoading(true);
        const { success, message } = await putData("/api/employee/personal/updatePassword", { oldPassword: formData.oldPassword, newPassword: formData.newPassword });
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
        handleSnackbarOpen('Password Changed Successfully', 'success')
        clearForm();
        setShowPassword({ oldPassword: false, newPassword: false, confirmPassword: false });
    }

    const { formData, handleSubmit, handleChange, clearForm } = useFormOperations({}, onSubmit);

    return (
        <div className="relative flex items-center justify-center p-5 xs:p-10 h-full min-h-[calc(100svh-132px)] tab:min-h-[calc(100svh-5rem)] overflow-hidden">

            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[25%] right-0 top-[20%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[50%] right-0 top-[45%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[10%] right-0 top-[70%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-30 via-regal-purple-50 to-regal-purple-30 h-20 w-[20%] right-[12%] top-[70%] rounded-full" />

            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[25%] left-0 top-[45%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[50%] left-0 top-[70%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[10%] left-0 top-[20%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-30 via-regal-purple-50 to-regal-purple-30 h-20 w-[20%] left-[12%] top-[20%] rounded-full" />

            <div className="flex flex-col p-5 xs:p-10 gap-8 max-w-md w-full bg-white xs:drop-shadow xs:rounded-lg">
                <h1 className="h2-p text-center text-regal-purple-800">Update Password</h1>

                <form className='flex flex-col w-full gap-2' onSubmit={handleSubmit}>
                    <div className='flex flex-col gap-5'>
                        <CustomTextField
                            className='w-full'
                            label="Old Password"
                            name="oldPassword"
                            value={formData.oldPassword ?? ""}
                            type={showPassword.oldPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('oldPassword')} edge="end"
                                >
                                    {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                        />
                        <CustomTextField
                            className='w-full'
                            label="New Password"
                            name="newPassword"
                            value={formData.newPassword ?? ""}
                            type={showPassword.newPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('newPassword')} edge="end"
                                >
                                    {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                        />
                        <CustomTextField
                            className='w-full'
                            label="Confirm Password"
                            name="confirmPassword"
                            value={formData.confirmPassword ?? ""}
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            fullWidth
                            endIcon={
                                <IconButton aria-label="toggle Confirm password visibility"
                                    onClick={() => handleClickShowPassword('confirmPassword')} edge="end"
                                >
                                    {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            } onChange={handleChange}
                            error={formData.confirmPassword !== undefined && formData.confirmPassword !== formData.newPassword}
                            helperText={(formData.confirmPassword !== undefined && formData.confirmPassword !== formData.newPassword) ? 'Confirm Password does not match your new password.' : " "}
                        />
                    </div>

                    <PurpleButton type='submit' text='Set Password' fullWidth />
                    <Link to='/admin/settings' className="text-center text-p text-regal-purple-800 hover:text-regal-purple-950 hover:underline mt-2">Cancel Changes</Link>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword;