import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const ElementaryInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const elementaryFields = [
        { label: "Board Name", value: formData?.exams?.elementaryDetail?.boardName?.status, data: formData?.exams?.elementaryDetail?.boardName?.data, name: 'formData.exams.elementaryDetail.boardName.status' },
        { label: "Roll No", value: formData?.exams?.elementaryDetail?.rollNo?.status, data: formData?.exams?.elementaryDetail?.rollNo?.data, name: 'formData.exams.elementaryDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.exams?.elementaryDetail?.examYear?.status, data: formData?.exams?.elementaryDetail?.examYear?.data, name: 'formData.exams.elementaryDetail.examYear.status' },
        { label: "School Name", value: formData?.exams?.elementaryDetail?.schoolName?.status, data: formData?.exams?.elementaryDetail?.schoolName?.data, name: 'formData.exams.elementaryDetail.schoolName.status' },
        { label: "School City", value: formData?.exams?.elementaryDetail?.city?.status, data: formData?.exams?.elementaryDetail?.city?.data, name: 'formData.exams.elementaryDetail.city.status' },
        { label: "School State", value: formData?.exams?.elementaryDetail?.state?.status, data: formData?.exams?.elementaryDetail?.state?.data, name: 'formData.exams.elementaryDetail.state.status' },
        { label: "School Country", value: formData?.exams?.elementaryDetail?.country?.status, data: formData?.exams?.elementaryDetail?.country?.data, name: 'formData.exams.elementaryDetail.country.status' },
        { label: "Obtained Marks", value: formData?.exams?.elementaryDetail?.obtainedMarks?.status, data: formData?.exams?.elementaryDetail?.obtainedMarks?.data, name: 'formData.exams.elementaryDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.exams?.elementaryDetail?.totalMarks?.status, data: formData?.exams?.elementaryDetail?.totalMarks?.data, name: 'formData.exams.elementaryDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.exams?.elementaryDetail?.percentage?.status, data: formData?.exams?.elementaryDetail?.percentage?.data, name: 'formData.exams.elementaryDetail.percentage.status' },
        { label: "Grade", value: formData?.exams?.elementaryDetail?.grade?.status, data: formData?.exams?.elementaryDetail?.grade?.data, name: 'formData.exams.elementaryDetail.grade.status' },
        { label: "CGPA", value: formData?.exams?.elementaryDetail?.cgpa?.status, data: formData?.exams?.elementaryDetail?.cgpa?.data, name: 'formData.exams.elementaryDetail.cgpa.status' },
    ];

    const subjectFields = [
        { type: 'input', size: 1, name: 'subjectName', label: 'Subject Name' },
        { type: 'input', size: 1, name: 'obtainedMarks', label: 'Obtained Marks' },
        { type: 'input', size: 1, name: 'totalMarks', label: 'Total Marks' }
    ]

    return (
        <React.Fragment>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Elementary Details')} value={masterCheckBoxValue(elementaryFields, checkBoxOptions)} name={elementaryFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Elementary Details</h4>
                </CustomCheckBox>

                {elementaryFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
                )}
            </div>

            {formData?.exams?.elementaryDetail?.subjects?.map((subject, index) => {
                const fields = subjectFields?.map(field => ({
                    value: formData?.exams?.elementaryDetail?.subjects?.[index]?.[field.name]?.status,
                    name: `formData.exams.elementaryDetail.subjects.${[index]}.${field?.name}.status`,
                    label: field?.label, data: subject[field?.name]?.data, loading: isLoading
                }));

                const masterCheckboxValue = { value: masterCheckBoxValue(fields, checkBoxOptions), name: fields };

                return (
                    <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Elementary Detail ${index + 1}`)} value={masterCheckboxValue.value} name={masterCheckboxValue.name} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <h4 className="col-span-full h4-p text-grey"> Elementary - {subject?.subjectName?.data} Details</h4>
                        </CustomCheckBox>

                        {fields?.map((field, fieldIndex) => ((isLoading || field?.data !== undefined) &&
                            <div key={`${index}-${field?.name}`}>
                                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                                    <TextBox label={field?.label} value={field?.data} loading={field?.loading} />
                                </CustomCheckBox>
                            </div>
                        ))}
                    </div>
                );
            })}
        </React.Fragment>
    )
}

export default ElementaryInfo;