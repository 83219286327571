import { useRecoilState } from 'recoil';
import { viewPopupState } from '../recoil/state';
import Logout from '../assets/icons/Logout.svg';

const useViewPopup = () => {
    const [popup, setPopup] = useRecoilState(viewPopupState);
    const { open, icon, heading, message, onClose } = popup;

    const openPopup = (heading, message, icon, onCancel = () => { }) => {
        const iconPaths = {
            logout: Logout,
            success: '/icons/tabs/Success.svg',
            download: '/icons/tabs/Download.svg',
            view: '/icons/tabs/SuccessPurple.svg',
            delete: '/icons/tabs/Delete.svg',
            edit: '/icons/tabs/Edit.svg',
            reject: '/icons/tabs/Failed.svg'
        };

        const iconPath = iconPaths[icon] || null;
        setPopup({
            open: true,
            icon: iconPath,
            heading: heading,
            message: message,
            onClose: () => { onCancel(); setPopup({ ...popup, open: false }) },
        });
    };

    const closePopup = () => setPopup({ open: false });

    return {
        open,
        icon,
        message,
        heading,
        onClose,
        openPopup,
        closePopup
    };
};

export default useViewPopup;