export const deleteKeys = (obj, selectKeys, inputKeys) => {
    const modifyKey = (o, keys, action) => {
        const [key, ...rest] = keys;
        if (!o || !(key in o)) return; // Check if 'o' is undefined or key doesn't exist

        if (rest.length === 0) {
            if (action === 'delete') delete o[key];
            else if (action === 'update') o[key] = '';
        } else {
            modifyKey(o[key], rest, action);
        }
    };

    const traverseAndModify = (o, keyPath, action) => {
        const keys = keyPath.split(/[\.\[\]]/).filter(k => k);
        modifyKey(o, keys, action);
    };

    selectKeys?.forEach(key => traverseAndModify(obj, key, 'delete'));
    inputKeys?.forEach(key => traverseAndModify(obj, key, 'update'));

    return obj;
};

export const findUpdatedKeys = (original, updated) => {
    let result = {};

    for (let key in updated) {
        // Check if the key exists in updated JSON but not in original JSON, or it's a new key
        if (!original.hasOwnProperty(key)) {
            result[key] = updated[key];
        }
        // If key exists in both original and updated
        else {
            // If the value is an object (not null), and not an array, recurse into it
            if (typeof updated[key] === 'object' && updated[key] !== null) {
                if (Array.isArray(updated[key])) {
                    // For arrays, compare objects inside them deeply and include only changes
                    const arrayChanges = compareArrayObjects(original[key], updated[key]);
                    if (arrayChanges && arrayChanges.length > 0) {
                        result[key] = arrayChanges; // Include only if there are changes in the array
                    }
                } else {
                    // For objects, do a deep comparison
                    const nestedChanges = findUpdatedKeys(original[key], updated[key]);
                    // Only include the key if there are changes in the nested object
                    if (nestedChanges && Object.keys(nestedChanges).length > 0) {
                        result[key] = nestedChanges;
                    }
                }
            }
            // If it's a non-object value and it has changed, or if the value is undefined in the original
            else if (updated[key] !== original[key] || original[key] === undefined) {
                result[key] = updated[key];
            }
        }
    }

    return Object.keys(result).length > 0 ? result : undefined;
};

// Helper function to compare arrays deeply for objects inside them
export const compareArrayObjects = (arr1 = [], arr2 = []) => {
    let result = [];

    for (let i = 0; i < arr2.length; i++) {
        if (typeof arr2[i] === 'object' && arr2[i] !== null && !Array.isArray(arr2[i])) {
            // Deep compare objects inside the array
            const objectChanges = findUpdatedKeys(arr1[i], arr2[i]);
            if (objectChanges && Object.keys(objectChanges).length > 0) {
                result[i] = objectChanges;
            }
        } else if (arr1[i] !== arr2[i]) {
            // If primitive values differ, include them directly
            result[i] = arr2[i];
        }
    }

    return result.length > 0 ? result : undefined;
};

// Helper function to compare arrays deeply
export const arraysAreEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (typeof arr1[i] === 'object' && arr1[i] !== null) {
            if (Array.isArray(arr1[i])) {
                // Compare arrays inside the arrays
                if (!arraysAreEqual(arr1[i], arr2[i])) {
                    return false;
                }
            } else {
                // Compare objects inside the arrays
                if (!objectDeepEqual(arr1[i], arr2[i])) {
                    return false;
                }
            }
        } else if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
};

// Helper function to compare objects deeply
export const objectDeepEqual = (obj1 = {}, obj2 = {}) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }
    for (let key in obj1) {
        if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            if (Array.isArray(obj1[key])) {
                if (!arraysAreEqual(obj1[key], obj2[key])) {
                    return false;
                }
            } else {
                if (!objectDeepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
};