import React from "react";
import { useNavigate } from "react-router-dom"
import { KeyboardBackspace } from '@mui/icons-material';

import useGetData from "../../../../hooks/useGetData";

import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import CustomTable from "../../../../components/mui/Table";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";
import RenderStats from "../../../../utils/RenderStats";
import PieChartWrapper from "../../../../components/graphsWrapper/PieChartWrapper";

const InProgressApplicants = () => {
    const navigate = useNavigate();
    const { data: programStatsData, isLoading: programStatsLoading } = useGetData('/api/employee/admission/applicants/stats/getInprogressApplicantsProgramsCount');
    const { data: roundStatsData, isLoading: roundStatsLoading } = useGetData('/api/employee/admission/applicants/stats/getInprogressApplicantsRoundTypeCount');
    const { data: defaultData, isLoading } = useGetData('/api/employee/admission/applicants/getInprogressApplicants');

    const customOperations = () => {
        return <SquareIconButton title='Applicants' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<KeyboardBackspace sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={() => navigate('/admin/student/applicants')} />
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', type: 'text' },
        { id: 'roundType', label: 'Round Type', type: 'text' },
        { id: 'roundId', label: 'Round ID', type: 'text' },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={StudentIcon} data={programStatsData} statsLoading={programStatsLoading} />
            <div className="grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3">
                <PieChartWrapper heading='Student Round Type Distribution' isLoading={roundStatsLoading}
                    data={Object.keys(roundStatsData ?? {})?.map((round) => ({ label: round, value: roundStatsData[round] }))} />
            </div>

            <CustomTable
                heading="All Inprogress Applicants"
                text="Inprogress Applicants data"
                isLoading={isLoading}
                columns={columns} rows={defaultData}
                sortParameters={['name', 'admissionYear', 'programKey', 'roundType', 'roundId']}
                filterOptions={filterOptions}
                activeOperations
                extraOperations={customOperations}
            />
        </div>
    )
}

export default InProgressApplicants;