import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSearchParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

const BoxTabSmall = ({ tabs, className, onClick, disabled }) => {
    const isSmallScreen = useMediaQuery('(min-width:500px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('type')

    const changeTab = (_event, newValue) => {
        if (disabled) return;
        const value = tabs[newValue].label;
        onClick && onClick(value);
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', value);
            return Object.fromEntries(newParams.entries());
        });
    };

    return (
        <div className={`flex bg-white rounded-[5px] ${className} border-[1.5px] border-[#C4C4C4]`} >
            <Tabs className='h-16 xs:h-12 flex justify-between items-center w-full'
                value={tabs[activeTab]?.label} onChange={changeTab}
            >
                {tabs && tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon}
                        iconPosition={isSmallScreen ? 'start' : 'top'}
                        disableRipple
                        className='flex-grow font-poppins leading-6'
                        sx={{
                            color: activeTab === tab.label ? '#5B21B6' : '#9CA3AF',
                            fontWeight: activeTab === tab.label ? 'bold' : 'normal',
                            textTransform: "none"
                        }} disabled={activeTab !== tab.label && disabled}
                    />
                ))}
            </Tabs>
        </div>
    );
}

export default BoxTabSmall;