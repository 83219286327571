import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom"
import { CloudDownload, PendingOutlined, CancelOutlined, ChangeCircleOutlined, VerifiedOutlined, ScreenRotationAlt, PersonOff, PersonAdd, MoreHoriz } from '@mui/icons-material';

import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';
import usePutData from "../../../../hooks/usePutData";
import usePostData from "../../../../hooks/usePostData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DocumentVerified from '../../../../assets/icons/admission/DocumentVerified.svg';
import DocumentPending from '../../../../assets/icons/admission/DocumentPending.svg';
import DocumentRejected from '../../../../assets/icons/admission/DocumentRejected.svg';
import DocumentDeclined from '../../../../assets/icons/admission/DocumentDeclined.svg';
import PromoteIcon from "../../../../assets/icons/PromoteIcon";

import { dateFormat } from "../../../../utils/dateFormat";
import CustomTable from "../../../../components/mui/Table";
import BlackToolTip from "../../../../components/mui/BlackToolTip";
import AdmissionTile from "../../../../components/tiles/AdmissionTile";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";
import PromoteRecruitDialog from "../../../../components/popups/localPopup/verification/PromoteRecruitDialog";

const RecruitsVerification = () => {
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/recruits/stats/getStats');
    const { data: defaultData, isLoading, updateData } = useGetData('/api/employee/hr/recruits/getRecruits');
    const { putData } = usePutData();
    const { postData } = usePostData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();

    const setLoading = useSetRecoilState(loadingState);

    const [promotePopup, setPromotePopup] = useState({ isOpen: false, _id: '' });

    const employeeData = defaultData?.map(e => ({ name: `${e.firstName} ${e.lastName}`, ...e }));
    
    const onAddRecruit = async (formData) => {
        setLoading(true);
        const { success, message, data } = await postData('/api/employee/hr/recruits/addRecruit', formData);
        setLoading(false);
        
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        navigate('/admin/employee/recruits/addRecruit/' + data.id + '?step=Personal+Details');
    }

    const handleAddRecruit = () => openPopup('Add Recruit', 'Enter the new recruit Email ID', 'edit', onAddRecruit, undefined, 'input', addRecruitFields);

    const handleRow = (event, value) => openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/employee/recruits/profile/" + value + '?type=Personal'));

    const handleOnlineVerification = (event, value) => openPopup('Review Details', 'Are you sure you want to view further details of Online Verification?', 'view', () => navigate("/admin/employee/recruits/onlineVerify/" + value));

    const handleOfflineVerification = (event, value) => openPopup('Verify Documents Offline', 'Are you sure you want to view further details of Offline Verification?', 'view', () => navigate("/admin/employee/recruits/offlineVerify/" + value));

    const handlePromote = (event, id) => {
        const user = defaultData?.find(user => user._id === id) || {};
        if (user?.promoted) return;
        if (!(user?.onlineVerificationStatus === 'Verified' || user?.offlineVerificationStatus === 'Verified'))
            return handleSnackbarOpen('Verification required: User must be verified either online or offline.', 'warning');
        setPromotePopup({ isOpen: true, _id: id });
    };

    const onPromote = async (data) => {
        setPromotePopup({ isOpen: false, _id: '' });
        const user = defaultData?.find(user => user?._id === data?._id) || {};

        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/recruits/updateRecruitPromotion/${data?._id}`, data);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        if (user?.onlineVerificationStatus === 'Verified' && user?.offlineVerificationStatus === 'Verified') updateData(prevState => { return prevState.filter(user => user._id !== data?._id) });

        updateData(prevState => prevState.map(user => user._id === data._id ? { ...user, promoted: true, promotedTimeStamp: new Date() } : user));
        handleSnackbarOpen(`Recruit successfully promoted!`, 'success');
    }

    const tileData = [
        { icon: DocumentVerified, color: "#D1FADF", heading: "Online Verified", count: statsData?.online?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Online Pending", count: statsData?.online?.Pending },
        { icon: DocumentRejected, color: "#D6D0F7", heading: "Online Rejected", count: statsData?.online?.Rejected },
        { icon: DocumentDeclined, color: "#ffdfdf", heading: "Blocked Recruit", count: statsData?.online?.Declined },
        { icon: DocumentVerified, color: "#D1FADF", heading: "Offline Verified", count: statsData?.offline?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Offline Pending", count: statsData?.offline?.Pending },
    ]

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'departments', label: 'Department', minWidth: 180, type: 'array' },
        { id: 'joiningDate', label: 'Joining Date', minWidth: 140, type: 'date' },
        {
            id: 'onlineVerificationStatus', label: 'Online Verification', minWidth: 100, type: 'iconButton',
            icon: {
                Verified: <VerifiedOutlined className='text-green' />,
                Pending: <PendingOutlined className='text-amber-500' />,
                Rejected: <ChangeCircleOutlined className="text-purple" />,
                Declined: <CancelOutlined className="text-signal" />
            },
            align: 'center', onClick: handleOnlineVerification,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {(row?.reSubmitted && row?.onlineVerificationStatus === 'Pending') ?
                        <BlackToolTip title="Re-submitted">
                            <ScreenRotationAlt className="text-purple" />
                        </BlackToolTip>
                        : row?.onlineVerificationStatus === 'Pending' ?
                            <PendingOutlined className="text-amber-500" />
                            : <>
                                {row?.onlineVerificationStatus === 'Verified' ?
                                    <VerifiedOutlined className="text-green" />
                                    : <CancelOutlined className="text-signal" />
                                }
                                <div className="text-xs">{dateFormat(row.onlineVerificationTimeStamp)}</div>
                                <div className="text-s text-xs text-signal">{row.onlineVerificationAuthority}</div>
                            </>
                    }
                </div>
            ), customSort: (a, b) => {
                const statusComparison = a.onlineVerificationStatus.localeCompare(b.onlineVerificationStatus);

                if (statusComparison !== 0) return statusComparison;
                if (a.onlineVerificationStatus === 'Verified' && b.onlineVerificationStatus === 'Verified') return new Date(a.onlineVerificationTimeStamp) - new Date(b.onlineVerificationTimeStamp);
                if (a.onlineVerificationStatus === 'Pending' && b.onlineVerificationStatus === 'Pending') return (a.reSubmitted ? 1 : 0) - (b.reSubmitted ? 1 : 0);

                return 0;
            }
        },
        {
            id: 'offlineVerificationStatus', label: 'Offline Verification', minWidth: 100, type: 'iconButton',
            icon: {
                Verified: <VerifiedOutlined className='text-green' />,
                Pending: <PendingOutlined className='text-amber-500' />,
                Rejected: <ChangeCircleOutlined className="text-purple" />,
                Declined: <CancelOutlined className="text-signal" />
            },
            align: 'center', onClick: handleOfflineVerification,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {row?.offlineVerificationStatus === 'Verified' ? <>
                        <VerifiedOutlined className="text-green" />
                        <div className="text-xs">{dateFormat(row.offlineVerificationTimeStamp)}</div>
                        <div className="text-s text-xs text-signal">{row.offlineVerificationAuthority}</div>
                    </> : <PendingOutlined className="text-amber-500" />
                    }
                </div>
            ), customSort: (a, b) => {
                const statusComparison = a.offlineVerificationStatus.localeCompare(b.offlineVerificationStatus);

                if (statusComparison !== 0) return statusComparison;
                if (a.offlineVerificationStatus === 'Verified') return new Date(a.offlineVerificationTimeStamp) - new Date(b.offlineVerificationTimeStamp);

                return 0;
            }
        },
        {
            id: 'promoted', label: 'Transfer to Current', type: 'iconButton',
            align: 'center', onClick: handlePromote,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {row?.promoted ? <>
                        <VerifiedOutlined className="text-green" />
                        <div className="text-xs">{dateFormat(row?.promotedTimeStamp)}</div>
                        <div className="text-s text-xs text-signal">{row?.promotedAuthority}</div>
                    </> : <PromoteIcon className="h-6 w-6 fill-purple-700" />}
                </div>
            ), customSort: (a, b) => {
                const promotedComparison = (a.promoted === b.promoted) ? 0 : (a.promoted ? -1 : 1);

                if (promotedComparison !== 0) return promotedComparison;
                if (a.promoted && b.promoted) return new Date(b.promotedTimeStamp) - new Date(a.promotedTimeStamp);

                return 0;
            }
        }
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    const addRecruitFields = [
        {
            type: 'input',
            inputType: 'email',
            label: 'Email ID',
            name: 'emailId',
            placeholder: 'abc@example.com'
        }
    ]

    const buttonData = [
        { title: 'Inprogress Recruits', icon: <MoreHoriz sx={{ color: "orange", fontSize: 24 }} />, onClick: () => navigate('/admin/employee/recruits/inProgress') },
        { title: 'Add Recruit', icon: <PersonAdd sx={{ color: "#512C9D", fontSize: 24 }} />, onClick: handleAddRecruit },
        { title: 'Blocked Recruits', icon: <PersonOff sx={{ color: "red", fontSize: 24 }} />, onClick: () => navigate('/admin/employee/recruits/blocked') },
        { title: 'Download Excel', icon: <CloudDownload sx={{ color: "green", fontSize: 24 }} />, onClick: () => { } },
    ];

    const customOperations = () => {
        return <div className="grid grid-cols-2 xs:grid-cols-4 gap-4">
            {buttonData.map(({ title, icon, onClick }, index) => (
                <SquareIconButton
                    key={index}
                    title={title}
                    sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }}
                    icon={icon}
                    onClick={onClick}
                />
            ))}
        </div>
    }

    return (
        <div className="flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]">
            <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {tileData.map((tile, index) => (
                    <AdmissionTile key={index} icon={tile.icon} color={tile.color}
                        heading={tile.heading} count={tile.count} isLoading={statsLoading} />
                ))}
            </div>

            <CustomTable
                heading="All Recruits" text="Recruits data"
                isLoading={isLoading}
                rows={employeeData} columns={columns}
                sortParameters={['name', 'department', 'joiningDate', 'departments', 'onlineVerificationStatus', 'offlineVerificationStatus', 'promoted']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations extraOperations={customOperations}
            />

            {promotePopup?.isOpen && <PromoteRecruitDialog id={promotePopup._id} onClose={() => setPromotePopup({ isOpen: false, _id: '' })} onConfirm={onPromote} />}
        </div>
    )
}

export default RecruitsVerification;