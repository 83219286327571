import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterAltOff } from '@mui/icons-material';

import useGetData from '../../../../hooks/useGetData';

import { pastYearsArray } from '../../../../utils/dateFormat';
import { CustomAsyncSelect } from '../../../../components/mui/Input';
import OrganizationStats from './OrganizationStats';

const FilterComponent = ({ organizationStats, isLoading }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const divisionId = searchParams.get('division_id');
    const departmentId = searchParams.get('department_id');

    const handleChange = useCallback((name, value) => {
        const newParams = new URLSearchParams(searchParams);

        if (value) newParams.set(name, value);
        else newParams.delete(name);

        // Implement hierarchy logic
        if (name === 'division_id') {
            newParams.delete('department_id');
            newParams.delete('program_id');
        }
        else if (name === 'department_id') newParams.delete('program_id');

        setSearchParams(newParams);
    }, [searchParams, setSearchParams]);

    const { data: divisionData, isLoading: divisionDataLoading } = useGetData('/api/organization/getDivisions');
    const { data: departmentData, isLoading: departmentDataLoading } = useGetData(`/api/organization/getDepartments?division_id=${divisionId}`, null, null, !!divisionId);
    const { data: programData, isLoading: programDataLoading } = useGetData(`/api/organization/getPrograms?department_id=${departmentId}`, null, null, !!departmentId);

    const divisionOptions = divisionData?.map((division) => ({ label: division?.name, value: division?._id })) || [];
    const departmentOptions = departmentData?.map((department) => ({ label: department?.name, value: department?._id })) || [];
    const programOptions = programData?.map((program) => ({ label: program?.name, value: program?._id })) || [];

    const clearFilter = useCallback(() => {
        const newParams = new URLSearchParams(searchParams);

        newParams.delete('division_id');
        newParams.delete('department_id');
        newParams.delete('program_id');
        newParams.delete('year');

        setSearchParams(newParams);
    }, [searchParams, setSearchParams]);

    return (
        <div className='flex flex-col gap-5'>
            <div className='grid grid-cols-10 xs:grid-cols-20 gap-4 xs:gap-5 p-5 border drop-shadow bg-white rounded-[4px]'>
                <CustomAsyncSelect
                    className='col-span-full xs:col-span-10 lg:col-span-5'
                    label='Division'
                    name='division_id'
                    value={searchParams.get('division_id') || ''}
                    onChange={(e) => handleChange('division_id', e.target.value)}
                    options={divisionOptions}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='col-span-full xs:col-span-10 lg:col-span-5'
                />

                <CustomAsyncSelect
                    className='col-span-full xs:col-span-10 lg:col-span-5'
                    label='Department'
                    name='department_id'
                    value={searchParams.get('department_id') || ''}
                    onChange={(e) => handleChange('department_id', e.target.value)}
                    options={departmentOptions}
                    noOptionsMessage='Select Division first'
                    loading={departmentDataLoading}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='col-span-full xs:col-span-10 lg:col-span-5'
                />

                <CustomAsyncSelect
                    className='col-span-full xs:col-span-12 lg:col-span-5'
                    label='Program'
                    name='program_id'
                    value={searchParams.get('program_id') || ''}
                    onChange={(e) => handleChange('program_id', e.target.value)}
                    options={programOptions}
                    noOptionsMessage='Select Department first'
                    loading={programDataLoading}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='col-span-full xs:col-span-12 lg:col-span-5'
                />

                <CustomAsyncSelect
                    className='col-span-8 xs:col-span-6 lg:col-span-4'
                    label='Academic Year'
                    name='year'
                    value={searchParams.get('year') || ''}
                    onChange={(e) => handleChange('year', e.target.value)}
                    options={pastYearsArray()}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='col-span-8 xs:col-span-6 lg:col-span-4'
                />

                <div onClick={clearFilter} sx={{ backgroundColor: 'transparent', borderRadius: 1, border: 1, borderColor: '#c4c4c4', '&:hover': { backgroundColor: '#FAF5FF' } }} className='self-center justify-self-center flex items-center justify-center h-full w-full col-span-2 lg:col-span-1 border-[1px] border-[#c4c4c4] hover:bg-secondary rounded-[4px] cursor-pointer'>
                    <FilterAltOff sx={{ color: "red", fontSize: 20 }} />
                </div>
            </div>

            <OrganizationStats isLoading={isLoading} organizationStats={organizationStats} />
        </div>
    );
};

export default FilterComponent;