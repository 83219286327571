import React from 'react';
import { useMediaQuery } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const colorScheme = ['#512C9D', '#7954B7', '#9A7CD5', '#B6A6EA', '#D7CFFA'];

const BarChartComponent = ({ data, width = "100%", height = 300, margin = { top: 20, right: 15, left: -20, bottom: 5 }, xAxisKey = "label" }) => {
  const xs = useMediaQuery('(max-width:500px)');

  if (!data?.length) return <div>Please provide valid data</div>

  const processedData = data.map(item => {
    const { label, ...rest } = item;
    const total = Object.values(rest).reduce((sum, value) => sum + value, 0);
    const sortedEntries = Object.entries(rest).sort((a, b) => b[1] - a[1]);

    return { label, total, parts: sortedEntries.map(([key, value]) => ({ key, value })) };
  });

  const CustomBar = (props) => {
    const { x, y, width, height, payload } = props;
    return (
      <g>
        {payload.parts.map((part, index, array) => {
          const startPercentage = array.slice(0, index).reduce((sum, p) => sum + p.value, 0) / payload.total;
          const partHeight = (part.value / payload.total) * height;
          return (
            <rect
              key={`${payload.label}-${part.key}`}
              x={x}
              y={y + height - (startPercentage * height) - partHeight}
              width={width}
              height={partHeight}
              fill={colorScheme[index % colorScheme.length]}
            />
          );
        })}
      </g>
    );
  };

  return (
    <div className="w-full h-full min-h-fit">
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          data={processedData}
          margin={{ ...margin }}
          barSize={xs ? 16 : 32}
        >
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="flex flex-col bg-white p-4 border rounded shadow gap-0.5">
                    <span className="font-bold text-gray-800">{label}</span>
                    {payload[0].payload.parts.map((part, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <div
                          className="size-3 rounded-full"
                          style={{ backgroundColor: colorScheme[index % colorScheme.length] }}
                        />
                        <span className="text-dark tracking-wide">
                          {part.key}: {part.value}
                        </span>
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            }}
          />
          <Bar dataKey="total" shape={<CustomBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;