import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { loadingState } from '../recoil/state';
import useSnackbar from './useSnackbar';

const HOST_URL = process.env.REACT_APP_HOST;
// const HOST_URL = 'http://localhost:4000';

const useFetchData = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbar();

  const fetchData = async (url, options = {}) => {
    setIsLoading(true);
    setError(null);

    const token = sessionStorage.getItem('auth_token');

    try {
      const response = await fetch(HOST_URL + url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        ...options,
      });

      if (response?.status === 403) {
        handleSnackbarOpen('Your Session is timed out', 'warning')
        return navigate('/login');
      }

      const result = await response.json();
      if (!result?.success) return setError(result?.error);

      setData(result?.data);
      return result?.data;
    }
    
    catch (error) { setError(error?.message); }
    finally { setIsLoading(false); }
  };

  return { data, isLoading, error, fetchData };
};

export default useFetchData;