import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const UndergraduateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const ugFields = [
        { label: 'Degree', value: formData?.exams?.ugDetail?.degree?.status, data: formData?.exams?.ugDetail?.degree?.data, name: 'formData.exams.ugDetail.degree.status' },
        { label: 'Major', value: formData?.exams?.ugDetail?.major?.status, data: formData?.exams?.ugDetail?.major?.data, name: 'formData.exams.ugDetail.major.status' },
        { label: 'Degree Type', value: formData?.exams?.ugDetail?.degreeType?.status, data: formData?.exams?.ugDetail?.degreeType?.data, name: 'formData.exams.ugDetail.degreeType.status' },
        { label: 'Graduation Batch Year', value: formData?.exams?.ugDetail?.passingYear?.status, data: formData?.exams?.ugDetail?.passingYear?.data, name: 'formData.exams.ugDetail.passingYear.status' },
        { label: 'College Name', value: formData?.exams?.ugDetail?.collegeName?.status, data: formData?.exams?.ugDetail?.collegeName?.data, name: 'formData.exams.ugDetail.collegeName.status' },
        { label: 'College Country', value: formData?.exams?.ugDetail?.country?.status, data: formData?.exams?.ugDetail?.country?.data, name: 'formData.exams.ugDetail.country.status' },
        { label: 'College State', value: formData?.exams?.ugDetail?.state?.status, data: formData?.exams?.ugDetail?.state?.data, name: 'formData.exams.ugDetail.state.status' },
        { label: 'College City', value: formData?.exams?.ugDetail?.city?.status, data: formData?.exams?.ugDetail?.city?.data, name: 'formData.exams.ugDetail.city.status' },
        { label: 'Percentage', value: formData?.exams?.ugDetail?.percentage?.status, data: formData?.exams?.ugDetail?.percentage?.data, name: 'formData.exams.ugDetail.percentage.status' },
        { label: 'Grade', value: formData?.exams?.ugDetail?.grade?.status, data: formData?.exams?.ugDetail?.grade?.data, name: 'formData.exams.ugDetail.grade.status' },
        { label: 'CGPA', value: formData?.exams?.ugDetail?.cgpa?.status, data: formData?.exams?.ugDetail?.cgpa?.data, name: 'formData.exams.ugDetail.cgpa.status' }
    ];

    return (
        <div className={`grid gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Under Graduation Details')} value={masterCheckBoxValue(ugFields, checkBoxOptions)} name={ugFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Under Graduation Details</h4>
            </CustomCheckBox>
            
            {ugFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
            )}
        </div>
    )
}

export default UndergraduateInfo;