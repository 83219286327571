import React from 'react'
import { CustomCheckBox } from '../../../../../../components/mui/Input';
import TextBox from '../../../../../../components/mui/text/TextBox';

const AddressInfo = ({ data }) => {

  const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

  const residentialAddressInfoFields = [
    { label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address?.status, data: formData?.personalInfo?.residentialAddress?.address?.data, name: 'formData.personalInfo.residentialAddress.address.status' },
    { label: "Town", value: formData?.personalInfo?.residentialAddress?.town?.status, data: formData?.personalInfo?.residentialAddress?.town?.data, name: 'formData.personalInfo.residentialAddress.town.status' },
    { label: "City", value: formData?.personalInfo?.residentialAddress?.city?.status, data: formData?.personalInfo?.residentialAddress?.city?.data, name: 'formData.personalInfo.residentialAddress.city.status' },
    { label: "State", value: formData?.personalInfo?.residentialAddress?.state?.status, data: formData?.personalInfo?.residentialAddress?.state?.data, name: 'formData.personalInfo.residentialAddress.state.status' },
    { label: "Country", value: formData?.personalInfo?.residentialAddress?.country?.status, data: formData?.personalInfo?.residentialAddress?.country?.data, name: 'formData.personalInfo.residentialAddress.country.status' },
    { label: "Pin Code", value: formData?.personalInfo?.residentialAddress?.pincode?.status, data: formData?.personalInfo?.residentialAddress?.pincode?.data, name: 'formData.personalInfo.residentialAddress.pincode.status' },
  ];

  const permanentAddressInfoFields = [
    { label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address?.status, data: formData?.personalInfo?.permanentAddress?.address?.data, name: 'formData.personalInfo.permanentAddress.address.status' },
    { label: "Town", value: formData?.personalInfo?.permanentAddress?.town?.status, data: formData?.personalInfo?.permanentAddress?.town?.data, name: 'formData.personalInfo.permanentAddress.town.status' },
    { label: "City", value: formData?.personalInfo?.permanentAddress?.city?.status, data: formData?.personalInfo?.permanentAddress?.city?.data, name: 'formData.personalInfo.permanentAddress.city.status' },
    { label: "State", value: formData?.personalInfo?.permanentAddress?.state?.status, data: formData?.personalInfo?.permanentAddress?.state?.data, name: 'formData.personalInfo.permanentAddress.state.status' },
    { label: "Country", value: formData?.personalInfo?.permanentAddress?.country?.status, data: formData?.personalInfo?.permanentAddress?.country?.data, name: 'formData.personalInfo.permanentAddress.country.status' },
    { label: "Pin Code", value: formData?.personalInfo?.permanentAddress?.pincode?.status, data: formData?.personalInfo?.permanentAddress?.pincode?.data, name: 'formData.personalInfo.permanentAddress.pincode.status' }
  ];

  return (
    <React.Fragment>
      <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Residential Address Details')} value={masterCheckBoxValue(residentialAddressInfoFields, checkBoxOptions)} name={residentialAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
          <h4 className="col-span-full h4-p text-grey">Residential Address Details</h4>
        </CustomCheckBox>

        {residentialAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
          <TextBox label={field?.label} value={field?.data} loading={isLoading} />
        </CustomCheckBox>)}
      </div>

      <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
        <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Permanent Address Details')} value={masterCheckBoxValue(permanentAddressInfoFields, checkBoxOptions)} name={permanentAddressInfoFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
          <h4 className="col-span-full h4-p text-grey">Permanent Address Details</h4>
        </CustomCheckBox>

        {permanentAddressInfoFields?.map((field, index) => (isLoading || field?.data !== undefined) && <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
          <TextBox label={field?.label} value={field?.data} loading={isLoading} />
        </CustomCheckBox>)}
      </div>
    </React.Fragment>
  )
}

export default AddressInfo;