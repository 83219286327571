import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const OptionalExamsInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const optionalExamsFields = Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.map((round, index) => [
        { label: 'Exam Year', value: formData?.exams?.optionalExamsDetail?.[round]?.examYear?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.examYear?.data, name: `formData.exams.optionalExamsDetail.${round}.examYear.status` },
        { label: 'Roll No', value: formData?.exams?.optionalExamsDetail?.[round]?.rollNo?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.rollNo?.data, name: `formData.exams.optionalExamsDetail.${round}.rollNo.status` },
        { label: 'Obtained Marks', value: formData?.exams?.optionalExamsDetail?.[round]?.obtainedMarks?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.obtainedMarks?.data, name: `formData.exams.optionalExamsDetail.${round}.obtainedMarks.status` },
        { label: 'Total Marks', value: formData?.exams?.optionalExamsDetail?.[round]?.totalMarks?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.totalMarks?.data, name: `formData.exams.optionalExamsDetail.${round}.totalMarks.status` },
        { label: 'Percentage', value: formData?.exams?.optionalExamsDetail?.[round]?.percentage?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.percentage?.data, name: `formData.exams.optionalExamsDetail.${round}.percentage.status` },
        { label: 'Percentile', value: formData?.exams?.optionalExamsDetail?.[round]?.percentile?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.percentile?.data, name: `formData.exams.optionalExamsDetail.${round}.percentile.status` },
        { label: 'National Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.nationalRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.nationalRank?.data, name: `formData.exams.optionalExamsDetail.${round}.nationalRank.status` },
        { label: 'State Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.stateRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.stateRank?.data, name: `formData.exams.optionalExamsDetail.${round}.stateRank.status` },
        { label: 'Caste Rank', value: formData?.exams?.optionalExamsDetail?.[round]?.casteRank?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.casteRank?.data, name: `formData.exams.optionalExamsDetail.${round}.casteRank.status` },
        { label: 'Grade', value: formData?.exams?.optionalExamsDetail?.[round]?.grade?.status, data: formData?.exams?.optionalExamsDetail?.[round]?.grade?.data, name: `formData.exams.optionalExamsDetail.${round}.grade.status` }
    ]);

    return (
        optionalExamsFields?.map((fields, index) => (
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={index}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `${Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.[index]} Details`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">{Object?.keys(formData?.exams?.optionalExamsDetail ?? {})?.[index]} Details</h4>
                </CustomCheckBox>
                {fields?.map((field, i) => (isLoading || field?.data !== undefined) &&
                    <div key={`${index}-${i}`}>
                        <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                        </CustomCheckBox>
                    </div>
                )}
            </div>
        ))
    )
}

export default OptionalExamsInfo;