import React, { useEffect, useRef, useState } from 'react'
import Lottie from "lottie-react";
import { InfoOutlined } from '@mui/icons-material';

import useGetData from '../../../../hooks/useGetData';
import usePutData from '../../../../hooks/usePutData';
import useSnackbar from '../../../../hooks/useSnackbar';
import usePopup from '../../../../hooks/usePopup';

import BottomTile from './BottomTile';
import Pending from "../../../../assets/lotties/Pending.json";
import renderFormField from '../../../../utils/renderFormField';
import { pastYearsArray } from '../../../../utils/dateFormat';
import { generateGeoOptions } from "../../../../utils/geoLocationOptions";
import { boards, degreeTypes, diplomaMajors, diplomas, postgraduateMajors, postGraduation, undergraduateMajors, underGraduation } from '../../../../constants/education';
import { CustomAsyncSelect, CustomTextField } from '../../../../components/mui/Input';
import BlackToolTip from '../../../../components/mui/BlackToolTip';

const AcademicsDetails = ({ id, formData, verifiedValues, formOperations, isLoading, steps }) => {
  const { handleNestedChange, handleNestedSelectImmediateChange, handleSubmit, clearForm } = formOperations;

  const { data: examData, isLoading: examDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getExams/${id}` : "/api/admission/student/getExams");
  const { data: documentData, isLoading: documentDataLoading } = useGetData(id ? `/api/employee/admission/applicants/getOptionalCommonDocuments/${id}` : "/api/admission/student/getOptionalCommonDocuments", null, null, !!examData);
  const { putData, isLoading: updateDocumentLoading } = usePutData();
  const { handleSnackbarOpen } = useSnackbar();
  const { openPopup } = usePopup();

  const [subjectsLength, setSubjectsLength] = useState({
    elementaryDetailSubjectLength: formData?.exams?.elementaryDetail?.subjects?.length ?? 5,
    intermediateDetailSubjectLength: formData?.exams?.intermediateDetail?.subjects?.length ?? 5,
  })
  const initializedRef = useRef(false);
  const prevFormDataRef = useRef();

  useEffect(() => {
    if (!initializedRef.current && formData && prevFormDataRef.current === undefined) {
      setSubjectsLength({
        elementaryDetailSubjectLength: formData.exams?.elementaryDetail?.subjects?.length ?? 5,
        intermediateDetailSubjectLength: formData.exams?.intermediateDetail?.subjects?.length ?? 5,
      });
      initializedRef.current = true;
    }
    prevFormDataRef.current = formData;
  }, [formData]);

  const { countryOptions, stateOptions: elementaryState } = generateGeoOptions(formData?.exams?.elementaryDetail?.country, formData?.exams?.elementaryDetail?.state)
  const { stateOptions: lateralEntryState } = generateGeoOptions(formData?.exams?.lateralEntryDetail?.country, formData?.exams?.lateralEntryDetail?.state)
  const { stateOptions: intermediateState } = generateGeoOptions(formData?.exams?.intermediateDetail?.country, formData?.exams?.intermediateDetail?.state)
  const { stateOptions: ugState } = generateGeoOptions(formData?.exams?.ugDetail?.country, formData?.exams?.ugDetail?.state)
  const { stateOptions: pgState } = generateGeoOptions(formData?.exams?.pgDetail?.country, formData?.exams?.pgDetail?.state)

  const boardOptions = boards.map(board => ({ value: board, label: board }));
  const degreeTypeOptions = degreeTypes.map(type => ({ value: type, label: type }));
  const diplomaOptions = diplomas.map(diploma => ({ value: diploma, label: diploma }));
  const diplomaMajorOptions = diplomaMajors.map(diploma => ({ value: diploma, label: diploma }));
  const ugOptions = underGraduation.map(ug => ({ value: ug, label: ug }));
  const ugMajorOptions = undergraduateMajors.map(ug => ({ value: ug, label: ug }));
  const pgOptions = postGraduation.map(pg => ({ value: pg, label: pg }));
  const pgMajorOptions = postgraduateMajors.map(pg => ({ value: pg, label: pg }));

  // const selectedOptionalExams = formData?.exams?.otherExams?.optional ? (Object?.keys(formData?.exams?.otherExams?.optional)) : [];

  const elementaryFields = [
    { type: 'select', size: 'full', name: 'formData.exams.elementaryDetail.boardName', label: 'Board Name', options: boardOptions, value: formData?.exams?.elementaryDetail?.boardName, skeletonClassName: 'col-span-full' },
    { type: 'input', name: 'formData.exams.elementaryDetail.rollNo', label: 'Roll No', value: formData?.exams?.elementaryDetail?.rollNo },
    { type: 'select', name: 'formData.exams.elementaryDetail.examYear', label: 'Exam Year', options: pastYearsArray(), value: formData?.exams?.elementaryDetail?.examYear },
    { type: 'input', name: 'formData.exams.elementaryDetail.schoolName', label: 'School Name', value: formData?.exams?.elementaryDetail?.schoolName },
    { type: 'select', name: 'formData.exams.elementaryDetail.country', label: 'School Country', value: formData?.exams?.elementaryDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.exams.elementaryDetail.state', label: 'School State', value: formData?.exams?.elementaryDetail?.state, options: elementaryState, noOptionsMessage: 'Select country first' },
    { type: 'input', name: 'formData.exams.elementaryDetail.city', label: 'School City', value: formData?.exams?.elementaryDetail?.city },
    { type: 'space' },
    { type: 'input', name: 'formData.exams.elementaryDetail.obtainedMarks', label: 'Obtained Marks', customType: 'number', value: formData?.exams?.elementaryDetail?.obtainedMarks },
    { type: 'input', name: 'formData.exams.elementaryDetail.totalMarks', label: 'Total Marks', customType: 'number', value: formData?.exams?.elementaryDetail?.totalMarks },
    { type: 'input', name: 'formData.exams.elementaryDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.exams?.elementaryDetail?.percentage },
    { type: 'input', name: 'formData.exams.elementaryDetail.percentile', label: 'Percentile', customType: 'decimal', value: formData?.exams?.elementaryDetail?.percentile },
    { type: 'input', name: 'formData.exams.elementaryDetail.grade', label: 'Grade', customType: 'grade', value: formData?.exams?.elementaryDetail?.grade },
    { type: 'input', name: 'formData.exams.elementaryDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.exams?.elementaryDetail?.cgpa }
  ];

  const lateralEntryFields = [
    { type: 'select', name: 'formData.exams.lateralEntryDetail.degree', label: 'Degree', options: diplomaOptions, value: formData?.exams?.lateralEntryDetail?.degree },
    { type: 'select', name: 'formData.exams.lateralEntryDetail.major', label: 'Major', options: diplomaMajorOptions, value: formData?.exams?.lateralEntryDetail?.major },
    { type: 'select', name: 'formData.exams.lateralEntryDetail.degreeType', label: 'Degree Type', options: degreeTypeOptions, value: formData?.exams?.lateralEntryDetail?.degreeType },
    { type: 'select', name: 'formData.exams.lateralEntryDetail.passingYear', label: 'Completion Year', options: pastYearsArray(), value: formData?.exams?.lateralEntryDetail?.passingYear },
    { type: 'input', name: 'formData.exams.lateralEntryDetail.collegeName', label: 'School Name', value: formData?.exams?.lateralEntryDetail?.collegeName },
    { type: 'select', name: 'formData.exams.lateralEntryDetail.country', label: 'School Country', value: formData?.exams?.lateralEntryDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.exams.lateralEntryDetail.state', label: 'School State', value: formData?.exams?.lateralEntryDetail?.state, options: lateralEntryState, noOptionsMessage: 'Select Country first' },
    { type: 'input', name: 'formData.exams.lateralEntryDetail.city', label: 'School City', value: formData?.exams?.lateralEntryDetail?.city },
    { type: 'space' },
    { type: 'input', name: 'formData.exams.lateralEntryDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.exams?.lateralEntryDetail?.percentage },
    { type: 'input', name: 'formData.exams.lateralEntryDetail.grade', label: 'Grade', value: formData?.exams?.lateralEntryDetail?.grade, customType: 'grade' },
    { type: 'input', name: 'formData.exams.lateralEntryDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.exams?.lateralEntryDetail?.cgpa }
  ];

  const intermediateFields = [
    { type: 'select', size: 'full', name: 'formData.exams.intermediateDetail.boardName', label: 'Board Name', options: boardOptions, value: formData?.exams?.intermediateDetail?.boardName, skeletonClassName: 'col-span-full' },
    { type: 'input', name: 'formData.exams.intermediateDetail.rollNo', label: 'Roll No', value: formData?.exams?.intermediateDetail?.rollNo },
    { type: 'select', name: 'formData.exams.intermediateDetail.examYear', label: 'Exam Year', options: pastYearsArray(), value: formData?.exams?.intermediateDetail?.examYear },
    { type: 'input', name: 'formData.exams.intermediateDetail.schoolName', label: 'School Name', value: formData?.exams?.intermediateDetail?.schoolName },
    { type: 'select', name: 'formData.exams.intermediateDetail.country', label: 'School Country', value: formData?.exams?.intermediateDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.exams.intermediateDetail.state', label: 'School State', value: formData?.exams?.intermediateDetail?.state, options: intermediateState, noOptionsMessage: 'Select Country first' },
    { type: 'input', name: 'formData.exams.intermediateDetail.city', label: 'School City', value: formData?.exams?.intermediateDetail?.city },
    { type: 'space' },
    { type: 'input', name: 'formData.exams.intermediateDetail.obtainedMarks', label: 'Obtained Marks', customType: 'number', value: formData?.exams?.intermediateDetail?.obtainedMarks },
    { type: 'input', name: 'formData.exams.intermediateDetail.totalMarks', label: 'Total Marks', customType: 'number', value: formData?.exams?.intermediateDetail?.totalMarks },
    { type: 'input', name: 'formData.exams.intermediateDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.exams?.intermediateDetail?.percentage },
    { type: 'input', name: 'formData.exams.intermediateDetail.percentile', label: 'Percentile', customType: 'decimal', value: formData?.exams?.intermediateDetail?.percentile },
    { type: 'input', name: 'formData.exams.intermediateDetail.grade', label: 'Grade', value: formData?.exams?.intermediateDetail?.grade, customType: 'grade' },
    { type: 'input', name: 'formData.exams.intermediateDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.exams?.intermediateDetail?.cgpa }
  ];

  const ugFields = [
    { type: 'select', name: 'formData.exams.ugDetail.degree', label: 'Degree', value: formData?.exams?.ugDetail?.degree, options: ugOptions },
    { type: 'input', name: 'formData.exams.ugDetail.major', label: 'Major', value: formData?.exams?.ugDetail?.major, options: ugMajorOptions },
    { type: 'select', name: 'formData.exams.ugDetail.degreeType', label: 'Degree Type', value: formData?.exams?.ugDetail?.degreeType, options: degreeTypeOptions },
    { type: 'select', name: 'formData.exams.ugDetail.passingYear', label: 'Graduation Batch Year', options: pastYearsArray(), value: formData?.exams?.ugDetail?.passingYear },
    { type: 'input', name: 'formData.exams.ugDetail.collegeName', label: 'College Name', value: formData?.exams?.ugDetail?.collegeName },
    { type: 'select', name: 'formData.exams.ugDetail.country', label: 'College Country', value: formData?.exams?.ugDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.exams.ugDetail.state', label: 'College State', value: formData?.exams?.ugDetail?.state, options: ugState, noOptionsMessage: 'Please select Country first' },
    { type: 'input', name: 'formData.exams.ugDetail.city', label: 'College City', value: formData?.exams?.ugDetail?.city },
    { type: 'input', name: 'formData.exams.ugDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.exams?.ugDetail?.percentage },
    { type: 'input', name: 'formData.exams.ugDetail.grade', label: 'Grade', value: formData?.exams?.ugDetail?.grade, customType: 'grade' },
    { type: 'input', name: 'formData.exams.ugDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.exams?.ugDetail?.cgpa }
  ];

  const pgFields = [
    { type: 'select', name: 'formData.exams.pgDetail.degree', label: 'Degree', options: pgOptions, value: formData?.exams?.pgDetail?.degree },
    { type: 'input', name: 'formData.exams.pgDetail.major', label: 'Major', value: formData?.exams?.pgDetail?.major, options: pgMajorOptions },
    { type: 'select', name: 'formData.exams.pgDetail.degreeType', label: 'Degree Type', value: formData?.exams?.pgDetail?.degreeType, options: degreeTypeOptions },
    { type: 'select', name: 'formData.exams.pgDetail.passingYear', label: 'Graduation Batch Year', options: pastYearsArray(), value: formData?.exams?.pgDetail?.passingYear },
    { type: 'input', name: 'formData.exams.pgDetail.collegeName', label: 'College Name', value: formData?.exams?.pgDetail?.collegeName },
    { type: 'select', name: 'formData.exams.pgDetail.country', label: 'College Country', value: formData?.exams?.pgDetail?.country, options: countryOptions },
    { type: 'select', name: 'formData.exams.pgDetail.state', label: 'College State', value: formData?.exams?.pgDetail?.state, options: pgState, noOptionsMessage: 'Please Select Country first' },
    { type: 'input', name: 'formData.exams.pgDetail.city', label: 'College City', value: formData?.exams?.pgDetail?.city },
    { type: 'input', name: 'formData.exams.pgDetail.percentage', label: 'Percentage', customType: 'decimal', value: formData?.exams?.pgDetail?.percentage },
    { type: 'input', name: 'formData.exams.pgDetail.grade', label: 'Grade', value: formData?.exams?.pgDetail?.grade, customType: 'grade' },
    { type: 'input', name: 'formData.exams.pgDetail.cgpa', label: 'CGPA', customType: 'decimal', value: formData?.exams?.pgDetail?.cgpa }
  ];

  const boardSubjectProperties = [
    { type: 'input', name: 'subjectName', label: 'Subject Name' },
    { type: 'input', name: 'obtainedMarks', label: 'Obtained Marks', customType: "number" },
    { type: 'input', name: 'totalMarks', label: 'Total Marks', customType: "number" },
  ];

  const otherExamsProperties = [
    { type: 'select', name: 'examYear', label: 'Exam Year', options: pastYearsArray() },
    { type: 'input', name: 'rollNo', label: 'Roll No' },
    { type: 'space' },
    { type: 'input', name: 'obtainedMarks', label: 'Obtained Marks', customType: "number" },
    { type: 'input', name: 'totalMarks', label: 'Total Marks', customType: "number" },
    { type: 'input', name: 'percentage', label: 'Percentage', customType: "decimal" },
    { type: 'input', name: 'percentile', label: 'Percentile', customType: "decimal" },
    { type: 'input', name: 'nationalRank', label: 'National Rank', customType: "number" },
    { type: 'input', name: 'stateRank', label: 'State Rank', customType: "number" },
    { type: 'input', name: 'casteRank', label: 'Caste Rank', customType: "number" },
    { type: 'input', name: 'grade', label: 'Grade', customType: 'grade' },
  ];

  const handleSubjectNumberChange = (e, boardExam) => {
    const newTotalSubjects = parseInt(e.target.value);
    const currentSubjects = formData?.exams?.[boardExam]?.subjects ? [...formData?.exams?.[boardExam]?.subjects] : [];
    const newSubjects = currentSubjects?.slice(0, newTotalSubjects);
    handleNestedChange({ target: { name: `formData.exams.${boardExam}.subjects`, value: newSubjects } });
    setSubjectsLength({ ...subjectsLength, [`${boardExam}SubjectLength`]: e.target.value })
  };

  // const handleElectiveAddDelete = (newlyAddedElement, newlyDeletedElement) => {
  //   if (newlyAddedElement) {
  //     handleNestedChange({ target: { name: `formData.exams.otherExams.elective.${newlyAddedElement}`, value: {} } });
  //   } else if (newlyDeletedElement) {
  //     let updatedElective = { ...formData.exams.otherExams.elective };
  //     delete updatedElective[newlyDeletedElement];
  //     handleNestedChange({ target: { name: 'formData.exams.otherExams.elective', value: updatedElective } });
  //   }
  // }

  // const handleOptionalAddDelete = (newlyAddedElement, newlyDeletedElement) => {
  //   if (newlyAddedElement) {
  //     handleNestedChange({ target: { name: `formData.exams.otherExams.optional.${newlyAddedElement}`, value: {} } });
  //   } else if (newlyDeletedElement) {
  //     let updatedOptional = { ...formData.exams.otherExams.optional };
  //     delete updatedOptional[newlyDeletedElement];
  //     handleNestedChange({ target: { name: 'formData.exams.otherExams.optional', value: updatedOptional } });
  //   }
  // }

  const handleDocumentChange = async (event, index) => {
    openPopup('Select Document', 'Do you want to choose this document type? Any of your previously selected document from this section will get deleted', 'edit',
      () => onDocumentChange(event),
      () => handleNestedSelectImmediateChange({ target: { name: event.target.name, value: formData?.optionalCommonDocuments?.[index] } }))
  }

  const onDocumentChange = async (event) => {
    const body = { documents: documentData, selectedDocument: event.target.value }
    const { message, success } = await putData(id ? `/api/employee/admission/applicants/updateOptionalCommonDocuments/${id}` : "/api/admission/student/updateOptionalCommonDocuments", body)
    if (message) return handleSnackbarOpen(message, 'warning');
    if (!success) return handleSnackbarOpen('Opps... Some error Occurred', 'error');
    handleNestedChange(event);
    handleSnackbarOpen('Document updated successfully', 'success')
  }

  const filterFields = (templateFields, examDataFields) => {
    if (!examDataFields) return templateFields;
    return templateFields
      .filter(field => field.type === 'space' || examDataFields[field?.name?.split('.')?.pop()] !== undefined)
      .map(field => ({ ...field, required: examDataFields[field?.name?.split('.')?.pop()] }));
  };

  const handleDisable = (name) => {
    if (!verifiedValues) return false;
    const value = name?.split('.')?.reduce((acc, key) => acc?.[key], verifiedValues);
    return value?.status !== 'Rejected';
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit} >
      {(!isLoading && !examDataLoading && !examData) ?

        <div className='flex flex-col items-center justify-center p-5 xs:p-10 text-center border-2 gap-5 h-fit min-h-[60svh]'>
          <Lottie className='h-40' animationData={Pending} loop={true} />
          <p className='flex flex-col text-s font-normal'>
            <span className='text-red-500 text-lg font-semibold'>Please Go back...</span>
            Select your Program and Round Type before entering to Academic Section
          </p>
        </div> :

        <div className='flex flex-col'>
          <div className="flex flex-col gap-10 px-5 sm:px-14 py-10 border-2 bg-tertiary">
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'>
              <h4 className="col-span-full h4-p">Elementary (10<sup>th</sup> Standard) Details</h4>
              {filterFields(elementaryFields, examData?.elementaryDetail)?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('exams.elementaryDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, examDataLoading || isLoading))}
            </div>

            <div className='flex flex-col gap-5'>
              <h4 className="h4-p">Subject Wise Marks Distribution</h4>
              <CustomAsyncSelect
                className='bg-white'
                options={Array.from({ length: verifiedValues ? (10 - subjectsLength?.elementaryDetailSubjectLength) : 10 }, (_, index) => ({ value: verifiedValues ? (index + subjectsLength?.elementaryDetailSubjectLength) : (index + 1), label: verifiedValues ? `${index + subjectsLength?.elementaryDetailSubjectLength}` : `${index + 1}` }))}
                disabled={subjectsLength?.elementaryDetailSubjectLength === 10}
                value={subjectsLength?.elementaryDetailSubjectLength}
                onChange={(e) => { handleSubjectNumberChange(e, 'elementaryDetail') }}
                label='Total Number of Subjects you have'
                skeletonLoading={examDataLoading || isLoading}
              />

              {Array.from({ length: subjectsLength.elementaryDetailSubjectLength })?.map((_, i) =>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'>
                  {boardSubjectProperties?.map((field, index) => (renderFormField({
                    type: field?.type,
                    size: field?.size,
                    label: field?.label,
                    name: `formData.exams.elementaryDetail.subjects.${i}.${field?.name}`,
                    value: formData?.exams?.elementaryDetail?.subjects?.[i]?.[field?.name],
                    disabled: handleDisable('exams.elementaryDetail.subjects.' + i + field?.name),
                    onChange: handleNestedChange,
                    customType: field?.customType
                  }, index, examDataLoading || isLoading)))}
                </div>
              )}
            </div>
          </div>

          {examData?.lateralEntryDetail && <div className="flex flex-col gap-10 px-5 sm:px-14 py-10 border-2">
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'>
              <h4 className="col-span-full h4-p">LateralEntry Details</h4>
              {filterFields(lateralEntryFields, examData?.lateralEntryDetail)?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('exams.lateralEntryDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, examDataLoading || isLoading))}
            </div>
          </div>}

          {examData?.intermediateDetail && <div className="flex flex-col gap-10 px-5 sm:px-14 py-10 border-2">
            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'>
              <h4 className="col-span-full h4-p">Intermediate (12<sup>th</sup> Standard) Details</h4>
              {filterFields(intermediateFields, examData?.intermediateDetail)?.map((field, index) => renderFormField({
                ...field,
                disabled: handleDisable('exams.intermediateDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange
              }, index, examDataLoading || isLoading))}
            </div>

            <div className='flex flex-col gap-5'>
              <h4 className="h4-p">Subject Wise Marks Distribution</h4>
              <CustomAsyncSelect
                className='bg-white'
                options={Array.from({ length: verifiedValues ? (10 - subjectsLength?.intermediateDetailSubjectLength) : 10 }, (_, index) => ({ value: verifiedValues ? (index + subjectsLength?.intermediateDetailSubjectLength) : (index + 1), label: verifiedValues ? `${index + subjectsLength?.intermediateDetailSubjectLength}` : `${index + 1}` }))}
                disabled={subjectsLength?.elementaryDetailSubjectLength === 10}
                value={subjectsLength.intermediateDetailSubjectLength}
                onChange={(e) => { handleSubjectNumberChange(e, 'intermediateDetail') }}
                label='Total Number of Subjects you have'
                skeletonLoading={examDataLoading || isLoading}
              />

              {Array.from({ length: subjectsLength.intermediateDetailSubjectLength })?.map((_, i) =>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'>
                  {boardSubjectProperties?.map((field, index) => renderFormField({
                    type: field?.type,
                    size: field?.size,
                    label: field?.label,
                    name: `formData.exams.intermediateDetail.subjects.${i}.${field?.name}`,
                    value: formData?.exams?.intermediateDetail?.subjects?.[i]?.[field?.name],
                    disabled: handleDisable('exams.intermediateDetail.subjects.' + i + field?.name),
                    onChange: handleNestedChange,
                    customType: field?.customType
                  }, index, examDataLoading || isLoading))}
                </div>
              )}
            </div>
          </div>}

          {examData?.ugDetail && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2 bg-tertiary">
            <h4 className="col-span-full h4-p">Under Graduation Details</h4>
            {filterFields(ugFields, examData?.ugDetail)?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('exams.ugDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, examDataLoading || isLoading))}
          </div>}

          {examData?.pgDetail && <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2">
            <h4 className="col-span-full h4-p">Post Graduation Details</h4>
            {filterFields(pgFields, examData?.pgDetail)?.map((field, index) => renderFormField({ ...field, disabled: handleDisable('exams.pgDetail.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, examDataLoading || isLoading))}
          </div>}

          {examData?.requiredExamsDetail && <div className="flex flex-col gap-10 px-5 sm:px-14 py-10 border-2 bg-tertiary">
            {Object?.keys(examData?.requiredExamsDetail ?? {})?.map((roundName, index) =>
              <div key={index} className='flex flex-col gap-5'>
                <h4 className="h4-p">{roundName} Exam Details</h4>
                {examData?.requiredExamsDetail?.[roundName]?.map((exam, i) =>
                  <div key={i} className={`grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 ${i === 0 ? '' : 'mt-10'}`}>
                    <CustomTextField className='bg-white' label='Exam Name' value={exam?.examName} disabled skeletonLoading={examDataLoading || isLoading} />
                    {filterFields(otherExamsProperties, exam)?.map(({ name, ...otherKeys }, examIndex) =>
                      renderFormField({
                        ...otherKeys,
                        name: `formData.exams.requiredExamsDetail.${roundName}.${i}.${name}`,
                        value: formData?.exams?.requiredExamsDetail?.[roundName]?.[i]?.[name],
                        disabled: handleDisable('exams.requiredExamsDetail.' + roundName + i + name),
                        onChange: handleNestedChange
                      }, examIndex, examDataLoading || isLoading)
                    )}
                  </div>)}
              </div>
            )}
          </div>}

          {/* {examData?.optionalExamsDetail && <div className="flex flex-col gap-10 px-5 sm:px-14 py-10 border-2 bg-tertiary">
            <div className='flex flex-col gap-4'>
              <h4 className="col-span-full h4-p">Optional Exams</h4>
              <CustomMultiCheckboxSelect className='bg-white' label='Select all the exams you have attempted' value={selectedOptionalExams} name='formData.exams.optionalExamsDetail' onChange={handleNestedChange} options={Object.keys(examData?.otherExams?.optional).map(key => ({ label: key, value: key }))} handleAddDelete={handleOptionalAddDelete} color='secondary' required={false} skeletonLoading={isLoading || examDataLoading} skeletonClassName='col-span-full' />
            </div>

            {Object?.keys(formData?.exams?.otherExams?.optional ?? {})?.length > 0 && <>
              {Object?.keys(formData?.exams?.otherExams?.optional)?.map((examName, i) =>
                <div key={i} className={'grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14'}>
                  <h4 className="h4-p col-span-full">{examName} Exam Details</h4>
                  {filterFields(otherExamsProperties, examData?.otherExams?.optional?.[examName])?.map(({ name, ...otherKeys }, examIndex) =>
                    renderFormField({
                      ...otherKeys,
                      name: `formData.exams.otherExams.optional.${examName}.${name}`,
                      value: formData?.exams?.otherExams?.optional?.[examName]?.[name],
                      disabled: verifiedValues ? !(verifiedValues?.exams?.otherExams?.optional?.[examName]?.[name]) : false,
                      onChange: handleNestedChange
                    }, examIndex, examDataLoading || isLoading)
                  )}
                </div>
              )}
            </>}
          </div>} */}


          {documentData && <div className="grid sm:grid-cols-2 gap-5 lg:gap-x-14 px-5 sm:px-14 py-10 border-2">

            <div className='col-span-full flex justify-between'>
              <h4 className="h4-p col-span-full">Optional Documents</h4>
              <BlackToolTip title='You have to select at least one of below documents'>
                <InfoOutlined className='text-slate-500' />
              </BlackToolTip>
            </div>

            {documentData?.map((documents, index) => <CustomAsyncSelect
              className="bg-white"
              label={`Document ${index + 1}`}
              options={() => documents.map(document => ({ label: document, value: document }))}
              value={formData?.optionalCommonDocuments?.[index]}
              name={`formData.optionalCommonDocuments.${index}`}
              onChange={(e) => handleDocumentChange(e, index)}
              disabled={!!verifiedValues}
              loading={updateDocumentLoading}
              skeletonLoading={isLoading || examDataLoading || documentDataLoading}
            />)}

          </div>}
        </div>
      }
      < BottomTile id={id} clearForm={clearForm} steps={steps} />
    </form >
  )
}

export default AcademicsDetails;