import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const ElementaryInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const elementaryDetailFields = [
        { label: "Board Name", value: formData?.education?.elementaryDetail?.boardName?.status, data: formData?.education?.elementaryDetail?.boardName?.data, name: 'formData.education.elementaryDetail.boardName.status' },
        { label: "Roll No", value: formData?.education?.elementaryDetail?.rollNo?.status, data: formData?.education?.elementaryDetail?.rollNo?.data, name: 'formData.education.elementaryDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.education?.elementaryDetail?.examYear?.status, data: formData?.education?.elementaryDetail?.examYear?.data, name: 'formData.education.elementaryDetail.examYear.status' },
        { label: "School Name", value: formData?.education?.elementaryDetail?.schoolName?.status, data: formData?.education?.elementaryDetail?.schoolName?.data, name: 'formData.education.elementaryDetail.schoolName.status' },
        { label: "School City", value: formData?.education?.elementaryDetail?.city?.status, data: formData?.education?.elementaryDetail?.city?.data, name: 'formData.education.elementaryDetail.city.status' },
        { label: "School State", value: formData?.education?.elementaryDetail?.state?.status, data: formData?.education?.elementaryDetail?.state?.data, name: 'formData.education.elementaryDetail.state.status' },
        { label: "School Country", value: formData?.education?.elementaryDetail?.country?.status, data: formData?.education?.elementaryDetail?.country?.data, name: 'formData.education.elementaryDetail.country.status' },
        { label: "Obtained Marks", value: formData?.education?.elementaryDetail?.obtainedMarks?.status, data: formData?.education?.elementaryDetail?.obtainedMarks?.data, name: 'formData.education.elementaryDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.education?.elementaryDetail?.totalMarks?.status, data: formData?.education?.elementaryDetail?.totalMarks?.data, name: 'formData.education.elementaryDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.education?.elementaryDetail?.percentage?.status, data: formData?.education?.elementaryDetail?.percentage?.data, name: 'formData.education.elementaryDetail.percentage.status' },
        { label: "Grade", value: formData?.education?.elementaryDetail?.grade?.status, data: formData?.education?.elementaryDetail?.grade?.data, name: 'formData.education.elementaryDetail.grade.status' },
        { label: "CGPA", value: formData?.education?.elementaryDetail?.cgpa?.status, data: formData?.education?.elementaryDetail?.cgpa?.data, name: 'formData.education.elementaryDetail.cgpa.status' },
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Elementary Details')} value={masterCheckBoxValue(elementaryDetailFields, checkBoxOptions)} name={elementaryDetailFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Elementary Details</h4>
            </CustomCheckBox>

            {elementaryDetailFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
            )}
        </div>
    )
}

export default ElementaryInfo;