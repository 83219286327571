import { Link } from 'react-router-dom'
import { Avatar, useMediaQuery } from '@mui/material'
import Clock from './Clock';
import CalendarForm from './CalendarForm';
import Announcement from './Announcement';
import Occasion from './Occasion';
import TechSupport from './TechSupport';
import Stats from './Stats';
import BarChartWrapper from '../../../components/graphsWrapper/BarChartWrapper';
import SkeletonText from '../../../components/mui/text/SkeltonText';

const Dashboard = () => {
  const xs = useMediaQuery('(min-width:500px)');
  const largeScreen = useMediaQuery('(min-width:1024px)');

  const graphData = [
    { label: '2015', CSE: 120, BBA: 45, MBA: 30, BTECH: 50 },
    { label: '2016', BCOM: 95, MBBS: 50 },
    { label: '2017', BTECH: 85, MTECH: 40, BA: 15, MCA: 20, BCA: 35 },
    { label: '2018', BBA: 110, BDS: 55, BCOM: 25, LLB: 30 },
    { label: '2019', CSE: 150, BCA: 60, MCA: 35 },
    { label: '2020', MBBS: 130, BBA: 75, BTECH: 50, LLB: 45 },
    { label: '2021', LLB: 105, MBA: 70, BCOM: 40, BA: 25 },
    { label: '2022', BCA: 80, BDS: 65, MTECH: 30, BTECH: 55, CSE: 60 },
    { label: '2023', CSE: 160, BTECH: 90, BA: 50, MBA: 40 },
    { label: '2024', BBA: 140, BCOM: 85, LLB: 60, BCA: 75, MBBS: 80 }
  ];

  return (
    <div className='grid grid-cols-1 xs:grid-cols-20 gap-3 w-full h-full p-5'>

      <div className='col-span-full lg:col-span-15 grid grid-cols-6 gap-3'>
        <div className='col-span-full flex flex-col xs:flex-row gap-4 items-center p-5 border bg-white drop-shadow rounded-md'>
          <Link to='/admin/profile?type=Personal'>
            <Avatar loading={true} alt='Akash' src='{employeeData?.photoURL}' sizes='lg' variant="rounded" sx={{ fontSize: 40, width: 80, height: 80 }} />
          </Link>

          <p className='flex flex-col text-center xs:text-start truncate gap-1'>
            <SkeletonText loading={false} width='50%' className='h2-p font-semibold whitespace-normal'>Welcome Back, Akash!</SkeletonText>
            <SkeletonText loading={false} width='80%' className='lb-s whitespace-normal'>Ready to lead another successful day at Aspire College, where your expertise ensures a smooth and secure admission process.</SkeletonText>
          </p>
        </div>

        <Occasion className='col-span-full p-5 border bg-secondary drop-shadow rounded-md' smallScreen={xs} />

        <BarChartWrapper data={graphData} heading='Program Distribution over Years' className='col-span-full p-5 border bg-white drop-shadow rounded-md' />

        <Stats className='col-span-full' />
      </div>

      <div className='col-span-full lg:col-span-5 grid sm:grid-cols-2 gap-3 h-fit'>
        {largeScreen && <Clock className='col-span-3 md:col-span-2 lg:col-span-full p-5 border bg-white drop-shadow rounded-md' />}

        {largeScreen && <CalendarForm className='lg:col-span-full border bg-white drop-shadow rounded-md' />}

        <Announcement className='lg:col-span-full p-5 border bg-white drop-shadow rounded-md' />

        <TechSupport className='lg:col-span-full p-5 border bg-white drop-shadow rounded-md' />
      </div>
    </div >
  )
}

export default Dashboard