import React, { useState, useRef } from "react";
import { useSetRecoilState } from "recoil";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { ChangeCircle } from '@mui/icons-material';

import { loadingState } from "../../../recoil/state";
import useGetData from "../../../hooks/useGetData";
import useSnackbar from "../../../hooks/useSnackbar";
import usePostData from "../../../hooks/usePostData";
import useFormOperations from "../../../hooks/useFormOperations";

import { CustomAsyncSelect, CustomTextField } from "../../../components/mui/Input";
import { PurpleButton } from "../../../components/mui/button/Button";
import { Link } from "react-router-dom";

const UpdateContactInfo = () => {
    const { data: communicationData } = useGetData('/api/employee/personal/getCurrent')
    const { postData } = usePostData();

    const { handleSnackbarOpen } = useSnackbar();
    const verifyOtpButtonRef = useRef(null);

    const setLoading = useSetRecoilState(loadingState);

    const [otp, setOtp] = useState('')
    const [infoSubmitted, setInfoSubmitted] = useState(false);
    const [contact, setContact] = useState();

    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);

    const isEmail = ['emailId', 'officialEmailId', 'alternateEmailId'].includes(contact);
    const isNumber = ['phNo', 'alternatePhNo'].includes(contact);

    const contactOptions = {
        emailId: 'Email ID',
        officialEmailId: 'Official Email ID',
        alternateEmailId: 'Alternate Email ID',
        phNo: 'Phone Number',
        alternatePhNo: 'Alternate Phone Number',
    };

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, message } = await postData('/api/employee/personal/updateContactDetailsGenerateOTP', formData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP!", 'error')

        handleSnackbarOpen("OTP sent successfully!", 'success')
        setInfoSubmitted(true)
    }

    const { formData, handleChange, updateData, handleSubmit } = useFormOperations({}, onSubmit);

    const handleContactChange = (e) => {
        const name = e.target.value;
        setContact(name);
        updateData(() => ({ [name]: communicationData[name] }));
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (verifyOtpButtonRef.current) verifyOtpButtonRef.current.click();
        }
    };

    const onOTPVerify = async () => {
        const OTPFormData = { ...formData, otp }
        setLoading(true);
        const { success, message } = await postData('/api/employee/personal/updateContactDetailsVerifyOTP', OTPFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Wrong OTP!", 'error');

        handleSnackbarOpen("OTP Verified successfully!", 'success');
        setContact('');
        setInfoSubmitted(false);
        updateData(() => ({}));
    }

    const toggleInfoSubmitted = () => setInfoSubmitted(!infoSubmitted)
    const handleDisable = () => communicationData[contact] === formData[contact];

    return (
        <div className="relative flex items-center justify-center p-5 xs:p-10 h-full min-h-[calc(100svh-132px)] tab:min-h-[calc(100svh-5rem)]">

            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[25%] right-0 top-[20%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[50%] right-0 top-[45%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-50 to-regal-purple-10 h-20 w-[10%] right-0 top-[70%] rounded-l-full" />
            <div className="absolute hidden xs:block bg-gradient-to-l from-regal-purple-30 via-regal-purple-50 to-regal-purple-30 h-20 w-[20%] right-[12%] top-[70%] rounded-full" />

            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[25%] left-0 top-[45%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[50%] left-0 top-[70%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-50 to-regal-purple-10 h-20 w-[10%] left-0 top-[20%] rounded-r-full" />
            <div className="absolute hidden xs:block bg-gradient-to-r from-regal-purple-30 via-regal-purple-50 to-regal-purple-30 h-20 w-[20%] left-[12%] top-[20%] rounded-full" />

            <div className="flex flex-col p-5 xs:p-10 gap-8 max-w-md w-full bg-white xs:drop-shadow xs:rounded-lg">
                <div className="flex flex-col gap-3">
                    <h1 className="h2-p text-center text-regal-purple-800">Update Contact</h1>
                    <span className="text-center text-s">Update your Information by verifying through Otp</span>
                </div>

                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <CustomAsyncSelect
                        label='Select your contact Info'
                        value={contact}
                        disabled={infoSubmitted}
                        onChange={handleContactChange}
                        options={Object.keys(contactOptions).map(key => ({ value: key, label: contactOptions[key] }))}
                    />

                    <CustomTextField
                        name={contact} label={contact ? contactOptions?.[contact] : 'Contact Info'}
                        value={formData?.[contact] ?? ''}
                        InputLabelProps={{ shrink: true }}
                        placeholder='Select your Contact Info first'
                        onChange={handleChange}
                        disabled={!contact || infoSubmitted}
                        type={isEmail && 'email'}
                        InputProps={isNumber && { inputProps: { maxLength: 10 } }}
                        endIcon={infoSubmitted && <ChangeCircle className="text-light hover:text-purple cursor-pointer" onClick={toggleInfoSubmitted} />}
                    />

                    {infoSubmitted ?
                        <div className="flex flex-col gap-2 h-20">
                            <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 12 } }} onKeyDown={handleKeyDown} />
                            <p className="gap-2">
                                <span className="text-light">Didn't receive the code?</span> <button type="submit" className="text-purple hover:underline ">Resend it.</button>
                            </p>
                        </div>
                        :
                        <p className="h-20 grid place-items-center text-center border-dashed border-2 w-full p-3">
                            <span className="text-s leading-6">
                                Want to update your data? <br />
                                Change the Information to update.
                            </span>
                        </p>
                    }

                    <div className="flex flex-col gap-4">
                        {infoSubmitted ?
                            <PurpleButton disabled={otp.length < 6} fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} ref={verifyOtpButtonRef} />
                            :
                            <PurpleButton fullWidth disabledElevation={true} type="submit" text="Generate OTP" disabled={!contact || handleDisable()} />
                        }
                        <Link to='/admin/settings' className="text-center text-p text-regal-purple-800 hover:text-regal-purple-950 hover:underline">Cancel Changes</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateContactInfo;