import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const IntermediateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const intermediateDetailFields = [
        { label: "Board Name", value: formData?.education?.intermediateDetail?.boardName?.status, data: formData?.education?.intermediateDetail?.boardName?.data, name: 'formData.education.intermediateDetail.boardName.status' },
        { label: "Roll No", value: formData?.education?.intermediateDetail?.rollNo?.status, data: formData?.education?.intermediateDetail?.rollNo?.data, name: 'formData.education.intermediateDetail.rollNo.status' },
        { label: "Exam Year", value: formData?.education?.intermediateDetail?.examYear?.status, data: formData?.education?.intermediateDetail?.examYear?.data, name: 'formData.education.intermediateDetail.examYear.status' },
        { label: "School Name", value: formData?.education?.intermediateDetail?.schoolName?.status, data: formData?.education?.intermediateDetail?.schoolName?.data, name: 'formData.education.intermediateDetail.schoolName.status' },
        { label: "School City", value: formData?.education?.intermediateDetail?.city?.status, data: formData?.education?.intermediateDetail?.city?.data, name: 'formData.education.intermediateDetail.city.status' },
        { label: "School State", value: formData?.education?.intermediateDetail?.state?.status, data: formData?.education?.intermediateDetail?.state?.data, name: 'formData.education.intermediateDetail.state.status' },
        { label: "School Country", value: formData?.education?.intermediateDetail?.country?.status, data: formData?.education?.intermediateDetail?.country?.data, name: 'formData.education.intermediateDetail.country.status' },
        { label: "Obtained Marks", value: formData?.education?.intermediateDetail?.obtainedMarks?.status, data: formData?.education?.intermediateDetail?.obtainedMarks?.data, name: 'formData.education.intermediateDetail.obtainedMarks.status' },
        { label: "Total Marks", value: formData?.education?.intermediateDetail?.totalMarks?.status, data: formData?.education?.intermediateDetail?.totalMarks?.data, name: 'formData.education.intermediateDetail.totalMarks.status' },
        { label: "Percentage", value: formData?.education?.intermediateDetail?.percentage?.status, data: formData?.education?.intermediateDetail?.percentage?.data, name: 'formData.education.intermediateDetail.percentage.status' },
        { label: "Grade", value: formData?.education?.intermediateDetail?.grade?.status, data: formData?.education?.intermediateDetail?.grade?.data, name: 'formData.education.intermediateDetail.grade.status' },
        { label: "CGPA", value: formData?.education?.intermediateDetail?.cgpa?.status, data: formData?.education?.intermediateDetail?.cgpa?.data, name: 'formData.education.intermediateDetail.cgpa.status' },
    ];

    return (
        <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
            <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, 'Intermediate Details')} value={masterCheckBoxValue(intermediateDetailFields, checkBoxOptions)} name={intermediateDetailFields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                <h4 className="col-span-full h4-p text-grey">Intermediate Details</h4>
            </CustomCheckBox>
            
            {intermediateDetailFields?.map((field, index) => (isLoading || field?.data !== undefined) &&
                <CustomCheckBox value={field?.value} name={field?.name} onChange={handleCheckboxChange} key={index} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <TextBox label={field?.label} value={field?.data} loading={isLoading} />
                </CustomCheckBox>
            )}
        </div>
    )
}

export default IntermediateInfo;