import { useEffect, useState } from 'react';
import useFetchData from './useFetchData';

const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000;

const useGetClientData = () => {
    const [data, setData] = useState(null);
    const { fetchData, isLoading } = useFetchData();

    useEffect(() => {
        const storedData = localStorage.getItem('clientData');
        const storedTime = localStorage.getItem('clientDataTime');

        // Check for valid stored data
        if (storedData && storedTime) {
            const currentTime = Date.now();
            if (currentTime - Number(storedTime) < EXPIRATION_TIME)
                return setData(JSON.parse(storedData));

            localStorage.removeItem('clientData');
            localStorage.removeItem('clientDataTime');
        }

        // Fetch data if local storage does not have valid data
        const fetchAndStoreData = async () => {
            const responseData = await fetchData('/api/clientData');
            setData(responseData);
            localStorage.setItem('clientData', JSON.stringify(responseData));
            localStorage.setItem('clientDataTime', String(Date.now()));
        };

        fetchAndStoreData();
    }, []);

    return {
        organizationName: data?.name,
        abbreviation: data?.abbreviation,
        tagline: data?.tagline,
        supportEmailId: data?.mails?.support,
        socialMediaLinks: data?.socialMediaLinks,
        address: data?.address,
        officialWebsite: data?.officialWebsite,
        logoDefaultURL: data?.logoDefaultURL,
        logoThumbnailURL: data?.logoThumbnailURL || data?.logoDefaultURL, isLoading
    };
};

export default useGetClientData;