import { nationalities } from '../../../../../constants/geoConstants';
import { indianReligions, } from "../../../../../constants/personal";
import renderFormField from '../../../../../utils/renderFormField';

const IdentityInfo = ({ formData, data, handleNestedChange, isLoading }) => {

  const nationalityOptions = nationalities.map(nationality => ({ value: nationality, label: nationality }));
  const categoryOptions = data?.categoryData?.map(category => ({ value: category, label: category }));
  const religionOptions = indianReligions.map(religion => ({ value: religion, label: religion }));

  const casteDetailsFields = [
    { type: 'select', label: "Nationality", value: formData?.identityInfo?.nationality, name: "formData.identityInfo.nationality", options: nationalityOptions },
    { type: 'select', label: "Category", value: formData?.identityInfo?.category, name: "formData.identityInfo.category", options: categoryOptions, loading: data?.categoryLoading },
    { type: 'select', label: "Religion", value: formData?.identityInfo?.religion, name: "formData.identityInfo.religion", options: religionOptions },
    { type: 'input', label: "Caste", value: formData?.identityInfo?.caste, name: "formData.identityInfo.caste" },
    { type: 'input', label: "Sub Caste", value: formData?.identityInfo?.subCaste, name: "formData.identityInfo.subCaste", required: false },
  ]

  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
      <h5 className="col-span-full h4-p">Identity Details</h5>
      {casteDetailsFields?.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
    </div>
  )
}

export default IdentityInfo;