import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Avatar, useMediaQuery } from '@mui/material';
import { LocalPhone, MailOutline, CalendarMonth, VpnKey } from '@mui/icons-material';

import useGetData from '../../../hooks/useGetData';
import { dateFormat } from '../../../utils/dateFormat';

import BasicInfo from './BasicInfo';
import PersonalDetails from './PersonalDetails';
import FamilyDetails from './FamilyDetails';
import EducationDetails from './EducationDetails';
import EmploymentDetails from './EmploymentDetails';
import DocumentDetails from './DocumentDetails';
import FinanceDetails from './FinanceDetails';
import TabSmall from '../../../components/mui/tab/TabSmall';
import BubbleTile from '../../../components/tiles/BubbleTileFields';
import SkeletonText from '../../../components/mui/text/SkeltonText';
import BackNavigate from '../../../components/mui/BackNavigate';

const Profile = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const routeMap = {
        former: `/api/employee/hr/formers/getFormer/${id}`,
        current: `/api/employee/hr/currents/getCurrent/${id}`
    };

    const key = location?.pathname?.includes('former') ? 'former' : 'current';
    const { data, isLoading } = useGetData(id ? routeMap[key] : '/api/employee/personal/getCurrent');

    const activeTab = searchParams.get('type');
    const xs = useMediaQuery('(max-width:500px)');
    const tab = useMediaQuery('(max-width:900px)');

    const fullName = `${data?.firstName} ${data?.middleName ?? ""} ${data?.lastName}`;

    const sections = [
        { icon: <CalendarMonth color='secondary' />, label: 'Joining Date', value: (data) => dateFormat(data?.joiningDate), },
        { icon: <VpnKey color='warning' />, label: 'Employee ID', value: (data) => data?.employeeId, },
        { icon: <LocalPhone color='success' />, label: 'Contact Number', value: (data) => data?.phNo, },
        { icon: <MailOutline color='error' />, label: 'Official Email ID', value: (data) => data?.officialEmailId }
    ];

    return (
        <div className='flex flex-col gap-y-5 w-full h-full bg-secondary p-5'>
            <div className='flex flex-col gap-5 bg-white px-8 py-5 rounded-lg drop-shadow-md min-h-svh'>
                <div className='relative flex flex-col tab:flex-row items-center tab:items-start gap-5'>
                    {id && <BackNavigate previousLink={`/admin/employee/${key}s`} text='Back' subText={`Return to ${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`} className='absolute left-0 top-0 tab:hidden' />}

                    <Avatar loading={isLoading} src={data?.photoURL} alt={fullName} sizes='lg' variant="rounded" sx={{ fontSize: 75, width: 125, height: 125 }} />

                    <div className='flex flex-col gap-5 tab:w-full'>
                        <div className='flex flex-col gap-1 items-center tab:items-start tab:w-full'>
                            <div className='flex flex-col tab:flex-row justify-between text-center items-center w-full gap-1'>
                                <SkeletonText loading={isLoading} width={tab ? '100%' : '50%'} className='h4-p sm:h2-p'>{fullName}</SkeletonText>

                                {id && <BackNavigate previousLink={`/admin/employee/${key}s`} text='Back' subText={`Return to ${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`} className='hidden tab:flex' />}
                            </div>

                            <SkeletonText loading={isLoading} width={tab ? '100%' : '75%'} lines={xs ? 2 : 1} className='lb-s text-center'>{data?.designation} | <span className='text-purple'> {data?.collegeName} </span></SkeletonText>
                        </div>

                        <div className='flex items-center justify-center w-full'>
                            <div className='grid xs:grid-cols-2 tab:grid-cols-4 place-items-center gap-5 w-fit xs:w-full truncate'>
                                {sections?.map(({ icon, label, value }, index) => (
                                    <div key={index} className='flex flex-col gap-1 w-full'>
                                        <p className='flex gap-2 items-center'>
                                            {icon}
                                            <span className='lb-p'>{label}</span>
                                        </p>

                                        <SkeletonText loading={isLoading} width={150} className='text-s'>
                                            {value(data)}
                                        </SkeletonText>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col-reverse tab:flex-row gap-8 w-full'>
                    <div className='w-full tab:w-52 flex flex-col gap-10 tab:mt-9'>
                        <BasicInfo basicData={data?.personalInfo} loading={isLoading} />
                        <BubbleTile title="Domain" data={data?.additionalInfo?.domain} loading={isLoading} />
                    </div>

                    <div className='flex flex-col gap-5 w-full'>
                        <TabSmall className='flex justify-center lg:justify-start w-full'
                            tabs={['Personal', 'Education', 'Family', 'Finance', 'Employment', 'Documents']} />

                        <div className='flex flex-col w-full'>
                            {activeTab === "Personal" && <PersonalDetails formData={data} loading={isLoading} />}
                            {activeTab === "Education" && <EducationDetails educationData={data?.education} loading={isLoading} />}
                            {activeTab === "Family" && <FamilyDetails familyData={data?.familyInfo} loading={isLoading} />}
                            {activeTab === "Finance" && <FinanceDetails financeData={data?.financialAndLegalInfo} loading={isLoading} />}
                            {activeTab === "Employment" && <EmploymentDetails employmentData={data?.experience} loading={isLoading} />}
                            {activeTab === "Documents" && <DocumentDetails documentData={data?.documents} loading={isLoading} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;