import React from "react";
import Lottie from "lottie-react";

import { dateFormat } from "../../../utils/dateFormat";
import NoData from '../../../assets/lotties/NoData.json'
import TextBox from '../../../components/mui/text/TextBox';

const EmploymentDetails = ({ employmentData, loading }) => {

    return (
        <div className='flex flex-col w-full gap-5'>
            <div className='flex items-center gap-4'>
                <h6 className='lb-s whitespace-nowrap'>Employment Details</h6>
                <hr className='block lg:hidden w-full h-0.5 bg-slate-400' />
            </div>

            {(employmentData && employmentData.length > 0) ? <div className="flex flex-col gap-8 ml-1">
                {employmentData?.map((data, i) => (
                    <React.Fragment key={i}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 gap-5">
                            <TextBox label="Organization Name" value={data?.organizationName} loading={loading} />
                            <TextBox label="Position" value={data?.position} loading={loading} />
                            <TextBox label="Start Date" value={dateFormat(data?.startDate)} loading={loading} />
                            <TextBox label="End Date" value={dateFormat(data?.endDate)} loading={loading} />
                        </div>
                        {(i < employmentData.length - 1) && <hr className="w-full bg-slate-400 border-[1px]" />}
                    </React.Fragment>
                ))}
            </div> :
                <div className='flex flex-col text-center justify-center items-center h-full'>
                    <Lottie className='h-48' animationData={NoData} loop={true} />
                    <p className='text-s'>You have not updated any previous work experience.</p>
                </div>}
        </div>
    )
}

export default EmploymentDetails;