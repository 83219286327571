import React from 'react'
import { useSearchParams } from 'react-router-dom';

import useGetData from '../../../../hooks/useGetData';

import StudentIcon from '../../../../assets/icons/StudentIcon.svg';
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg';
import AdmissionTile from '../../../../components/tiles/AdmissionTile';
import PieChartWrapper from '../../../../components/graphsWrapper/PieChartWrapper';
import BarChartWrapper from '../../../../components/graphsWrapper/BarChartWrapper';

const OrganizationStats = ({ organizationStats, isLoading }) => {
    const [searchParams] = useSearchParams();

    const { data: studentCounts, isLoading: studentCountsLoading } = useGetData(
        `/api/student/stat/getStudentCounts?${searchParams.toString()}`
    );
    const { data: employeeCounts, isLoading: employeeCountsLoading } = useGetData(
        `/api/employee/stat/getEmployeeCounts?${searchParams.toString()}`
    );
    const { data: studentGenderCount, isLoading: studentGenderCountLoading } = useGetData(
        `/api/student/stat/getStudentGenderCount?${searchParams.toString()}`
    );
    const { data: employeeGenderCount, isLoading: employeeGenderCountLoading } = useGetData(
        `/api/employee/stat/getEmployeeGenderCount?${searchParams.toString()}`
    );

    const studentStats = [
        { heading: 'Total Applicant', count: studentCounts?.newAdmissionCount, icon: StudentIcon },
        { heading: 'Total Enrolled Students', count: studentCounts?.enrolledCount, icon: StudentIcon },
        { heading: 'Total Alumni', count: studentCounts?.alumniCount, icon: StudentIcon }
    ]
    const employeeStats = [
        { heading: 'Total Recruits', count: employeeCounts?.newAdmissionCount, icon: ProfessorIcon },
        { heading: 'Total Current Employees', count: employeeCounts?.currentCount, icon: ProfessorIcon },
        { heading: 'Total Former Employees', count: employeeCounts?.formerCount, icon: ProfessorIcon }
    ]

    const studentGenderData = [
        { label: 'Male', value: studentGenderCount?.maleCount },
        { label: 'Female', value: studentGenderCount?.femaleCount },
    ];

    const employeeGenderData = [
        { label: 'Male', value: employeeGenderCount?.maleCount },
        { label: 'Female', value: employeeGenderCount?.femaleCount },
    ];

    const graphData = [
        { label: '2015', CSE: 120, BBA: 45, MBA: 30, BTECH: 50 },
        { label: '2016', BCOM: 95, MBBS: 50 },
        { label: '2017', BTECH: 85, MTECH: 40, BA: 15, MCA: 20, BCA: 35 },
        { label: '2018', BBA: 110, BDS: 55, BCOM: 25, LLB: 30 },
        { label: '2019', CSE: 150, BCA: 60, MCA: 35 },
        { label: '2020', MBBS: 130, BBA: 75, BTECH: 50, LLB: 45 },
        { label: '2021', LLB: 105, MBA: 70, BCOM: 40, BA: 25 },
        { label: '2022', BCA: 80, BDS: 65, MTECH: 30, BTECH: 55, CSE: 60 },
        { label: '2023', CSE: 160, BTECH: 90, BA: 50, MBA: 40 },
        { label: '2024', BBA: 140, BCOM: 85, LLB: 60, BCA: 75, MBBS: 80 }
    ];


    return (
        <React.Fragment>
            <div className='grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3'>
                {organizationStats?.map((stats, index) => <AdmissionTile
                    key={index}
                    icon={stats?.icon}
                    color="#D6D0F7"
                    heading={stats?.heading}
                    count={stats?.count}
                    isLoading={isLoading}
                />
                )}
            </div>

            <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4'>
                {studentStats?.map((stats, index) => <AdmissionTile
                    key={index}
                    icon={stats?.icon}
                    color="#D6D0F7"
                    heading={stats?.heading}
                    count={stats?.count}
                    isLoading={studentCountsLoading}
                />
                )}

                {employeeStats?.map((stats, index) => <AdmissionTile
                    key={index}
                    icon={stats?.icon}
                    color="#D6D0F7"
                    heading={stats?.heading}
                    count={stats?.count}
                    isLoading={employeeCountsLoading}
                />
                )}
            </div>

            <div className='grid gap-4 grid-cols-1 xs:grid-cols-2 tab:grid-cols-3 lg:grid-cols-3'>
                <PieChartWrapper data={studentGenderData} heading='Student Gender Ratio' isLoading={studentGenderCountLoading} />
                <PieChartWrapper data={employeeGenderData} heading='Faculty Gender Ratio' isLoading={employeeGenderCountLoading} />
                <PieChartWrapper data={studentGenderData} heading='Student Program Distribution' isLoading={studentGenderCountLoading} />
            </div>

            <BarChartWrapper data={graphData} heading='Program Distribution over Years' isLoading={false} className='drop-shadow bg-white rounded-md p-5' />
        </React.Fragment>
    )
}

export default OrganizationStats