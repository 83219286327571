import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { AccountCircle, ChangeCircle, Email } from '@mui/icons-material'

import { loadingState } from '../../recoil/state'
import useFormOperations from '../../hooks/useFormOperations'
import usePostData from '../../hooks/usePostData'
import useSnackbar from '../../hooks/useSnackbar'
import useGetClientData from '../../hooks/useGetClientData'

import { ConfirmButton, PurpleButton } from '../../components/mui/button/Button'
import { CustomTextField } from '../../components/mui/Input'
import ReachLogo from '../../assets/logo/products/reachLongLogo.svg'
import LoginInfo from '../../components/other/LoginInfo'

const ForgotPassword = () => {
    const { organizationName, logoThumbnailURL } = useGetClientData();

    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { data: employeeEmail, postData } = usePostData();

    const [idSubmitted, setIdSubmitted] = useState(false);

    const toggleIdSubmitted = () => setIdSubmitted(!idSubmitted);

    const onSubmitId = async () => {
        const IdFormData = { employeeId: formData?.employeeId }
        setLoading(true);
        const { success, message } = await postData('/api/forgotPassword/getEmailId', IdFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error')

        handleSnackbarOpen('Employee Id Verified! Please enter your registered Email ID', 'success');
        setIdSubmitted(true);
    }

    const onVerifyMail = async () => {
        const MailFormData = { employeeId: formData?.employeeId, emailId: formData?.emailId }
        setLoading(true);
        const { success, message } = await postData('/api/forgotPassword/verifyEmailId', MailFormData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        handleSnackbarOpen("We have sent a password reset link to your registered Email Id", 'success');
        navigate('/login')
    }
    const { formData, handleSubmit, handleChange } = useFormOperations({}, onSubmitId);

    return (
        <div className="relative flex justify-center items-center min-h-screen h-full md:p-10 md:px-16 bg-gradient-to-t from-regal-purple-950 to-regal-purple-800">

            {/* Main content container */}
            <div className="relative z-10 grid grid-cols-12 min-h-[670px] h-full w-full max-w-8xl md:rounded-xl bg-white shadow-inner">

                <LoginInfo />

                <div className="col-span-full flex flex-col lg:col-span-6 items-center text-center w-full px-8 sm:px-10 py-10 lg:py-14 gap-10">
                    <header className='flex flex-col items-center select-none gap-2'>
                        <img draggable={false} className='block lg:hidden h-20' src={logoThumbnailURL} alt={organizationName} />
                        <img draggable={false} className='w-48 sm:w-52 h-20' src={ReachLogo} alt="REACH_Logo" />
                        <p className='text-light text-xs sm:text-sm font-medium text-center max-w-xl w-full'>One Organization, One Platform</p>
                    </header>

                    <div className='flex flex-col gap-4 max-w-md w-full' >
                        <form className='flex flex-col gap-6 w-full' onSubmit={handleSubmit}>
                            <CustomTextField
                                label="User ID"
                                name="employeeId"
                                value={formData?.employeeId ?? ''}
                                placeholder='Enter your official User ID'
                                fullWidth
                                disabled={idSubmitted}
                                startIcon={<AccountCircle className='text-regal-purple-600' />}
                                onChange={handleChange}
                            />
                            {idSubmitted ? <div className='flex flex-col gap-[7px] w-full'>
                                <CustomTextField
                                    name="emailId" label="Email Address" color="secondary" type="email" placeholder={employeeEmail ? `************${employeeEmail?.emailId}` : 'abc@example.com'}
                                    value={formData?.emailId ?? ''} onChange={handleChange} fullWidth
                                    startIcon={<Email className='text-regal-purple-600' />}
                                    endIcon={idSubmitted &&
                                        <ChangeCircle className="text-light hover:text-regal-purple-500 cursor-pointer" onClick={toggleIdSubmitted} />
                                    }
                                />
                                <span className="text-s self-start">Enter your Email Id to get password reset link</span>
                            </div> : <p className="h-[81px] grid place-items-center border-dashed border-2 w-full p-3">
                                <span className="text-center text-s leading-6">
                                    Forgot Password? <br />
                                    Reset your password by verifying your Email ID.
                                </span>
                            </p>}
                            {idSubmitted ?
                                <PurpleButton disabled={!(!!formData?.emailId) || !(!!formData?.employeeId)} size='large' fullWidth type="button" text="Verify Mail" onClick={onVerifyMail} sx={{ paddingX: 3, paddingY: 1.3 }} />
                                : <ConfirmButton fullWidth disabledElevation={true} size='large' type="submit" text="Submit ID" sx={{ paddingX: 3, paddingY: 1.3 }} />}
                        </form>

                        <Link to='/login?type=Password' className='text-s hover:text-regal-purple-500 hover:underline'>Continue to Login</Link>
                    </div>

                    <div className='lg:hidden flex flex-col items-center gap-2 text-sm text-medium text-slate-600'>
                        <div className='flex flex-col xs:flex-row gap-3 xs:gap-5'>
                            <Link className="hover:text-regal-purple-700" to='/privacypolicy'>Privacy Policy</Link>
                            <span className='hidden xs:flex'>|</span>
                            <Link className=" hover:text-regal-purple-700" to='/termsandconditions'>Terms and Conditions</Link>
                        </div>
                        <span className='text-slate-400'>© {new Date().getFullYear()} Byteswrite. All Rights Reserved</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgotPassword;