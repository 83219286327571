import React from 'react'
import renderFormField from '../../../../../utils/renderFormField';
import { generateGeoOptions } from '../../../../../utils/geoLocationOptions';
import { Checkbox } from '@mui/material';
import { deleteKeys, objectDeepEqual } from '../../../../../utils/formFunctions';

const AddressInfo = ({ formData, updateData, handleNestedChange, isLoading }) => {

  const { countryOptions, stateOptions: permanentStateOptions, cityOptions: permanentCityOptions } = generateGeoOptions(formData?.personalInfo?.permanentAddress?.country, formData?.personalInfo?.permanentAddress?.state);
  const { stateOptions: residentialStateOptions, cityOptions: residentialCityOptions } = generateGeoOptions(formData?.personalInfo?.residentialAddress?.country, formData?.personalInfo?.residentialAddress?.state);

  const residentialAddressFields = [
    { type: 'input', size: 'full', label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address, name: "formData.personalInfo.residentialAddress.address" },
    { type: 'select', label: "Country", value: formData?.personalInfo?.residentialAddress?.country, name: "formData.personalInfo.residentialAddress.country", options: countryOptions },
    { type: 'select', label: "State", value: formData?.personalInfo?.residentialAddress?.state, name: "formData.personalInfo.residentialAddress.state", options: residentialStateOptions },
    { type: 'select', label: "City", value: formData?.personalInfo?.residentialAddress?.city, name: "formData.personalInfo.residentialAddress.city", options: residentialCityOptions },
    { type: 'input', label: "Town", value: formData?.personalInfo?.residentialAddress?.town, name: "formData.personalInfo.residentialAddress.town" },
    { type: 'input', label: formData?.personalInfo?.residentialAddress?.country === 'India' ? 'Pin Code' : 'Zip Code', value: formData?.personalInfo?.residentialAddress?.pincode, name: "formData.personalInfo.residentialAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } } },
  ]

  const permanentAddressFields = [
    { type: 'input', size: 'full', label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address, name: "formData.personalInfo.permanentAddress.address" },
    { type: 'select', label: "Country", value: formData?.personalInfo?.permanentAddress?.country, name: "formData.personalInfo.permanentAddress.country", options: countryOptions },
    { type: 'select', label: "State", value: formData?.personalInfo?.permanentAddress?.state, name: "formData.personalInfo.permanentAddress.state", options: permanentStateOptions },
    { type: 'select', label: "City", value: formData?.personalInfo?.permanentAddress?.city, name: "formData.personalInfo.permanentAddress.city", options: permanentCityOptions },
    { type: 'input', label: "Town", value: formData?.personalInfo?.permanentAddress?.town, name: "formData.personalInfo.permanentAddress.town" },
    { type: 'input', label: formData?.personalInfo?.permanentAddress?.country === 'India' ? 'Pin Code' : 'Zip Code', value: formData?.personalInfo?.permanentAddress?.pincode, name: "formData.personalInfo.permanentAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } } },
  ]

  const handleAddressCheckBox = () => {
    if (addressCheckBox()) {
      const updatedFormData = { ...formData }
      deleteKeys(updatedFormData?.personalInfo, ['permanentAddress'])
      updateData((formData) => ({ ...updatedFormData }));
    } else {
      permanentAddressFields?.forEach(field => {
        handleNestedChange({ target: { name: field?.name, value: formData?.personalInfo?.residentialAddress?.[field?.name?.split('.').pop()] } })
      });
    }
  };

  const addressCheckBox = () => {
    const residentialAddress = formData?.personalInfo?.residentialAddress;
    const permanentAddress = formData?.personalInfo?.permanentAddress;
    return objectDeepEqual(residentialAddress, permanentAddress);
  };

  return (
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-10">
      <h5 className="col-span-full h4-p">Address Details</h5>
      {residentialAddressFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}

      {isLoading ?
        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
        : <div className="col-span-full flex items-center">
          <Checkbox id="address-checkbox" checked={addressCheckBox()} color="secondary" onChange={handleAddressCheckBox} />
          <label htmlFor="address-checkbox" className="text-s cursor-pointer">Set Permanent Address same as Residential Address</label>
        </div>}

      {!addressCheckBox() && permanentAddressFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
    </div>
  )
}

export default AddressInfo;