import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SkeletonText = ({ loading, children, className, skeletonClassName = 'w-full', lines = 1, width, height, displayEmpty = true, ...props }) => {

    if (loading) return (
        <div className={skeletonClassName}>
            {Array.from({ length: lines }).map((_, index) => (
                <Skeleton className={className} key={index} animation='wave' width={width || '100%'} height={height && height} {...props} />
            ))}
        </div>
    );

    
    return (
        <span className={className}>
            {(children !== null && children !== undefined && children !== '') ? children : displayEmpty ? 'N/A' : ''}
        </span>
    );
};

export default SkeletonText;