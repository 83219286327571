import React from 'react'
import { Link } from 'react-router-dom';
import { LightButton } from '../mui/button/Button';
import { useMediaQuery } from '@mui/material';

const StatusTile = ({ heading, icon: Icon, count, latest, content, btn, link, className }) => {
    const xs = useMediaQuery('(min-width:500px)')

    return (
        <div className={`flex flex-col gap-5 ${className}`}>
            <div className='flex gap-2'>
                <Icon sx={{ color: '#475569' }} />
                <span className='body-semibold text-neutral'>{heading}</span>
            </div>

            <div className='flex flex-col gap-1'>
                <div className='flex justify-between'>
                    <span className='h1-p text-neutral'>{count}</span>
                    {latest && <span className='text-p font-semibold rounded-full px-4 py-2 bg-secondary h-fit'>+{latest} today</span>}
                </div>

                <div className='flex flex-col xs:flex-row'>
                    {
                        content?.map((item, index) => <div key={index} className='flex gap-1 lb-p text-neutral font-regular'>
                            <span>{item?.value}</span>
                            <span>{item?.label}</span>
                            {xs && index !== content?.length - 1 && <span className='mx-1'>|</span>}
                        </div>)
                    }
                </div>
            </div>

            <Link to={link}><LightButton fullWidth text={btn} /></Link>
        </div>
    )
}

export default StatusTile