import React from 'react'
import Calendar from '../../../components/mui/Calendar'

const CalendarForm = ({ className }) => {
    return (
        <div className={`flex items-center justify-center ${className}`}>
            <Calendar />
        </div>
    )
}

export default CalendarForm