import React from 'react'
import { CustomCheckBox } from '../../../../../../../components/mui/Input'
import TextBox from '../../../../../../../components/mui/text/TextBox'

const DoctorateInfo = ({ data }) => {

    const { formData, documentOpen, handleMasterCheckBox, masterCheckBoxValue, handleCheckboxChange, checkBoxOptions, onlineStatus, isLoading } = data;

    const doctorateDetailFields = formData?.education?.doctorateDetails?.map((phd, index) => [
        { label: 'Degree', value: formData?.education?.doctorateDetails?.[index]?.degree?.status, data: phd?.degree?.data, name: `formData.education.doctorateDetails.${index}.degree.status` },
        { label: 'Dissertation Title', value: formData?.education?.doctorateDetails?.[index]?.dissertationTitle?.status, data: phd?.dissertationTitle?.data, name: `formData.education.doctorateDetails.${index}.dissertationTitle.status` },
        { label: 'Supervisor', value: formData?.education?.doctorateDetails?.[index]?.supervisor?.status, data: phd?.supervisor?.data, name: `formData.education.doctorateDetails.${index}.supervisor.status` },
        { label: 'Completion Year', value: formData?.education?.doctorateDetails?.[index]?.completionYear?.status, data: phd?.completionYear?.data, name: `formData.education.doctorateDetails.${index}.completionYear.status` },
        { label: 'University Name', value: formData?.education?.doctorateDetails?.[index]?.universityName?.status, data: phd?.universityName?.data, name: `formData.education.doctorateDetails.${index}.universityName.status` },
        { label: 'University City', value: formData?.education?.doctorateDetails?.[index]?.city?.status, data: phd?.city?.data, name: `formData.education.doctorateDetails.${index}.city.status` },
        { label: 'University State', value: formData?.education?.doctorateDetails?.[index]?.state?.status, data: phd?.state?.data, name: `formData.education.doctorateDetails.${index}.state.status` },
        { label: 'University Country', value: formData?.education?.doctorateDetails?.[index]?.country?.status, data: phd?.country?.data, name: `formData.education.doctorateDetails.${index}.country.status` },
        { label: 'CGPA', value: formData?.education?.doctorateDetails?.[index]?.cgpa?.status, data: phd?.cgpa?.data, name: `formData.education.doctorateDetails.${index}.cgpa.status` }
    ]);

    return (
        doctorateDetailFields?.map((fields, i) =>
            <div className={`grid gap-4 xs:gap-5 xs:grid-cols-2 tab:grid-cols-3 ${documentOpen ? "lg:grid-cols-2" : "lg:grid-cols-3"}`} key={i}>
                <CustomCheckBox className="col-span-full" onChange={(e) => handleMasterCheckBox(e, `Doctorate ${doctorateDetailFields?.length > 1 ? i + 1 : 'Details'}`)} value={masterCheckBoxValue(fields, checkBoxOptions)} name={fields} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                    <h4 className="col-span-full h4-p text-grey">Doctorate {doctorateDetailFields?.length > 1 ? i + 1 : 'Details'}</h4>
                </CustomCheckBox>

                {fields.map((field, index) => (isLoading || field?.data !== undefined) &&
                    <div key={`${i}-${index}`}>
                        <CustomCheckBox value={field.value} name={field.name} onChange={handleCheckboxChange} options={checkBoxOptions} disabled={onlineStatus === 'Verified'}>
                            <TextBox label={field.label} value={field.data} loading={isLoading} />
                        </CustomCheckBox>
                    </div>
                )}
            </div>
        )
    )
}

export default DoctorateInfo;