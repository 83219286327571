import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Person, Person3, ChangeCircle, Email } from '@mui/icons-material';

import { loadingState, loggedInState, userState } from "../../../recoil/state";

import useFormOperations from "../../../hooks/useFormOperations";
import usePostData from "../../../hooks/usePostData";
import useSnackbar from "../../../hooks/useSnackbar";
import useGetData from "../../../hooks/useGetData";

import { CustomTextField } from "../../../components/mui/Input";
import { PurpleButton } from "../../../components/mui/button/Button";
import BoxTabSmall from "../../../components/mui/tab/BoxTabSmall";
import BlackToolTip from "../../../components/mui/BlackToolTip";
import LoginGoogle from "./LoginGoogle";

const Fields = () => {
    useGetData('/api/cookies/flushCookies');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('type');

    const setLoading = useSetRecoilState(loadingState);
    const setLoggedInState = useSetRecoilState(loggedInState);
    const setUserState = useSetRecoilState(userState);

    const { postData } = usePostData();

    const [otp, setOtp] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [remainingTime, setRemainingTime] = useState();

    const defaultValues = { otpType: 'admissionLogin' };
    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);
    const { handleSnackbarOpen } = useSnackbar();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const otpVerifyButton = document.getElementById('otpVerify');
            if (otpVerifyButton) otpVerifyButton.click();
        }
    };

    const toggleEmailSubmitted = () => setEmailSubmitted(!emailSubmitted)

    const onSubmit = async (formData) => {
        const OTPFormData = { email: formData?.email, type: activeTab }
        setLoading(true);
        const { success, message } = await postData('/api/admission/login/loginGenerateOTP', OTPFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP to your Email ID!", 'error')

        handleSnackbarOpen('OTP sent to your Email ID!', 'success');
        setEmailSubmitted(true);
        setRemainingTime(5 * 60);
    }

    const { formData, handleChange, handleSubmit } = useFormOperations(defaultValues, onSubmit, '/admissions/form?step=Personal+Details');

    const onOTPVerify = async () => {
        const OTPFormData = { email: formData?.email, otp, type: activeTab }
        setLoading(true);
        const { success, message, data } = await postData('/api/admission/login/loginVerifyOTP', OTPFormData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        const { loginType, submitted } = data;
        setLoggedInState(loginType);
        setUserState({ submitted });

        setEmailSubmitted(true);
        handleSnackbarOpen("OTP Verified successfully!", 'success');

        if (submitted) return navigate(`/admissions/${activeTab.toLowerCase()}/profile?type=Personal`);
        navigate(`/admissions/${activeTab.toLowerCase()}/form?step=Personal+Details`);
    }

    const tabs = [{ label: 'Student', icon: <Person /> }, { label: 'Employee', icon: <Person3 /> }]

    useEffect(() => {
        let timer;
        if (emailSubmitted && remainingTime > 0)
            timer = setInterval(() => setRemainingTime(t => t - 1), 1000);

        return () => clearInterval(timer);
    }, [emailSubmitted, remainingTime]);

    const formattedTime = emailSubmitted && remainingTime > 0 ? `${Math.floor(remainingTime / 60)} min ${remainingTime % 60} sec Remaining` : emailSubmitted && 'OTP EXPIRED PLS SEND AGAIN';


    return (
        <div className="flex flex-col gap-3 items-center w-full max-w-md">
            <BoxTabSmall disabled={emailSubmitted} className='w-full mb-4' tabs={tabs} />
            <form className="flex flex-col w-full gap-4" onSubmit={handleSubmit}>
                <CustomTextField
                    name="email" label="Email Address" color="secondary" type="email"
                    placeholder="Enter your personal email ID"
                    value={formData?.email} onChange={handleChange} disabled={emailSubmitted}
                    startIcon={<Email className="text-regal-purple-600" />}
                    endIcon={emailSubmitted &&
                        <BlackToolTip arrow message='Change email address?'>
                            <ChangeCircle className="text-light hover:text-regal-purple-600 cursor-pointer" onClick={toggleEmailSubmitted} />
                        </BlackToolTip>
                    }
                />

                {emailSubmitted ?
                    <div className="flex flex-col space-y-2 h-20">
                        <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 12 } }} onKeyDown={handleKeyDown} />
                        <p className="flex gap-x-2">
                            <span className="font-poppins text-sm text-light">Didn't receive the code?</span>
                            <button type="submit" className="font-poppins text-sm text-regal-purple-500 hover:underline ">Resend it.</button>
                        </p>
                    </div>
                    :
                    <p className="h-20 grid place-items-center border-dashed border-2 w-full p-3">
                        <span className=" text-s leading-6">
                            New? Create your account. <br />
                            Existing? Sign in seamlessly.
                        </span>
                    </p>
                }

                {emailSubmitted ? <PurpleButton id='otpVerify' disabled={otp.length < 6} fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} sx={{ paddingX: 3, paddingY: 1.3 }} />
                    : <PurpleButton fullWidth disabledElevation={true} type="submit" text="Generate OTP" sx={{ paddingX: 3, paddingY: 1.3 }} />
                }
            </form>

            <LoginGoogle emailSubmitted={emailSubmitted} />

            <p className="text-s">Already have an account? <Link to='/login?type=password' className=' hover:text-regal-purple-500 hover:underline'>
                Log in here.
            </Link>
            </p>
            <p className="text-p text-signal h-4">{formattedTime}</p>
        </div>
    )
}

export default Fields